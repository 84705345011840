define('core/helpers/is', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var service = Ember.inject.service;
  var Helper = Ember.Helper;
  exports.default = Helper.extend({
    currentSession: service('current-user'),
    compute: function compute(_ref) {
      var _ref2 = _slicedToArray(_ref, 1),
          role = _ref2[0];

      if (this.get('currentSession.user.roles')) {

        var roles = this.get('currentSession.user.roles').reduce(function (acc, value) {
          if (value.get('name')) {
            acc.push(value.get('name').toLowerCase().replace(' ', '-'));
          }
          return acc;
        }, []);

        console.log(roles);

        return roles.indexOf(role) > -1;
      }
      // let permissions = this.get('currentSession.user.rolesgrantedPermissions');
      // if (typeof(permissions) === 'undefined') {
      //   permissions = [];
      // }
      // return permissions.indexOf(role) > -1;
    }
  });
});