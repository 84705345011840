define('core/models/inbound-shipment', ['exports', 'ember-data', 'ember-api-actions'], function (exports, _emberData, _emberApiActions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = _emberData.default.Model.extend({
    shipmentId: _emberData.default.attr('string'),
    labelPrepType: _emberData.default.attr('string'),
    destinationCode: _emberData.default.attr('string'),
    name: _emberData.default.attr('string'),
    addressLine1: _emberData.default.attr('string', { readOnly: true }),
    addressLine2: _emberData.default.attr('string', { readOnly: true }),
    city: _emberData.default.attr('string'),
    state: _emberData.default.attr('string'),
    zip: _emberData.default.attr('string'),
    country: _emberData.default.attr('string'),
    cost: _emberData.default.attr('number'),
    status: _emberData.default.attr('string'),
    shipmentCreatedAt: _emberData.default.attr('date'),
    shippedAt: _emberData.default.attr('date'),
    shipmentType: _emberData.default.attr('string'),
    prepReceivingBinId: _emberData.default.attr('number'),
    stagingBinId: _emberData.default.attr('number'),
    stagingLocation: _emberData.default.attr('string', { readOnly: true }),
    stationId: _emberData.default.attr('number'),
    contentsConfirmedAt: _emberData.default.attr('date'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    projectRecords: _emberData.default.hasMany('project-record'),
    user: _emberData.default.belongsTo('user'),
    details: _emberData.default.attr({ readOnly: true }),
    receivingBin: _emberData.default.belongsTo('receiving-bin'),
    amazonRequest: _emberData.default.belongsTo('amazon-request'),
    fulfillmentCenter: _emberData.default.belongsTo('fulfillment-center'),
    receivingTotes: _emberData.default.hasMany('receiving-tote'),
    items: _emberData.default.hasMany('inbound-shipment-item'),
    submitBoxContents: (0, _emberApiActions.memberAction)({ path: 'submit_box_contents', type: 'put' }),
    ship: (0, _emberApiActions.memberAction)({ path: 'ship', type: 'put' }),
    boxes: _emberData.default.hasMany('box'),
    unweighedBoxes: computed.filterBy('boxes', 'weight', null),
    unweighedBoxNumbers: computed.mapBy('unweighedBoxes', 'boxNumber'),
    boxDirectly: computed('fulfillmentType', 'shipmentType', 'fulfillmentCenter.boxDirectly', 'receivingBin.id', function () {
      return this.get('fulfillmentCenter.boxDirectly');
    }),
    oversize: computed('fulfillmentCenter.fulfillmentType', function () {
      return this.get('fulfillmentCenter.fulfillmentType') === 'oversize';
    }),
    smallAndLight: computed('fulfillmentCenter.fulfillmentType', function () {
      return this.get('fulfillmentCenter.fulfillmentType') === 'small_and_light';
    }),
    standard: computed('fulfillmentCenter.fulfillmentType', function () {
      return this.get('fulfillmentCenter.fulfillmentType') === 'standard';
    })

  });
});