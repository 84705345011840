define('core/models/carton', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    code: _emberData.default.attr('string'),
    length: _emberData.default.attr('number'),
    width: _emberData.default.attr('number'),
    height: _emberData.default.attr('number'),
    weight: _emberData.default.attr('number'),
    cost: _emberData.default.attr('number'),
    speed: _emberData.default.attr('number'),
    protection: _emberData.default.attr('number'),
    cartonType: _emberData.default.attr('string'),
    maxValue: _emberData.default.attr('number'),
    maxWeight: _emberData.default.attr('number'),
    maxDim: _emberData.default.attr('number'),
    midDim: _emberData.default.attr('number'),
    minDim: _emberData.default.attr('number'),
    volume: _emberData.default.attr('number'),
    process: _emberData.default.attr('number'),
    uspsPackageType: _emberData.default.attr('string'),
    reserved: _emberData.default.attr('boolean'),
    pickingGroup: _emberData.default.attr('string'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    projectRecords: _emberData.default.hasMany('project-record'),
    groupName: computed('cartonType', 'name', function () {
      return this.get('cartonType') + ' ' + this.get('name');
    }),
    shortName: computed('name', function () {
      return ('' + this.get('name')).replace('USPS ', '').replace('Flat Rate ', '');
    }),
    groupClass: computed('cartonType', function () {
      if (this.get('cartonType') == 'USPS_FLAT_RATE') {
        return 'flat-rate';
      } else {
        return 'default';
      }
    })

  });
});