define('core/models/timeclock', ['exports', 'ember-data', 'moment'], function (exports, _emberData, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = _emberData.default.Model.extend({
    inAt: _emberData.default.attr('date'),
    outAt: _emberData.default.attr('date'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    user: _emberData.default.belongsTo('user'),
    clockIn: _emberData.default.attr('boolean'),
    clockOut: _emberData.default.attr('boolean'),
    hours: computed('inAt', 'outAt', function () {
      if (this.get('outAt')) {
        return Math.round((this.get('outAt') - this.get('inAt')) / 36000) / 100;
      }
      return Math.round((new Date() - this.get('inAt')) / 36000) / 100;
    }),
    momentIn: computed('inAt', function () {
      if (this.get('inAt')) {
        return (0, _moment.default)(this.get('inAt')).format('MM/DD/YY hh:mm:ss a');
      }
    }),
    momentOut: computed('inAt', function () {
      if (this.get('outAt')) {
        return (0, _moment.default)(this.get('outAt')).format('MM/DD/YY hh:mm:ss a');
      }
    })
  });
});