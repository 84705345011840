define('core/routes/pending-orders', ['exports', 'ember-infinity/lib/infinity-model'], function (exports, _infinityModel) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  var service = Ember.inject.service;


  var ExtendedInfinityModel = _infinityModel.default.extend({
    buildParams: function buildParams() {
      var params = this._super.apply(this, arguments);
      params['min_id'] = this.get('_minId');
      return params;
    },
    afterInfinityModel: function afterInfinityModel(pendingOrders) {
      var loadedAny = pendingOrders.get('length') > 0;
      pendingOrders.set('canLoadMore', loadedAny);
      this.set('_minId', pendingOrders.get('lastObject.id'));
    }
  });

  exports.default = Route.extend({
    infinity: service(),
    store: service(),
    model: function model() {
      return this.get('infinity').model('pending-order', { totalPagesParam: 'meta.pages', countParam: 'meta.count' }, ExtendedInfinityModel);
    }
  });
});