define('core/models/kit-build', ['exports', 'ember-data', 'ember-api-actions'], function (exports, _emberData, _emberApiActions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    packedAt: _emberData.default.attr('date'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    item: _emberData.default.belongsTo('item'),
    kitBin: _emberData.default.belongsTo('kit-bin'),
    user: _emberData.default.belongsTo('user'),
    purchaseOrderItem: _emberData.default.belongsTo('purchase-order-item'),
    kitBuildItems: _emberData.default.hasMany('kit-build-item'),
    projectRecords: _emberData.default.hasMany('project-record'),
    projectRecordId: _emberData.default.attr('number'),
    print: (0, _emberApiActions.memberAction)({ path: 'print', type: 'put' })
  });
});