define('core/components/mqtt-scale', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var service = Ember.inject.service;
  var computed = Ember.computed;
  var observer = Ember.observer;
  exports.default = Component.extend({
    currentUser: service(),
    mqttClient: service(),
    station: computed('currentUser.shippingStation', 'currentUser.user', 'currentUser.user.id', function () {
      return this.get('currentUser.shippingStation');
    }),
    topic: computed('station', function () {
      return 'station_' + this.get('station') + '_weight';
    }),
    pounds: computed('ounces', function () {
      return Math.floor(this.get('ounces') / 16);
    }),
    partOunces: computed('pounds', 'ounces', function () {
      return Math.round(100 * (this.get('ounces') - this.get('pounds') * 16)) / 100;
    }),
    weight: computed('ounces', 'poudsn', 'partOunces', function () {
      return this.get('pounds') + ' lb ' + this.get('partOunces') + ' oz';
    }),
    ounces: computed('topic', 'mqttClient.lastUpdate', function () {
      return (this.get('mqttClient').get(this.get('topic')) || 0) * 1;
    }),
    subscriber: observer('station', 'mqttClient.connected', 'mqttClient.lastUpdate', function () {
      if (this.get('station') && this.get('mqttClient.connected')) {
        this.get('mqttClient').subscribe('station/' + this.get('station') + '/weight', 0);
      }
    }),
    didInsertElement: function didInsertElement() {
      this.get('mqttClient').connect();
    }
  });
});