define('core/components/table-purchase-order-items', ['exports', 'core/mixins/table-basic', 'ember-light-table', 'ember-concurrency'], function (exports, _tableBasic, _emberLightTable, _emberConcurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  var isEmpty = Ember.isEmpty;
  var service = Ember.inject.service;
  exports.default = Component.extend(_tableBasic.default, {
    store: service(),
    isLoading: computed.or('fetchRecords.isRunning', 'setRows.isRunning').readOnly(),

    // Sort Logic
    sortedModel: computed.sort('model', 'sortBy').readOnly(),
    sortBy: computed('dir', 'sort', function () {
      return [this.get('sort') + ':' + this.get('dir')];
    }).readOnly(),
    hasSelection: computed.notEmpty('table.selectedRows'),
    // Filter Input Setup
    selectedFilter: computed.oneWay('possibleFilters.firstObject'),
    possibleFilters: computed('table.columns', function () {
      return this.get('table.columns').filterBy('sortable', true);
    }).readOnly(),
    columns: computed(function () {
      return [{
        width: '40px',
        sortable: false,
        cellComponent: 'lt-row-toggle',
        breakpoints: ['mobile', 'tablet', 'desktop']
      }, {
        label: 'Updated',
        valuePath: 'updatedAt',
        cellComponent: 'lt-date',
        cellClassNames: 'date',
        width: '200px',
        breakpoints: ['jumbo']
      }, {
        label: 'Received',
        valuePath: 'receivedAt',
        cellComponent: 'lt-date',
        cellClassNames: 'date',
        width: '200px',
        breakpoints: ['jumbo']
      }, {
        label: 'SKU',
        valuePath: 'item.sku',
        cellClassNames: 'item-sku',
        width: '175px',
        sortable: true
      }, {
        label: 'Name',
        valuePath: 'name',
        resizable: true
      }, {
        label: 'Unfulfillable Reason',
        valuePath: 'unfulfillableReason',
        resizable: true,
        breakpoints: ['mobile']
      }, {
        label: 'Quantity',
        valuePath: 'quantity',
        resizable: true,
        draggable: true,
        width: '100px'

      }, {
        label: 'Last Sold',
        valuePath: 'lastSoldAt',
        cellComponent: 'lt-date',
        cellClassNames: 'date',
        width: '200px',
        breakpoints: ['jumbo']
      }];
    }),
    limit: 50,
    sort: 'sku',
    dir: 'asc',
    canLoadMore: true,
    page: 0,
    init: function init() {
      this._super.apply(this, arguments);

      var table = new _emberLightTable.default(this.get('columns'), this.get('model'), { enableSync: this.get('enableSync') });
      var sortColumn = table.get('allColumns').findBy('valuePath', this.get('sort'));

      // Setup initial sort column
      if (sortColumn) {
        sortColumn.set('sorted', true);
      }

      this.set('table', table);
    },


    actions: {
      onAfterResponsiveChange: function onAfterResponsiveChange(matches) {
        console.log('resized');
        console.log(matches);
        if (matches.indexOf('jumbo') > -1) {
          this.get('table.expandedRows').setEach('expanded', false);
        }
      },
      selectAll: function selectAll() {
        this.get('table.rows').setEach('selected', true);
      },
      deselectAll: function deselectAll() {
        this.get('table.selectedRows').setEach('selected', false);
      }
    }
  });
});