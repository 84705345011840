define('core/components/manage-purchase-orders', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    typeOptions: ['purchase', 'transfer_out', 'transfer_in', 'fba_pick_list', 'fba_picked', 'small_and_light', 'pick_list'],
    statusOptions: ['open', 'partial', 'closed'],
    actions: {
      newPo: function newPo() {
        console.log('new PO');
      }
    }
  });
});