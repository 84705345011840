define('core/helpers/inc', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.inc = inc;
  var helper = Ember.Helper.helper;
  function inc(index /*, hash*/) {
    return parseInt(index) + 1;
  }

  exports.default = helper(inc);
});