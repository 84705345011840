define('core/models/pos-batch-tender-total', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    openingAmount: _emberData.default.attr('number'),
    shiftAmount: _emberData.default.attr('number'),
    closingAmount: _emberData.default.attr('number'),
    transactions: _emberData.default.attr('number'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    posBatch: _emberData.default.belongsTo('pos-batch'),
    tender: _emberData.default.belongsTo('tender')
  });
});