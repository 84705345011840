define('core/models/shopify-variant', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = _emberData.default.Model.extend({
    shopifyId: _emberData.default.attr('number'),
    shopifyProductId: _emberData.default.attr('number'),
    sku: _emberData.default.attr('string'),
    title: _emberData.default.attr('string'),
    price: _emberData.default.attr('number'),
    barcode: _emberData.default.attr('string'),
    compareAtPrice: _emberData.default.attr('number'),
    inventoryQuantity: _emberData.default.attr('number'),
    oldInventoryQuantity: _emberData.default.attr('number'),
    newQuantity: _emberData.default.attr('number', { readOnly: true }),
    supplierQuantity: _emberData.default.attr('number', { readOnly: true }),
    totalQuantity: computed('newQuantity', 'supplierQuantity', function () {
      return this.get('newQuantity') + this.get('supplierQuantity');
    }),
    fulfillmentService: _emberData.default.attr('string'),
    weight: _emberData.default.attr('number'),
    weightUnit: _emberData.default.attr('string'),
    inventoryManagement: _emberData.default.attr('string'),
    inventoryPolicy: _emberData.default.attr('string'),
    metafield: _emberData.default.attr('string'),
    position: _emberData.default.attr('number'),
    requiresShipping: _emberData.default.attr('boolean'),
    taxable: _emberData.default.attr('boolean'),
    grams: _emberData.default.attr('number'),
    imageId: _emberData.default.attr('number'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    changedAt: _emberData.default.attr('date'),
    syncedAt: _emberData.default.attr('date'),
    synced: computed('changedAt', 'syncedAt', function () {
      return this.get('changedAt') && this.get('syncedAt') && this.get('syncedAt') > this.get('changedAt');
    }),
    inventoryChangedAt: _emberData.default.attr('date'),
    inventorySyncedAt: _emberData.default.attr('date'),
    inventorySynced: computed('changedAt', 'syncedAt', function () {
      return this.get('inventoryChangedAt') && this.get('inventorySyncedAt') && this.get('inventorySyncedAt') > this.get('inventoryChangedAt');
    }),
    product: _emberData.default.belongsTo('shopify-product'),
    item: _emberData.default.belongsTo('item'),
    storeId: _emberData.default.attr('number'),
    storeName: computed('storeId', function () {
      if (this.get('storeId') === 1) {
        return 'US Novelty';
      } else if (this.get('storeId') === 3) {
        return 'Ziggos';
      }
    }),
    catalogUrl: computed('storeId', 'product.handle', function () {
      if (this.get('storeId') === 1) {
        return 'https://www.usnovelty.com/products/' + this.get('product.handle');
      } else if (this.get('storeId') === 3) {
        return 'https://www.ziggosparty.com/products/' + this.get('product.handle');
      }
    })
  });
});