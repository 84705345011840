define('core/models/project', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    model: _emberData.default.attr('string'),
    whereClause: _emberData.default.attr('string'),
    orderBy: _emberData.default.attr('string'),
    batchSize: _emberData.default.attr('number'),
    priority: _emberData.default.attr('number'),
    display: _emberData.default.attr('number'),
    status: _emberData.default.attr('number'),
    remainingCount: _emberData.default.attr('number', { readOnly: true }),
    verify: _emberData.default.attr('boolean'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    field: _emberData.default.attr('string'),
    layout: _emberData.default.attr('string'),
    joinTables: _emberData.default.attr('string'),
    minimumAssignment: _emberData.default.attr('number'),
    timeLimit: _emberData.default.attr('number'),
    reassign: _emberData.default.attr('boolean'),
    projectRecords: _emberData.default.hasMany('project-record'),
    projectUsers: _emberData.default.hasMany('project-user'),
    assignedUsers: computed.mapBy('projectUsers', 'user'),
    users: _emberData.default.hasMany('user'),
    user: _emberData.default.belongsTo('user'),
    emberSort: _emberData.default.attr('string'),
    maxBatches: _emberData.default.attr('number'),
    refreshTime: _emberData.default.attr('number'),
    template: _emberData.default.attr('string'),
    component: computed('template', function () {
      return 'project-' + this.get('template');
    }),
    button: computed('hasDirtyAttributes', function () {
      if (this.get('hasDirtyAttributes')) {
        return 'Save Project';
      } else {
        return 'Project Saved';
      }
    })

  });
});