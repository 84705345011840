define('core/models/kit-build-item', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = _emberData.default.Model.extend({
    pickedAt: _emberData.default.attr('date'),
    placedAt: _emberData.default.attr('date'),
    quantity: _emberData.default.attr('number'),
    quantityPicked: _emberData.default.attr('number'),
    quantityPacked: _emberData.default.attr('number'),
    batchPicked: _emberData.default.attr('number'),
    quantityNeeded: _emberData.default.attr('number', { readOnly: true }),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    kitBuild: _emberData.default.belongsTo('kit-build'),
    item: _emberData.default.belongsTo('item'),
    picker: _emberData.default.belongsTo('user'),
    packer: _emberData.default.belongsTo('user'),
    pickingTote: _emberData.default.belongsTo('picking-tote'),
    projectRecordId: _emberData.default.attr('number'),
    quantityRemaining: computed('quantity', 'quantityPicked', function () {
      return this.get('quantityNeeded') - (this.get('quantityPicked') || 0);
    })
  });
});