define('core/components/query-purchase-order-items', ['exports', 'core/mixins/query-common'], function (exports, _queryCommon) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend(_queryCommon.default, {
    sort: 'reorderNumber'
  });
});