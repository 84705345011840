define('core/models/feed-item', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = _emberData.default.Model.extend({
    sku: _emberData.default.attr('string'),
    quantity: _emberData.default.attr('number'),
    quantityCommitted: _emberData.default.attr('number'),
    cost: _emberData.default.attr('number'),
    unitOfMeasure: _emberData.default.attr('string'),
    upc: _emberData.default.attr('string'),
    category: _emberData.default.attr('string'),
    name: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    processedAt: _emberData.default.attr('date'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    item: _emberData.default.belongsTo('item'),
    feed: _emberData.default.belongsTo('feed'),
    available: computed('quantity', 'quantityCommitted', function () {
      return this.get('quantity') - (this.get('quantityCommitted') || 0);
    })
  });
});