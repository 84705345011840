define('core/models/price-rule', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = _emberData.default.Model.extend({
    position: _emberData.default.attr('number'),
    description: _emberData.default.attr('string'),
    priceSign: _emberData.default.attr('string'),
    priceAgainst: _emberData.default.attr('string'),
    priceBy: _emberData.default.attr('string'),
    priceAmount: _emberData.default.attr('number'),
    sellerFba: _emberData.default.attr('string'),
    compFba: _emberData.default.attr('string'),
    compStock: _emberData.default.attr('string'),
    compLocation: _emberData.default.attr('string'),
    stockLevelMin: _emberData.default.attr('number'),
    stockLevelMax: _emberData.default.attr('number'),
    stockDaysMin: _emberData.default.attr('number'),
    stockDaysMax: _emberData.default.attr('number'),
    stockAgeMin: _emberData.default.attr('number'),
    stockAgeMax: _emberData.default.attr('number'),
    marginTypeMin: _emberData.default.attr('string'),
    marginTypeMax: _emberData.default.attr('string'),
    marginAmountMin: _emberData.default.attr('number'),
    marginAmountMax: _emberData.default.attr('number'),
    compRatingCountMin: _emberData.default.attr('number'),
    compRatingCountMax: _emberData.default.attr('number'),
    compRatingMin: _emberData.default.attr('number'),
    compRatingMax: _emberData.default.attr('number'),
    sellerFreeShipping: _emberData.default.attr('string'),
    compFreeShipping: _emberData.default.attr('string'),
    compMatch: _emberData.default.attr('string'),
    compMatches: _emberData.default.attr('string'),
    compCountMin: _emberData.default.attr('number'),
    compCountMax: _emberData.default.attr('number'),
    compStockLevelMin: _emberData.default.attr('number'),
    compStockLevelMax: _emberData.default.attr('number'),
    compStockDaysMin: _emberData.default.attr('number'),
    compStockDaysMax: _emberData.default.attr('number'),
    compStockWithin: _emberData.default.attr('number'),
    ignoreOffer: _emberData.default.attr('boolean'),
    lastSaleSign: _emberData.default.attr('string'),
    lastSaleWas: _emberData.default.attr('number'),
    lastSalePeriod: _emberData.default.attr('string'),
    lastLosingWithin: _emberData.default.attr('number'),
    sellerBuyBox: _emberData.default.attr('string'),
    compBuyBox: _emberData.default.attr('string'),
    unitsSoldSign: _emberData.default.attr('string'),
    unitsSoldAmount: _emberData.default.attr('number'),
    unitsSoldTime: _emberData.default.attr('number'),
    unitsSoldPeriod: _emberData.default.attr('string'),
    pricedWithinSign: _emberData.default.attr('string'),
    pricedWithinType: _emberData.default.attr('string'),
    pricedWithinAmount: _emberData.default.attr('number'),
    winningToLosingComparison: _emberData.default.attr('string'),
    currentPriceBelowLastLosingPrice: _emberData.default.attr('boolean'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    pricingProfile: _emberData.default.belongsTo('pricing-profile'),
    soldDisplay: computed('lastSaleSign', 'lastSaleWas', 'lastSalePeriod', function () {
      if (this.get('lastSaleSign') === 'last_sale_unknown') {
        return '?';
      } else if (this.get('lastSaleSign') === 'last_sold_before') {
        return '< ' + this.get('lastSaleWas') + ' ' + this.get('lastSalePeriod');
      } else if (this.get('lastSaleSign') === 'last_sold_after') {
        return '> ' + this.get('lastSaleWas') + ' ' + this.get('lastSalePeriod');
      } else {
        return '-';
      }
    }),
    stockLevelDisplay: computed('stockLevelMin', 'stockLevelMax', function () {
      if (this.get('stockLevelMin') && this.get('stockLevelMax')) {
        return this.get('stockLevelMin') + ' - ' + this.get('stockLevelMax');
      } else if (this.get('stockLevelMin')) {
        return '>= ' + this.get('stockLevelMin');
      } else if (this.get('stockLevelMax')) {
        return '<= ' + this.get('stockLevelMax');
      } else {
        return '-';
      }
    }),
    stockDaysDisplay: computed('stockDaysMin', 'stockDaysMax', function () {
      if (this.get('stockDaysMin') && this.get('stockDaysMax')) {
        return this.get('stockDaysMin') + ' - ' + this.get('stockDaysMax');
      } else if (this.get('stockDaysMin')) {
        return '>= ' + this.get('stockDaysMin');
      } else if (this.get('stockDaysMax')) {
        return '<= ' + this.get('stockDaysMax');
      } else {
        return '-';
      }
    }),
    stockAgeDisplay: computed('stockAgeMin', 'stockAgeMax', function () {
      if (this.get('stockAgeMin') && this.get('stockAgeMax')) {
        return this.get('stockAgeMin') + ' - ' + this.get('stockAgeMax');
      } else if (this.get('stockAgeMin')) {
        return '>= ' + this.get('stockAgeMin');
      } else if (this.get('stockAgeMax')) {
        return '<= ' + this.get('stockAgeMax');
      } else {
        return '-';
      }
    }),
    ratingCountDisplay: computed('compRatingCountMin', 'compRatingCountMax', function () {
      if (this.get('compRatingCountMin') && this.get('compRatingCountMax')) {
        return this.get('compRatingCountMin') + ' - ' + this.get('compRatingCountMax');
      } else if (this.get('compRatingCountMin')) {
        return '>= ' + this.get('compRatingCountMin');
      } else if (this.get('compRatingCountMax')) {
        return '<= ' + this.get('compRatingCountMax');
      } else {
        return '-';
      }
    }),
    ratingDisplay: computed('compRatingMin', 'compRatingMax', function () {
      if (this.get('compRatingMin') && this.get('compRatingMax')) {
        return this.get('compRatingMin') + ' - ' + this.get('compRatingMax');
      } else if (this.get('compRatingMin')) {
        return '>= ' + this.get('compRatingMin');
      } else if (this.get('compRatingMax')) {
        return '<= ' + this.get('compRatingMax');
      } else {
        return '-';
      }
    })

  });
});