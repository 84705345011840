define('core/components/dash-sales', ['exports', 'ember-concurrency'], function (exports, _emberConcurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var service = Ember.inject.service;
  var computed = Ember.computed;
  exports.default = Component.extend({
    store: service(),
    ajax: service(),
    didInsertElement: function didInsertElement() {
      var _this = this;
      this.get('ajax').request('api/reports?data=sales').then(function (resp) {
        _this.set('data', resp);
      });
    }

  });
});