define('core/components/braintree-dropin', ['exports', 'ember-braintree-components/components/braintree-dropin'], function (exports, _braintreeDropin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _braintreeDropin.default;
    }
  });
});