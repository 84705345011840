define('core/models/project-record', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = _emberData.default.Model.extend({
    recordableType: _emberData.default.attr('string'),
    status: _emberData.default.attr('number'),
    completedAt: _emberData.default.attr('date'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    properties: _emberData.default.attr('string'),
    projectRecords: _emberData.default.hasMany('project-record'),
    projectBatch: _emberData.default.belongsTo('project-batch'),
    position: _emberData.default.attr({ readOnly: true }),
    pickLocation: _emberData.default.attr('string', { readOnly: true }),
    receivedItemId: _emberData.default.attr('number', { readOnly: true }),
    recordable: _emberData.default.belongsTo('recordable', { polymorphic: true }),
    model: computed('recordable', function () {
      this.set('recordable.projectRecordId', this.get('id'));
      return this.get('recordable');
    }),
    location: computed.alias('model.location'),
    visible: computed('projectBatch.visibleId', function () {
      console.log(this.get('projectBatch.visibleId'));
      var visible = this.get('id') === this.get('projectBatch.visibleId');
      return visible;
    })
  });
});