define('core/models/box', ['exports', 'ember-data', 'ember-api-actions'], function (exports, _emberData, _emberApiActions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = _emberData.default.Model.extend({
    inboundShipment: _emberData.default.belongsTo('inbound-shipment'),
    shipment: _emberData.default.belongsTo('shipment'),
    weight: _emberData.default.attr('number'),
    trackingNumber: _emberData.default.attr('string'),
    shipmentBoxNumber: _emberData.default.attr('number'),
    number: computed.alias('shipmentBoxNumber'),
    boxNumber: computed.alias('shipmentBoxNumber'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    image: _emberData.default.attr('string'),
    imageFormat: _emberData.default.attr('number'),
    transactionId: _emberData.default.attr('string'),
    png: _emberData.default.attr('string'),
    preshippedAt: _emberData.default.attr('date'),
    user: _emberData.default.belongsTo('user'),
    carton: _emberData.default.belongsTo('carton'),
    shippingMethod: _emberData.default.belongsTo('shipping-method'),
    contents: _emberData.default.hasMany('box-content'),
    pounds: computed('weight', function () {
      if (this.get('weight') && this.get('weight') > 0) {
        return Math.floor(this.get('weight') / 16) + ' LBs ' + Math.round(this.get('weight') % 16) + ' oz';
      }
    }),
    trackingLink: computed('shippingMethod.carrier', 'trackingNumber', function () {
      if (this.get('shippingMethod.carrier') === 'USPS') {
        return 'https://tools.usps.com/go/TrackConfirmAction?qtc_tLabels1=' + this.get('trackingNumber');
      } else {
        return 'http://wwwapps.ups.com/WebTracking/track?track=yes&trackNums=' + this.get('trackingNumber');
      }
    }),
    print: (0, _emberApiActions.memberAction)({ path: 'print', type: 'put' })
  });
});