define('core/components/th-sortable', ['exports', 'ember-data-table/components/th-sortable'], function (exports, _thSortable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _thSortable.default;
    }
  });
});