define('core/models/amazon-product', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    operation: _emberData.default.attr('string'),
    sku: _emberData.default.attr('string'),
    productIdType: _emberData.default.attr('string'),
    productIdValue: _emberData.default.attr('string'),
    title: _emberData.default.attr('string'),
    brand: _emberData.default.attr('string'),
    bullet1: _emberData.default.attr('string'),
    bullet2: _emberData.default.attr('string'),
    bullet3: _emberData.default.attr('string'),
    bullet4: _emberData.default.attr('string'),
    bullet5: _emberData.default.attr('string'),
    manufacturer: _emberData.default.attr('string'),
    mfrPartNumber: _emberData.default.attr('string'),
    searchTerms1: _emberData.default.attr('string'),
    searchTerms2: _emberData.default.attr('string'),
    searchTerms3: _emberData.default.attr('string'),
    searchTerms4: _emberData.default.attr('string'),
    searchTerms5: _emberData.default.attr('string'),
    status: _emberData.default.attr('number'),
    resultCode: _emberData.default.attr('string'),
    resultMessageCode: _emberData.default.attr('string'),
    resultDescription: _emberData.default.attr('string'),
    feedMessageId: _emberData.default.attr('number'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    shippingTemplate: _emberData.default.attr('string'),
    amazonRequest: _emberData.default.belongsTo('amazon-request'),
    amazonListing: _emberData.default.belongsTo('amazon-listing')
  });
});