define('core/components/edit-picking-tote', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    actions: {
      setRestocks: function setRestocks() {
        this.set('tote.target', 'restocks');
        this.get('tote').then(function (tote) {
          tote.save();
        });
      }
    }
  });
});