define('core/components/notify-channel', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var service = Ember.inject.service;
  exports.default = Component.extend({
    notify: service(),
    cable: service(),
    currentUser: service(),
    recent: [],
    didInsertElement: function didInsertElement() {
      var _this = this;

      setTimeout(function () {

        var consumer = _this.get('consumer') || _this.get('cable').createConsumer((window.location.protocol + '//' + window.location.hostname).replace('http', 'ws').replace('dv', 'core') + '/websocket?jwt=' + _this.get('currentUser.user.jwt'));
        consumer.subscriptions.create("NotifyChannel", {
          connected: function connected() {
            console.log('Notify connected!');
          },

          received: function received(payload) {
            console.log(payload.userId);
            console.log(_this.get('currentUser.user.id'));
            if (!payload.userId || payload.userId * 1 === _this.get('currentUser.user.id') * 1) {

              if (payload.info) {
                _this.get('notify').info(payload.info);
              }
              if (payload.success) {
                _this.get('notify').success(payload.success);
              }
              if (payload.warning) {
                _this.get('notify').warning(payload.warning);
              }
              if (payload.alert) {
                _this.get('notify').alert(payload.alert);
              }
              if (payload.error) {
                _this.get('notify').error(payload.error);
              }
            }
            console.log(payload);
            // }
          },
          disconnected: function disconnected() {
            console.log("NotifyChannel#disconnected");
          }
        });

        consumer.subscriptions.create("UserChannel", {
          connected: function connected() {
            console.log('User connected!');
          },

          received: function received(payload) {
            if (!payload.userId || payload.userId * 1 === _this.get('currentUser.user.id') * 1) {

              if (payload.info) {
                _this.get('notify').info(payload.info);
              }
              if (payload.success) {
                _this.get('notify').success(payload.success);
              }
              if (payload.warning) {
                _this.get('notify').warning(payload.warning);
              }
              if (payload.alert) {
                _this.get('notify').alert(payload.alert);
              }
              if (payload.error) {
                _this.get('notify').error(payload.error);
              }
            }
            console.log(payload);
            // }
          },
          disconnected: function disconnected() {
            console.log("UserChannel#disconnected");
          }
        });
      }, 5000);
    }
  });
});