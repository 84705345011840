define('core/models/ebay-offer', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    offerId: _emberData.default.attr('string'),
    listingId: _emberData.default.attr('string'),
    publishedAt: _emberData.default.attr('date'),
    quantityLimit: _emberData.default.attr('number'),
    quantityLimitPerBuyer: _emberData.default.attr('number'),
    price: _emberData.default.attr('number'),
    promotionAmount: _emberData.default.attr('number'),
    fulfillmentPolicy: _emberData.default.belongsTo('ebay-policy'),
    paymentPolicy: _emberData.default.belongsTo('ebay-policy'),
    returnPolicy: _emberData.default.belongsTo('ebay-policy'),
    ebayPromotion: _emberData.default.belongsTo('ebay-promotion'),
    categoryName: _emberData.default.attr('string'),
    categoryId: _emberData.default.attr('string'),
    specifics: _emberData.default.attr('string'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    item: _emberData.default.belongsTo('item'),
    sync: _emberData.default.attr('boolean'),
    publish: _emberData.default.attr('boolean'),
    storeCategories: _emberData.default.attr('string'),
    multiple: _emberData.default.attr('number'),
    imageUrl: _emberData.default.attr('string')

  });
});