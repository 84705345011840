define('core/models/department', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    fullName: _emberData.default.attr('string'),
    code: _emberData.default.attr('string'),
    address1: _emberData.default.attr('string'),
    address2: _emberData.default.attr('string'),
    city: _emberData.default.attr('string'),
    state: _emberData.default.attr('string'),
    zip: _emberData.default.attr('string'),
    country: _emberData.default.attr('string'),
    phone: _emberData.default.attr('string'),
    fax: _emberData.default.attr('string'),
    url: _emberData.default.attr('string'),
    customerServiceEmail: _emberData.default.attr('string'),
    emailAccount: _emberData.default.attr('string'),
    apiKey: _emberData.default.attr('string'),
    shopifyEndpoint: _emberData.default.attr('string'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    refreshToken: _emberData.default.attr('string'),
    accessToken: _emberData.default.attr('string'),
    accessTokenExpiresAt: _emberData.default.attr('date'),
    shopifyProducts: _emberData.default.hasMany('shopify-product'),
    shopifyVariants: _emberData.default.hasMany('shopify-variant'),
    shopifyPages: _emberData.default.hasMany('shopify-page'),
    shopifyCollections: _emberData.default.hasMany('shopify-collection'),
    orders: _emberData.default.hasMany('order'),
    shipments: _emberData.default.hasMany('shipment')
  });
});