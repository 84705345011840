define('core/components/edit-categories', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var service = Ember.inject.service;
  var computed = Ember.computed;
  exports.default = Component.extend({
    store: service(),
    amazonListings: computed('model.name', function () {
      return this.get('store').peekAll('fba-listing');
    }),
    quantity: computed('model.name', function () {
      var val = this.get('store').peekAll('fba-listing').reduce(function (acc, val) {
        console.log(acc);
        console.log(val);
        acc += val.get('quantity');
        return acc;
      }, 0);
      return val;
    }),
    inStockQuantity: computed('model.name', function () {
      var val = this.get('store').peekAll('fba-listing').reduce(function (acc, val) {
        console.log(acc);
        console.log(val);
        acc += val.get('inStockQuantity');
        return acc;
      }, 0);
      return val;
    }),
    monthlyUnits: computed('model.name', function () {
      var val = this.get('store').peekAll('fba-listing').reduce(function (acc, val) {
        console.log(acc);
        console.log(val);
        acc += val.get('monthlyUnits');
        return acc;
      }, 0);
      return val;
    }),
    revenue: computed('model.name', function () {
      var val = this.get('store').peekAll('fba-listing').reduce(function (acc, val) {
        console.log(acc);
        console.log(val);
        acc += val.get('monthlySales');
        return acc;
      }, 0);
      return val;
    })
  });
});