define('core/models/order-payment', ['exports', 'ember-data', 'ember-api-actions'], function (exports, _emberData, _emberApiActions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = _emberData.default.Model.extend({
    storeId: _emberData.default.attr('number'),
    name: _emberData.default.attr('string'),
    amount: _emberData.default.attr('number'),
    accountHolder: _emberData.default.attr('string'),
    creditCardNumber: _emberData.default.attr('string'),
    creditCardExpiration: _emberData.default.attr('string'),
    creditCardApprovalCode: _emberData.default.attr('string'),
    posBatchId: _emberData.default.attr('number'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    order: _emberData.default.belongsTo('order'),
    tender: _emberData.default.belongsTo('tender'),
    cardType: _emberData.default.attr('string'),
    paymentType: _emberData.default.attr('string'),
    authCode: _emberData.default.attr('string'),
    authAmount: _emberData.default.attr('number'),
    cvvResult: _emberData.default.attr('string'),
    avsResult: _emberData.default.attr('string'),
    paymentToken: _emberData.default.belongsTo('payment-token'),
    customer: _emberData.default.belongsTo('customer'),
    status: _emberData.default.attr('string'),
    lastFour: computed('creditCardNumber', function () {
      if (this.get('creditCardNumber')) {
        return this.get('creditCardNumber').replace(/\*/g, '');
      }
    }),
    voidPayment: (0, _emberApiActions.memberAction)({ path: 'void_payment', type: 'put' }),
    refundPayment: (0, _emberApiActions.memberAction)({ path: 'refund_payment', type: 'put' }),
    chargeCard: (0, _emberApiActions.memberAction)({ path: 'charge_card', type: 'put' })
  });
});