define('core/models/project-batch', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  var mapBy = Ember.computed.mapBy;
  var filter = Ember.computed.filter;
  var sort = Ember.computed.sort;
  exports.default = _emberData.default.Model.extend({
    status: _emberData.default.attr('number'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    completedAt: _emberData.default.attr('date'),
    heldAt: _emberData.default.attr('date'),
    projectRecords: _emberData.default.hasMany('project-record'),
    project: _emberData.default.belongsTo('project'),
    user: _emberData.default.belongsTo('user'),
    release: _emberData.default.attr('boolean'),
    records: mapBy('projectRecords', 'model'),
    incompleteRecords: filter('projectRecords.@each.completedAt', function (record) {
      return !record.get('completedAt');
    }),
    progress: computed('records', 'incompleteRecords', function () {
      return this.get('records.length') - this.get('incompleteRecords.length') + 1;
    }),
    sortedRecords: sort('incompleteRecords', 'sortOrder'),
    sortOrder: computed('project', function () {
      console.log(this.get('project.emberSort'));
      console.log(this.get('project.emberSort').split(','));
      return this.get('project.emberSort').split(',');
    }),
    allSortedRecords: sort('projectRecords', 'sortOrder'),
    sortedLocations: computed('allSortedRecords', function () {
      return this.get('allSortedRecords').reduce(function (acc, value) {
        acc.push(value.get('model.receivingBin.stationId') + ' ' + value.get('model.receivingBin.posInt'));
        return acc;
      }, []);
    }),
    visibleId: computed('sortedRecords.@each.completedAt', function () {
      // console.log('sortedLocations');
      console.log(this.get('sortedLocations'));
      return this.get('sortedRecords.firstObject.id');
    }),
    canContinue: computed('incompleteRecords', function () {
      return this.get('incompleteRecords').length === 0;
    })
  });
});