define('core/models/category', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    depth: _emberData.default.attr('number'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    seasonStartsOn: _emberData.default.attr('date'),
    seasonEndsOn: _emberData.default.attr('date'),
    lastSeasonStartedOn: _emberData.default.attr('date'),
    lastSeasonEndedOn: _emberData.default.attr('date'),
    categoryItems: _emberData.default.hasMany('category-item'),
    parent: _emberData.default.belongsTo('category', { inverse: 'children' }),
    children: _emberData.default.hasMany('categories'),
    tree: computed('parent.tree', 'name', function () {
      if (this.get('parent.tree')) {
        return this.get('parent.tree') + ' > ' + this.get('name');
      } else {
        return this.get('name');
      }
    })
  });
});