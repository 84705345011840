define('core/models/destination', ['exports', 'ember-data', 'ember-api-actions'], function (exports, _emberData, _emberApiActions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = _emberData.default.Model.extend({
    stockableType: _emberData.default.attr('string'),
    quantityAssigned: _emberData.default.attr('number'),
    quantityCompleted: _emberData.default.attr('number'),
    quantityRemaining: computed('quantityAssigned', 'quantityCompleted', function () {
      return this.get('quantityAssigned') - this.get('quantityCompleted') * 1;
    }),
    sku: computed('stockable.sku', 'stockable.type', function () {
      console.log(this.get('id'));
      if (this.get('stockable.sku') && this.get('stockable.sku').slice(0, 3) == 'FSL' && this.get('stockable.type') && this.get('stockable.type') !== 'FslListing') {
        console.log(this.get('id'));
        return '*' + this.get('stockable.sku');
      }
      console.log(this.get('id'));
      return this.get('stockable.sku');
    }),
    incomplete: computed('quantityRemaining', function () {
      return this.get('quantityRemaining') > 0;
    }),
    quantityToting: computed('quantityAssigned', 'quantityCompleted', function () {
      return this.get('quantityAssigned') - this.get('quantityCompleted') * 1;
    }),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    purchaseOrderItem: _emberData.default.belongsTo('purchase-order-item'),
    receivedItemId: _emberData.default.attr('number'),
    item: _emberData.default.belongsTo('item', { readOnly: true }),
    amazonListing: _emberData.default.belongsTo('amazon-listing', { readOnly: true }),
    fslListing: _emberData.default.belongsTo('fsl-listing', { readOnly: true }),
    fbaListing: _emberData.default.belongsTo('fba-listing'),
    unfulfillableReason: _emberData.default.attr('string'),
    completeShipment: _emberData.default.attr('boolean'),
    label: (0, _emberApiActions.memberAction)({ path: 'label', type: 'put' }),
    scannable: computed('stockableType', function () {
      return this.get('stockableType') !== 'AmazonListing' || this.get('unfulfillableReason');
    }),
    issue: _emberData.default.attr('number'),
    listing: computed(function () {
      if (this.get('amazonListing.id')) {
        return this.get('amazonListing');
      }
      if (this.get('fbaListing.id')) {
        return this.get('fbaListing');
      }
      if (this.get('fslListing.id')) {
        return this.get('fslListing');
      }
    }),
    stockItem: computed('stockable', 'stockableType', function () {
      if (this.get('stockableType') === 'Item') {
        return this.get('stockable');
      } else if (this.get('stockableType') === 'InboundShipmentItem') {
        return this.get('stockable.amazonListing.item');
      } else {
        return this.get('stockable.item');
      }
    }),
    receivingBin: computed('stockable.bins.length', 'stockable.bin.receivingGroupId', 'stockable.receivingBin.id', function () {
      var station = this.get('purchaseOrderItem.purchaseOrder.stationId');
      if (this.get('stockableType') === 'InboundShipmentItem') {
        if (this.get('receivedItemId')) {
          return this.get('stockable.preppedReceivingBin');
        }
        return this.get('stockable.receivingBin');
      } else {
        var group = this.get('stockable.bin.receivingGroupId');
        var bins = this.get('store').peekAll('receivingBin');
        group = 'complete_shipments';
        if (this.get('stockableType') === 'OrderItem' && this.get('completeShipment')) {
          group = 'complete_shipments';
        } else if (this.get('stockableType') === 'OrderItem') {
          group = 'ordered_items';
        } else if (this.get('stockableType') === 'Item') {

          var receivingGroupId = this.get('stockable.bin.receivingGroupId');

          bins = bins.filter(function (bin) {
            return bin.get('stationId') === station && bin.get('receivingGroupId') === receivingGroupId;
          });

          return bins.get('firstObject');
        }

        bins = bins.filter(function (bin) {
          return bin.get('stationId') === station && bin.get('binType') === group;
        });

        var bin = bins.get('firstObject');
        return bin;
      }
    }),
    receivingTote: computed('stockable.receivingBin.id', 'receivingBin.id', 'receivingBin.currentTote.code', 'receivingBin.currentTote.id', function () {
      if (this.get('stockableType') === 'InboundShipmentItem') {
        if (this.get('receivedItemId')) {
          return this.get('stockable.preppedReceivingTote');
        }
        return this.get('stockable.receivingTote');
      }
      return this.get('receivingBin.currentTote');
    }),
    scannableTote: computed('stockable.scannableTote', 'receivingBin.id', 'receivingBin.currentTote.id', 'receivingTote.code', 'receivingTote.filledAt', function () {
      if (this.get('stockableType') === 'InboundShipmentItem') {
        if (this.get('receivedItemId')) {
          return this.get('stockable.preppedScannableTote');
        }
        return this.get('stockable.scannableTote');
      } else if (this.get('receivingTote.filledAt') || this.get('receivingTote.transferredAt') || this.get('receivingTote.stockingAt') || this.get('receivingTote.inboundShipment.id')) {
        return 'Scan New Tote';
      } else {
        return this.get('receivingTote.code');
      }
    }),
    stockableId: _emberData.default.attr('number'),
    stockable: computed('listing', 'item', 'orderItem', 'inboundShipmentItem', function () {
      if (this.get('listing.id')) {
        return this.get('listing');
      }
      if (this.get('item.id')) {
        return this.get('item');
      }
      if (this.get('orderItem.id')) {
        return this.get('orderItem');
      }
      if (this.get('inboundShipmentItem')) {
        return this.get('inboundShipmentItem');
      }
    }),
    isInbound: computed('stockableType', function () {
      return this.get('stockableType') === 'InboundShipmentItem';
    }),
    shortType: computed('stockableType', function () {
      return this.get('stockableType') === 'InboundShipmentItem' ? 'Shipment' : this.get('stockableType');
    }),
    altScan: computed('stockableType', 'stockable', function () {
      if (this.get('stockableType') === 'InboundShipmentItem') {
        return this.get('stockable.afnSku');
      }
      return 'N/A';
    }),
    image: computed.alias('stockItem.largeImageUrl'),
    stock: computed.alias('stockable.item'),
    orderItem: _emberData.default.belongsTo('order-item'),
    inboundShipmentItem: _emberData.default.belongsTo('inbound-shipment-item'),
    inboundShipment: computed.alias('inboundShipmentItem.inboundShipment'),
    style: computed('stockableType', 'completeShipment', 'stockable.amazonListing.sku', function () {
      if (this.get('stockableType') === 'OrderItem' && this.get('completeShipment')) {
        return 'order-item-complete';
      } else if (this.get('stockableType') === 'OrderItem') {
        return 'order-item';
      } else if (this.get('stockableType') == 'Item') {
        return 'item';
      } else if (this.get('stockableType') == 'InboundShipmentItem' && this.get('stockable.amazonListing.sku') && this.get('stockable.amazonListing.type') === 'FslListing') {
        return 'fsl-listing';
      } else if (this.get('stockableType') == 'InboundShipmentItem') {
        return 'fba-listing';
      } else if (this.get('stockableType') == 'AmazonListing') {
        return 'amazon-listing';
      }
    }),
    destType: computed('stockableType', 'completeShipment', 'stockable.amazonListing.sku', function () {
      if (this.get('stockableType') === 'OrderItem') {
        return 'Order';
      } else if (this.get('stockableType') == 'Item') {
        return 'Warehouse';
      } else if (this.get('stockableType') == 'InboundShipmentItem' && this.get('stockable.amazonListing.sku') && this.get('stockable.amazonListing.type') === 'FslListing') {
        return 'FSL';
      } else if (this.get('stockableType') == 'InboundShipmentItem') {
        return 'FBA';
      } else if (this.get('stockableType') == 'AmazonListing') {
        return 'FBA-Prep';
      }
    })

  });
});