define('core/adapters/application', ['exports', 'ember-data', 'ember-simple-auth/mixins/data-adapter-mixin', 'core/config/environment'], function (exports, _emberData, _dataAdapterMixin, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var _Ember$String = Ember.String,
      pluralize = _Ember$String.pluralize,
      decamelize = _Ember$String.decamelize,
      underscore = _Ember$String.underscore;
  exports.default = _emberData.default.JSONAPIAdapter.extend(_dataAdapterMixin.default, {
    host: '' + _environment.default.host,
    authorizer: 'authorizer:custom',
    urlForQuery: function urlForQuery(query) {
      if (query.resource) {
        var resource = query.resource;
        delete query.resource;
        return this._super.apply(this, arguments) + '/' + resource;
      }

      return this._super.apply(this, arguments);
    },
    pathForType: function pathForType(type) {
      var decamelized = decamelize(type);
      var underscored = underscore(decamelized);
      return pluralize(underscored);
    }
  });
});