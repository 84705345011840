define('core/models/shipping-service', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    code: _emberData.default.attr('string'),
    transitDays: _emberData.default.attr('number'),
    guaranteed: _emberData.default.attr('boolean'),
    requiresTracking: _emberData.default.attr('boolean'),
    delivery: _emberData.default.attr('boolean'),
    priority: _emberData.default.attr('boolean'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    usnId: _emberData.default.attr('number'),
    ziggosId: _emberData.default.attr('number'),
    ebayId: _emberData.default.attr('string'),
    amazonId: _emberData.default.attr('string'),
    shopifyId: _emberData.default.attr('string'),
    upsId: _emberData.default.attr('string')
  });
});