define('core/models/item-property-value', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    variant: _emberData.default.attr('boolean'),
    item: _emberData.default.belongsTo('item'),
    propertyValue: _emberData.default.belongsTo('property-value')
  });
});