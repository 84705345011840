define('core/routes/projects/manage', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  exports.default = Route.extend({
    model: function model(params) {
      return this.get('store').query('project', { filter: { layout: 'ember' }, include: ['project_users'] });
    }
  });
});