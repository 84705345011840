define('core/components/manage-users', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var service = Ember.inject.service;
  exports.default = Component.extend({
    store: service(),
    router: service(),
    actions: {
      saveUser: function saveUser(user) {
        user.save();
      },
      newUser: function newUser() {
        var user = this.get('store').createRecord('user');
        user.save();
        this.get('router').transitionTo('users.edit', user);
      }
    }
  });
});