define('core/components/pending-order-charts', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var service = Ember.inject.service;
  exports.default = Component.extend({
    cable: service(),
    store: service(),
    currentUser: service(),
    sortOrder: ['placedAt:desc'],
    ajax: service(),
    didInsertElement: function didInsertElement() {
      var _this = this;

      setTimeout(function () {

        _this.get('ajax').request('/api/pending_orders/overview?data=charts').then(function (data) {
          if (data.charts) {
            data.charts.forEach(function (chart) {
              _this.set(chart.chart, chart.data);
            });
          }
        });

        var consumer = _this.get('consumer') || _this.get('cable').createConsumer((window.location.protocol + '//' + window.location.hostname).replace('http', 'ws').replace('dv', 'core') + '/websocket?jwt=' + _this.get('currentUser.user.jwt'));
        consumer.subscriptions.create("ChartChannel", {
          connected: function connected() {
            console.log('chart connected');
          },
          received: function received(payload) {
            if (payload.chart && payload.data) {
              _this.set(payload.chart, { series: null });
              setTimeout(function () {
                _this.set(payload.chart, payload.data);
              }, 100);
            }
          },
          disconnected: function disconnected() {
            console.log("ChartChannel#disconnected");
          }
        });

        // Using mixin and inject your services
        var receiver = function receiver(data) {
          if (data.model && data.id && data.action == 'reload') {
            console.log('reload');
            _this.get('store').findRecord(data.model, data.id);
          }
        };

        consumer.subscriptions.create({ channel: 'ChartChannel' }, receiver);
      }, 5000);
    }
  });
});