define('core/models/shipment', ['exports', 'ember-data', 'ember-api-actions'], function (exports, _emberData, _emberApiActions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = _emberData.default.Model.extend({
    orderId: _emberData.default.attr('number', { readOnly: true }),
    trackingNumber: _emberData.default.attr('string'),
    cost: _emberData.default.attr('number'),
    charge: _emberData.default.attr('number'),
    name: _emberData.default.attr('string'),
    company: _emberData.default.attr('string'),
    address1: _emberData.default.attr('string'),
    address2: _emberData.default.attr('string'),
    city: _emberData.default.attr('string'),
    state: _emberData.default.attr('string'),
    zip: _emberData.default.attr('string'),
    country: _emberData.default.attr('string'),
    email: _emberData.default.attr('string'),
    phone: _emberData.default.attr('string'),
    fax: _emberData.default.attr('string'),
    status: _emberData.default.attr('string'),
    station: _emberData.default.attr('number'),
    pickableAt: _emberData.default.attr('date'),
    pickedAt: _emberData.default.attr('date'),
    picked: _emberData.default.attr('boolean'),
    backorderedAt: _emberData.default.attr('date'),
    shippedAt: _emberData.default.attr('date'),
    expectedAt: _emberData.default.attr('date'),
    calculatedAt: _emberData.default.attr('date'),
    guaranteedAt: _emberData.default.attr('date'),
    mustShipBy: _emberData.default.attr('date'),
    deliveredAt: _emberData.default.attr('date'),
    feedbackRequestedAt: _emberData.default.attr('date'),
    marketConfirmedAt: _emberData.default.attr('date'),
    rma: _emberData.default.attr('string'),
    requiresTracking: _emberData.default.attr('boolean'),
    boxes: _emberData.default.hasMany('box'),
    guaranteed: _emberData.default.attr('boolean'),
    blind: _emberData.default.attr('boolean'),
    correction: _emberData.default.attr('boolean'),
    rateOnly: _emberData.default.attr('boolean'),
    notes: _emberData.default.attr('string'),
    prime: _emberData.default.attr('boolean'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    lossAmount: _emberData.default.attr('number'),
    lossApprovedAt: _emberData.default.attr('date'),
    lossApprovedBy: _emberData.default.belongsTo('user'),
    //projectRecords: DS.(('project-record'),
    order: _emberData.default.belongsTo('order'),
    shippingService: _emberData.default.belongsTo('shipping-service'),
    shippingMethod: _emberData.default.belongsTo('shipping-method'),
    //pickingBatch: DS.belongsTo('picking-batch'),
    storeId: _emberData.default.attr('number'),
    processAt: _emberData.default.attr('date'),
    processAction: _emberData.default.attr('string'),
    department: _emberData.default.belongsTo('department'),
    picker: _emberData.default.belongsTo('user'),
    shipper: _emberData.default.belongsTo('user'),
    shopifyId: _emberData.default.attr('string'),
    carton: _emberData.default.belongsTo('carton'),
    orderItems: _emberData.default.hasMany('order-item'),
    units: computed.mapBy('orderItems', 'quantityShipped'),
    unitCount: computed.sum('units'),
    printInvoice: (0, _emberApiActions.memberAction)({ path: 'print_invoice', type: 'put' }),
    process: (0, _emberApiActions.memberAction)({ path: 'process_shipment', type: 'put' }),
    addCarton: (0, _emberApiActions.memberAction)({ path: 'add_carton', type: 'put' }),
    voidShipment: (0, _emberApiActions.memberAction)({ path: 'void_shipment', type: 'put' }),
    pickingError: _emberData.default.attr('boolean', { readOnly: true }),
    preshippedAt: _emberData.default.attr('date'),
    processDate: computed(function () {
      var date = new Date();
      date.setDate(date.getDate() + 1);
      return date;
    }),
    location: _emberData.default.attr('string', { readOnly: true }),
    //channel: DS.belongsTo('channel'),
    pickingTote: _emberData.default.belongsTo('picking-tote'),
    pickingToteId: _emberData.default.attr('number', { readOnly: true }),
    minDate: computed('createdAt', function () {
      return this.get('createdAt') || new Date();
    }),
    rmaAllowed: computed('shippedAt', function () {
      return this.get('shippedAt') && this.get('shippedAt') * 1 > new Date() * 1 - 30 * 24 * 60 * 60 * 1000;
    }),
    mustShipToday: computed('mustShipBy', function () {
      var t = new Date();
      return this.get('mustShipBy') && this.get('mustShipBy') < t.setDate(t.getDate() + 1);
    }),
    priority: computed('charge', function () {
      return this.get('charge') * 1 > 25;
    })
  });
});