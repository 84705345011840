define('core/models/item', ['exports', 'ember-data', 'ember-api-actions'], function (exports, _emberData, _emberApiActions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = _emberData.default.Model.extend({
    sku: _emberData.default.attr('string'),
    location: _emberData.default.attr('string', { readOnly: true }),
    bins: _emberData.default.hasMany('bin', { inverse: 'item' }),
    supplierItems: _emberData.default.hasMany('supplier-item', { inverse: 'item' }),
    detailPages: _emberData.default.hasMany('detail-page'),
    tags: _emberData.default.hasMany('tag'),
    bin: computed.alias('bins.firstObject'),
    receivingBin: computed.alias('bin.receivingBin'),
    upc: _emberData.default.attr('string'),
    name: _emberData.default.attr('string'),
    quantity: _emberData.default.attr('number'),
    quantityOnOrder: _emberData.default.attr('number', { readOnly: true }),
    supplierQuantity: _emberData.default.attr('number'),
    fulfillmentQuantity: _emberData.default.attr('number'),
    quantityCounted: _emberData.default.attr('number'),
    cost: _emberData.default.attr('number'),
    price: _emberData.default.attr('number'),
    mapPrice: _emberData.default.attr('number'),
    msrp: _emberData.default.attr('number'),
    amazonPrice: _emberData.default.attr('number'),
    neverMissing: _emberData.default.attr('boolean'),
    restockLevel: _emberData.default.attr('number'),
    reorderPoint: _emberData.default.attr('number'),
    warehouseRestockLevel: _emberData.default.attr('number'),
    warehouseReorderPoint: _emberData.default.attr('number'),
    minimumQuantity: _emberData.default.attr('number'),
    retailUnits: _emberData.default.attr('number'),
    pieces: _emberData.default.attr('number'),
    unitOfMeasure: _emberData.default.attr('string'),
    barcodeFormat: _emberData.default.attr('number'),
    quantityCommitted: _emberData.default.attr('number'),
    fulfillmentQuantityCommitted: _emberData.default.attr('number'),
    lastCost: _emberData.default.attr('number'),
    replacementCost: _emberData.default.attr('number'),
    taxable: _emberData.default.attr('boolean'),
    inactive: _emberData.default.attr('boolean'),
    doNotOrder: _emberData.default.attr('boolean'),
    itemType: _emberData.default.attr('string'),
    shippingWeight: _emberData.default.attr('number'),
    weight: _emberData.default.attr('number'),
    length: _emberData.default.attr('number'),
    width: _emberData.default.attr('number'),
    height: _emberData.default.attr('number'),
    nest: _emberData.default.attr('number'),
    shelfLength: _emberData.default.attr('number'),
    shelfWidth: _emberData.default.attr('number'),
    shelfHeight: _emberData.default.attr('number'),
    shelfNest: _emberData.default.attr('number'),
    cubiscannedAt: _emberData.default.attr('date'),
    lastCountedAt: _emberData.default.attr('date'),
    lastSoldAt: _emberData.default.attr('date'),
    lastMissingAt: _emberData.default.attr('date'),
    lastStockedAt: _emberData.default.attr('date'),
    lastReceivedAt: _emberData.default.attr('date'),
    discontinuedAt: _emberData.default.attr('date'),
    lastBackorderedAt: _emberData.default.attr('date'),
    fulfillmentQuantityUpdatedAt: _emberData.default.attr('date'),
    seasonEndsOn: _emberData.default.attr('date'),
    imageSize: _emberData.default.attr('number'),
    protection: _emberData.default.attr('number'),
    lockWeight: _emberData.default.attr('boolean'),
    lockDimensions: _emberData.default.attr('boolean'),
    lockCarton: _emberData.default.attr('boolean'),
    priceA: _emberData.default.attr('number'),
    priceB: _emberData.default.attr('number'),
    priceC: _emberData.default.attr('number'),
    ziggos: _emberData.default.attr('number'),
    ypd: _emberData.default.attr('number'),
    ebay: _emberData.default.attr('number'),
    caseCode: _emberData.default.attr('string'),
    masterCode: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    htmlDescription: _emberData.default.attr('string'),
    title: _emberData.default.attr('string'),
    bullet1: _emberData.default.attr('string'),
    bullet2: _emberData.default.attr('string'),
    bullet3: _emberData.default.attr('string'),
    bullet4: _emberData.default.attr('string'),
    bullet5: _emberData.default.attr('string'),
    search1: _emberData.default.attr('string'),
    search2: _emberData.default.attr('string'),
    search3: _emberData.default.attr('string'),
    search4: _emberData.default.attr('string'),
    search5: _emberData.default.attr('string'),
    replacedDescription: computed('description', 'title', 'bullet1', 'bullet2', 'bullet3', 'bullet4', 'bullet5', function () {
      return this.get('description').replace('[T]', this.get('title')).replace('[B1]', this.get('bullet1')).replace('[B2]', this.get('bullet2')).replace('[B3]', this.get('bullet3')).replace('[B4]', this.get('bullet4')).replace('[B5]', this.get('bullet5')).replace('[B]', '<br>' + ('' + (this.get('bullet1') ? this.get('bullet1') + "<br>" : '') + (this.get('bullet2') ? this.get('bullet2') + "<br>" : '') + (this.get('bullet3') + "<br>" ? this.get('bullet3') : '') + (this.get('bullet4') + "<br>" ? this.get('bullet4') : '') + (this.get('bullet5') + "<br>" ? this.get('bullet5') : '')));
    }),
    notes: _emberData.default.attr('string'),
    relocate: _emberData.default.attr('boolean'),
    catalog: _emberData.default.attr('number'),
    averageCost: _emberData.default.attr('number'),
    warehouseQuantity: _emberData.default.attr('number'),
    backupQuantity: _emberData.default.attr('number'),
    position: _emberData.default.attr('number'),
    vendorTitle: _emberData.default.attr('string'),
    vendorDescription: _emberData.default.attr('string'),
    titleSnippet: _emberData.default.attr('string'),
    measures: _emberData.default.attr('string'),
    soldAsCase: _emberData.default.attr('boolean'),
    syncedAt: _emberData.default.attr('date'),
    casePrice: _emberData.default.attr('number'),
    countAt: _emberData.default.attr('date'),
    jet: _emberData.default.attr('number'),
    salePrice: _emberData.default.attr('number'),
    age: _emberData.default.attr('number'),
    mustShipGround: _emberData.default.attr('boolean'),
    publishUsn: _emberData.default.attr('boolean'),
    publishZiggos: _emberData.default.attr('boolean'),
    supplierCost: _emberData.default.attr('number'),
    reorderNumber: _emberData.default.attr('string'),
    mpq: _emberData.default.attr('number'),
    moq: _emberData.default.attr('number'),
    innerQuantity: _emberData.default.attr('number'),
    amazonMatchData: _emberData.default.attr('', { readOnly: true }),
    pickPosition: _emberData.default.attr('number', { readOnly: true }),
    units: _emberData.default.attr('number', { readOnly: true }),
    createdAt: _emberData.default.attr('date', { readOnly: true }),
    updatedAt: _emberData.default.attr('date', { readOnly: true }),
    projectRecordId: _emberData.default.attr('number'),
    tag: _emberData.default.attr('string'),
    binOptions: (0, _emberApiActions.memberAction)({ path: 'bin_options', type: 'get' }),
    report: (0, _emberApiActions.memberAction)({ path: 'report', type: 'get' }),
    assignLocation: (0, _emberApiActions.memberAction)({ path: 'assign_location', type: 'put' }),
    stockInfo: (0, _emberApiActions.memberAction)({ path: 'stock_info', type: 'get' }),
    updateStockInfo: (0, _emberApiActions.memberAction)({ path: 'update_stock_info', type: 'put' }),
    print: (0, _emberApiActions.memberAction)({ path: 'print', type: 'put' }),
    totalValue: computed('averageCost', 'cost', 'quantity', function () {
      if (this.get('averageCost') === 0) {
        return 0;
      }
      return (this.get('averageCost') || this.get('cost')) * this.get('quantity');
    }),
    spokenLocation: computed('location', function () {
      return;
    }),
    editableSku: computed('id', function () {
      return this.get('id');
    }),
    smallImageUrl: computed('sku', function () {
      return 'https://d1jnvibcc0nxly.cloudfront.net/fit-in/50x50/' + this.get('sku') + '.jpg';
    }),
    imageUrl: computed('sku', function () {
      return 'https://d1jnvibcc0nxly.cloudfront.net/fit-in/100x100/' + this.get('sku') + '.jpg';
    }),
    largeImageUrl: computed('sku', function () {
      return 'https://d1jnvibcc0nxly.cloudfront.net/fit-in/200x200/' + this.get('sku') + '.jpg';
    }),
    shopifyImageUrl: computed('sku', function () {
      return 'https://d1jnvibcc0nxly.cloudfront.net/fit-in/1000x1000/' + this.get('sku') + '.jpg';
    }),
    amazonMatches: computed('amazonMatchData.products.product', function () {
      if (this.get('amazonMatchData.products.product')) {
        if (Array.isArray(this.get('amazonMatchData.products.product'))) {
          return this.get('amazonMatchData.products.product');
        } else {
          return [this.get('amazonMatchData.products.product')];
        }
      }
    }),
    supplier: _emberData.default.belongsTo('supplier'),
    cartonSetBy: _emberData.default.belongsTo('user'),
    shippingMethod: _emberData.default.belongsTo('shipping-method'),
    cartonChangedAt: _emberData.default.attr('date'),
    keyItem: _emberData.default.attr('boolean'),
    keyedBy: _emberData.default.belongsTo('user'),
    KeyedAt: _emberData.default.attr('date'),
    kitSort: ['position', 'id'],
    sortedKits: computed.sort('kits', 'kitSort'),
    kits: _emberData.default.hasMany('kit', { inverse: 'item' }),
    kitItems: _emberData.default.hasMany('kit', { inverse: 'kitItem' }),
    itemAdjustments: _emberData.default.hasMany('item-adjustment'),
    imageSort: ['position', 'id'],
    sortedImages: computed.sort('itemImages', 'imageSort'),
    itemImages: _emberData.default.hasMany('item-image'),
    categoryItems: _emberData.default.hasMany('category-item'),
    itemPropertyValues: _emberData.default.hasMany('item-property-value'),
    orderItems: _emberData.default.hasMany('order-item'),
    shopifyVariants: _emberData.default.hasMany('shopify-variant'),
    collectionItems: _emberData.default.hasMany('collection-item'),
    collections: computed.mapBy('collectionItems', 'collection'),
    purchaseOrderItems: _emberData.default.hasMany('purchase-order-item'),
    poItems: computed.filterBy('purchaseOrderItems', 'purchaseOrder.poType', 'purchase'),
    feedItems: _emberData.default.hasMany('feed-item'),
    listingsCheckedAt: _emberData.default.attr('date'),
    listingsCheckedBy: _emberData.default.belongsTo('user'),
    amazonListings: _emberData.default.hasMany('amazon-listing'),
    fbaListings: _emberData.default.hasMany('fba-listing', { inverse: 'item' }),
    fslListings: _emberData.default.hasMany('fsl-listing', { inverse: 'item' }),
    fbmListings: _emberData.default.hasMany('fbm-listing', { inverse: 'item' }),
    ebayOffers: _emberData.default.hasMany('ebay-offer', { inverse: 'item' }),
    allListings: computed('fba-listings', 'fsl-listings', 'fbm-listings', function () {
      var listings = [];
      this.get('fbaListings').each(function (listing) {
        listings.pushObject(listing);
      });
      return listings;
      return [].concat(this.get('fbaListings'), this.get('fslListings'), this.get('fbmListings'));
    }),
    productType: _emberData.default.belongsTo('product-type'),
    carton: _emberData.default.belongsTo('carton'),
    //product: DS.belongsTo('product'),
    //promotion: DS.belongsTo('promotion')
    kitPicks: _emberData.default.attr('number', { readOnly: true }),
    quantityAvailable: computed('quantity', 'quantityCommitted', function () {
      return this.get('quantity') - this.get('quantityCommitted');
    }),
    sLength: computed('length', 'shelfLength', function () {
      return this.get('shelfLength') || this.get('length');
    }),
    sWidth: computed('width', 'shelfWidth', function () {
      return this.get('shelfWidth') || this.get('width');
    }),
    sHeight: computed('height', 'shelfHeight', function () {
      return this.get('shelfHeight') || this.get('height');
    }),
    sNest: computed('nest', 'shelfNest', function () {
      return this.get('shelfNest') || this.get('nest');
    }),
    sDims: computed('sLength', 'sWidth', 'sHeight', function () {
      return [this.get('sLength'), this.get('sWidth'), this.get('sHeight')].sort(function (a, b) {
        return a - b;
      });
    }),
    sMaxDim: computed('sDims', function () {
      return this.get('sDims')[2];
    }),
    sMidDim: computed('sDims', function () {
      return this.get('sDims')[1];
    }),
    sMinDim: computed('sDims', function () {
      return this.get('sDims')[0];
    }),
    dims: computed('length', 'width', 'height', function () {
      return [this.get('length'), this.get('width'), this.get('height')].sort(function (a, b) {
        return a - b;
      });
    }),
    maxDim: computed('dims', function () {
      return this.get('dims')[2];
    }),
    midDim: computed('dims', function () {
      return this.get('dims')[1];
    }),
    minDim: computed('dims', function () {
      return this.get('dims')[0];
    }),
    azUpcSearchUrl: computed('upc', function () {
      return 'https://www.amazon.com/s?k=' + encodeURI(this.get('upc')) + '&id=' + this.get('id') + '&sku=' + this.get('sku') + '&t=' + encodeURI(this.get('name')) + '&ref=nb_sb_noss';
    }),
    hasUpc: computed('upc', function () {
      return this.get('upc') && this.get('upc.length') > 11;
    }),
    azNameSearchUrl: computed('name', 'azSearchPhrase', function () {
      return 'https://www.amazon.com/s?k=' + encodeURI(this.get('azSearchPhrase') || this.get('name').toLowerCase()) + '&id=' + this.get('id') + '&sku=' + this.get('sku') + '&t=' + encodeURI(this.get('name')) + '&ref=nb_sb_noss';
    }),
    fslEligible: computed('dims', 'weight', function () {
      return this.get('maxDim') && this.get('midDim') && this.get('minDim') && this.get('weight') && this.get('maxDim') <= 16 && this.get('midDim') <= 9 && this.get('minDim') <= 4 && this.get('weight') <= 15;
    }),
    volume: computed('length', 'width', 'height', function () {
      return this.get('length') * this.get('width') * this.get('height');
    }),
    changed: computed('hasDirtyAttributes', 'amazonListings.@each.hasDirtyAttributes', 'shopifyVariants.@each.hasDirtyAttributes', 'kits.@each.hasDirtyAttributes', 'categoryItems.@each.hasDirtyAttributes', 'collectionItems.@each.hasDirtyAttributes', function () {
      return this.get('hasDirtyAttributes') || this.get('amazonListings').filterBy('hasDirtyAttributes', true).length > 0 || this.get('shopifyVariants').filterBy('hasDirtyAttributes', true).length > 0 || this.get('kits').filterBy('hasDirtyAttributes', true).length > 0 || this.get('categoryItems').filterBy('hasDirtyAttributes', true).length > 0 || this.get('collectionItems').filterBy('hasDirtyAttributes', true).length > 0;
    }),
    buttonText: computed('changed', function () {
      console.log('button text');
      if (this.get('changed')) {
        return 'Save Item';
      } else {
        return 'Item Saved';
      }
    })

  });
});