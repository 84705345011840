define('core/controllers/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  var service = Ember.inject.service;
  var computed = Ember.computed;
  exports.default = Controller.extend({
    currentUser: service(),
    android: computed(function () {
      return navigator.userAgent.match(/Android/i);
    })
  });
});