define('core/models/ticket-history', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = _emberData.default.Model.extend({
    emailFrom: _emberData.default.attr('string'),
    emailTo: _emberData.default.attr('string'),
    phone: _emberData.default.attr('string'),
    subject: _emberData.default.attr('string'),
    body: _emberData.default.attr('string'),
    bodyText: computed('body', function () {
      if (this.get('body')) {
        return this.get('body').replace(/(?:\r\n|\r|\n)/g, '<br>');
      }
    }),
    html: _emberData.default.attr('string'),
    headers: _emberData.default.attr('string'),
    notes: _emberData.default.attr('string'),
    contactType: _emberData.default.attr('number'),
    public: _emberData.default.attr('boolean'),
    assignedAt: _emberData.default.attr('date'),
    repliedAt: _emberData.default.attr('date'),
    startedAt: _emberData.default.attr('date'),
    finishedAt: _emberData.default.attr('date'),
    phoneCallId: _emberData.default.attr('string'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    ticket: _emberData.default.belongsTo('ticket'),
    user: _emberData.default.belongsTo('user'),
    sender: computed('emailFrom', 'ticket.email', 'user.id', 'user.shortName', function () {
      if (this.get('emailFrom') === this.get('ticket.email')) {
        return this.get('ticket.name');
      } else {
        return this.get('user.shortName');
      }
    }),
    baseHtml: computed('html', function () {
      return btoa(this.get('html'));
    }),
    baseUrl: computed('html', function () {
      return urlFromBase64({ data: this.get('baseHtml'), mimeType: 'text/html' });
    })
  });
});