define('core/services/storefront', ['exports', 'ember-data-storefront/services/storefront'], function (exports, _storefront) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _storefront.default;
    }
  });
});