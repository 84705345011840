define('core/models/fsl-listing', ['exports', 'ember-data', 'core/models/amazon-listing'], function (exports, _emberData, _amazonListing) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = _amazonListing.default.extend({
    fbaFulfillmentCost: computed('bestLength', 'bestWidth', 'bestHeight', 'bestWeight', function () {
      var cost = 0.75;
      if (this.get('price') < 5) {
        cost += 0.8;
      } else {
        cost += 1;
      }

      cost += 0.11 * Math.ceil(0.7 + this.get('bestWeight'));
      return Math.round(cost * 100) / 100;
    })
  });
});