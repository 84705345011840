define('core/components/project-stocking', ['exports', 'ember-concurrency'], function (exports, _emberConcurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var service = Ember.inject.service;
  var computed = Ember.computed;
  exports.default = Component.extend({
    speech: service(),
    store: service(),
    router: service(),
    item: computed.alias('record.model.item'),
    model: computed.alias('record.model'),
    actions: {
      focusSearch: function focusSearch() {},
      continue: function _continue() {
        var _this = this;
        this.set('model.completedAt', new Date());
        this.get('model').then(function (tote) {
          tote.save();
          _this.get('router').transitionTo('index');
        });
      }
    }
  });
});