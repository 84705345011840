define('core/models/shopify-product', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    shopifyId: _emberData.default.attr('number'),
    handle: _emberData.default.attr('string'),
    option1: _emberData.default.attr('string'),
    option2: _emberData.default.attr('string'),
    option3: _emberData.default.attr('string'),
    productType: _emberData.default.attr('string'),
    publishedScope: _emberData.default.attr('string'),
    templateSuffix: _emberData.default.attr('string'),
    title: _emberData.default.attr('string'),
    vendor: _emberData.default.attr('string'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    //product: DS.belongsTo('product'),
    storeId: _emberData.default.attr('number'),
    variants: _emberData.default.hasMany('shopify-variant')
  });
});