define('core/components/keypad-input', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    didInsertElement: function didInsertElement() {
      this.send('showKeypad');
    },
    actions: {
      showKeypad: function showKeypad() {
        console.log(window.innerWidth);
        if (navigator.userAgent.match(/Android/i) && !this.get('keypadVisible')) {
          this.set('keypadVisible', true);
          var _this = this;
          setTimeout(function () {
            if (_this.$('.keypad-value')) {
              _this.$('.keypad-value').focus();
            }
          }, 10);
        }
      },
      hideKeypad: function hideKeypad() {
        this.set('keypadVisible', false);
      },
      keypadDigit: function keypadDigit(input) {

        var newValue = (input + '') * 1;
        var currentValue = this.get('value');

        if (currentValue && currentValue !== '0' && currentValue !== '') {
          newValue = ('' + currentValue + input) * 1;
        }

        if (this.get('max')) {
          newValue = '' + Math.min(newValue, this.get('max'));
        }

        this.set('value', newValue);
      },

      keypadBack: function keypadBack() {
        var currentValue = '' + this.get('value');
        this.set('value', currentValue.slice(0, -1));
      },
      keypadClear: function keypadClear() {
        this.set('value', null);
      },
      clearModal: function clearModal() {
        this.set('keypadVisible', false);
      },
      keypadCancel: function keypadCancel() {
        this.set('keypadVisible', false);
        if (this.get('cancelAction')) {
          this.sendAction('cancelAction');
        }
      },
      keypadSubmit: function keypadSubmit() {
        this.sendAction('submitAction');
        this.set('keypadVisible', false);
      }
    }
  });
});