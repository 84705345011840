define('core/models/collection', ['exports', 'ember-data', 'ember-api-actions'], function (exports, _emberData, _emberApiActions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    published: _emberData.default.attr('boolean'),
    publishedAt: _emberData.default.attr('date'),
    publishedScope: _emberData.default.attr('string'),
    sortOrder: _emberData.default.attr('string'),
    templateSuffix: _emberData.default.attr('string'),
    bodyHtml: _emberData.default.attr('string'),
    metadata: _emberData.default.attr('string', { readOnly: true }),
    handle: _emberData.default.attr('string'),
    image: _emberData.default.attr('string'),
    position: _emberData.default.attr('number'),
    primaryColor: _emberData.default.attr('string'),
    secondaryColor: _emberData.default.attr('string'),
    usnId: _emberData.default.attr('number'),
    ziggosId: _emberData.default.attr('number'),
    page: _emberData.default.belongsTo('page'),
    collectionItems: _emberData.default.hasMany('collection-item'),
    sort: ['position'],
    sortedItems: computed.sort('collectionItems', 'sort'),
    items: _emberData.default.hasMany('item'),
    usn: computed('usnId', function () {
      return !!this.get('usnId');
    }),
    ziggos: computed('usnId', function () {
      return !!this.get('ziggosId');
    }),
    publishUsn: _emberData.default.attr('boolean'),
    publishZiggos: _emberData.default.attr('boolean'),
    sync: (0, _emberApiActions.memberAction)({ path: 'sync', type: 'put' })

    //channels: DS.hasMany('channel'),
    //stores: DS.hasMany('store')
  });
});