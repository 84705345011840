define('core/models/ebay-promotion', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = _emberData.default.Model.extend({
    promotionId: _emberData.default.attr('string'),
    name: _emberData.default.attr('string'),
    promoType: _emberData.default.attr('string'),
    status: _emberData.default.attr('string'),
    singleItemOnly: _emberData.default.attr('boolean'),
    startsAt: _emberData.default.attr('date'),
    endsAt: _emberData.default.attr('date'),
    position: _emberData.default.attr('number'),
    discount_1: _emberData.default.attr('number'),
    discount_2: _emberData.default.attr('number'),
    discount_3: _emberData.default.attr('number'),
    syncedAt: _emberData.default.attr('date'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    sync: _emberData.default.attr('boolean'),
    endPromo: _emberData.default.attr('boolean'),
    ebayOffers: _emberData.default.hasMany('ebay-offer'),
    active: computed('status', function () {
      return !!this.get('status');
    })
  });
});