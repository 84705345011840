define('core/models/picking-bin', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = _emberData.default.Model.extend({
    location: _emberData.default.attr('string'),
    length: _emberData.default.attr('number'),
    width: _emberData.default.attr('number'),
    height: _emberData.default.attr('number'),
    maxDim: _emberData.default.attr('number'),
    midDim: _emberData.default.attr('number'),
    minDim: _emberData.default.attr('number'),
    volume: _emberData.default.attr('number'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    // status: DS.attr('string',{readOnly: true}),
    shipment: _emberData.default.belongsTo('shipment'),
    pickingTote: _emberData.default.belongsTo('picking-tote'),
    projectRecordId: _emberData.default.attr('number'),
    cssWidth: computed('width', function () {
      return Math.ceil(this.get('width'));
    })
  });
});