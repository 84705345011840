define('core/models/amazon-inventory', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    sku: _emberData.default.attr('string'),
    quantity: _emberData.default.attr('number'),
    switchFulfillmentTo: _emberData.default.attr('string'),
    fulfillmentLatency: _emberData.default.attr('number'),
    availableAt: _emberData.default.attr('date'),
    restockAt: _emberData.default.attr('date'),
    status: _emberData.default.attr('string'),
    resultCode: _emberData.default.attr('string'),
    resultMessageCode: _emberData.default.attr('string'),
    resultDescription: _emberData.default.attr('string'),
    feedMessageId: _emberData.default.attr('number'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    amazonRequest: _emberData.default.belongsTo('amazon-request'),
    amazonListing: _emberData.default.belongsTo('amazon-listing')
  });
});