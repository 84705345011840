define('core/components/shopify-variant', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  exports.default = Component.extend({
    sortOrder: ['storeId', 'sku', 'product.handle.length', 'product.handle'],
    sortedVariants: computed.sort('variants', 'sortOrder'),
    actions: {
      destroyVariant: function destroyVariant(variant) {
        variant.destroyRecord();
      }
    }
  });
});