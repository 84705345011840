define('core/models/ticket', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    email: _emberData.default.attr('string'),
    phone: _emberData.default.attr('string'),
    subject: _emberData.default.attr('string'),
    priority: _emberData.default.attr('number'),
    accessToken: _emberData.default.attr('string'),
    contactType: _emberData.default.attr('string'),
    status: _emberData.default.attr('string'),
    completedAt: _emberData.default.attr('date'),
    deletedAt: _emberData.default.attr('date'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    order: _emberData.default.belongsTo('order'),
    user: _emberData.default.belongsTo('user'),
    customer: _emberData.default.belongsTo('customer'),
    department: _emberData.default.belongsTo('department'),
    storeId: _emberData.default.attr('number'),
    ticketCategory: _emberData.default.belongsTo('ticket-category'),
    ticketHistories: _emberData.default.hasMany('ticket-history'),
    ticketSort: ['createdAt'],
    sortedTicketHistories: computed.sort('ticketHistories', 'ticketSort'),
    firstReply: computed('sortedTicketHistories', function () {
      return this.get('sortedTicketHistories.firstObject');
    }),
    lastReply: computed('sortedTicketHistories', function () {
      if (this.get('ticketHistories.length') > 1) {
        return this.get('sortedTicketHistories.lastObject');
      }
    }),
    middleReplies: computed('sortedTicketHistories', function () {
      if (this.get('ticketHistories.length') > 2) {
        return this.get('sortedTicketHistories').slice(1, -1);
      }
    })

  });
});