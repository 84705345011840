define('core/models/ebay-policy', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    policyType: _emberData.default.attr('string'),
    policyId: _emberData.default.attr('string'),
    name: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date')
  });
});