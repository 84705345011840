define('core/components/card-channel', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var service = Ember.inject.service;
  exports.default = Component.extend({
    cable: service(),
    store: service(),
    sortOrder: ['placedAt:desc'],
    ajax: service(),
    didInsertElement: function didInsertElement() {
      var _this = this;

      var consumer = _this.get('consumer') || _this.get('cable').createConsumer((window.location.protocol + '//' + 'core.usnovelty.com').replace('http', 'ws') + '/websocket');
      consumer.subscriptions.create("CardChannel", {
        connected: function connected() {
          console.log('card connected');
        },
        received: function received(payload) {
          console.log(payload);
          if (payload.swipedCard && _this.get('checkout')) {
            _this.get('swipes').pushObject(payload.swipedCard);
            setTimeout(function () {
              _this.get('swipes').removeObject(payload.swipedCard);
            }, 20000);
          }
        },
        disconnected: function disconnected() {
          console.log("CardChannel#disconnected");
        }
      });

      // Passing Parameters to Channel
      var subscription = consumer.subscriptions.create({ channel: 'CardChannel', room: 'Best Room' }, {
        received: function received(data) {
          //athis.updateRecord(data);
        }
      });

      // Using mixin and inject your services
      var receiver = function receiver(data) {
        if (data.model && data.id && data.action == 'reload') {
          console.log('reload');
          _this.get('store').findRecord(data.model, data.id);
        }
      };

      consumer.subscriptions.create({ channel: 'CardChannel' }, receiver);

      // Send actions to your Action Cable channel class
      subscription.perform("your_channel_action", { hey: "hello" });
    }
  });
});