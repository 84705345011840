define('core/models/page', ['exports', 'ember-data', 'ember-api-actions'], function (exports, _emberData, _emberApiActions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    handle: _emberData.default.attr('string'),
    title: _emberData.default.attr('string'),
    templateSuffix: _emberData.default.attr('string'),
    bodyHtml: _emberData.default.attr('string'),
    publishedAt: _emberData.default.attr('date'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    primaryColor: _emberData.default.attr('string'),
    secondaryColor: _emberData.default.attr('string'),
    altPrimaryColor: _emberData.default.attr('string'),
    altSecondaryColor: _emberData.default.attr('string'),
    parent: _emberData.default.belongsTo('page', { inverse: 'children' }),
    pageBlocks: _emberData.default.hasMany('page-block'),
    children: _emberData.default.hasMany('page'),
    collections: _emberData.default.hasMany('collection'),
    sync: (0, _emberApiActions.memberAction)({ path: 'sync', type: 'put' }),
    publishUsn: _emberData.default.attr('boolean'),
    publishZiggos: _emberData.default.attr('boolean')

  });
});