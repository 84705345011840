define('core/models/receiving-tote', ['exports', 'ember-data', 'core/models/current', 'ember-api-actions'], function (exports, _emberData, _current, _emberApiActions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = _current.default.extend({
    code: _emberData.default.attr('string'),
    filledAt: _emberData.default.attr('date'),
    transferredAt: _emberData.default.attr('date'),
    completedAt: _emberData.default.attr('date'),
    stockingAt: _emberData.default.attr('date'),
    target: _emberData.default.attr('number'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    //projectRecords: DS.hasMany('project-record'),
    user: _emberData.default.belongsTo('user'),
    receivingBin: _emberData.default.belongsTo('receiving-bin'),
    ReceivingBinId: _emberData.default.attr('number'),
    tote: _emberData.default.belongsTo('tote', { inverse: 'current' }),
    toteId: _emberData.default.attr('number'),
    projectRecordId: _emberData.default.attr('number'),
    inboundShipment: _emberData.default.belongsTo('inbound-shipment'),
    receivedItems: _emberData.default.hasMany('received-item'),
    preProcess: (0, _emberApiActions.memberAction)({ path: 'pre_process', type: 'put' }),
    remainingItems: computed('receivedItems.@each.quantity', 'receivedItems.@each.quantityStocked', function () {
      return this.get('receivedItems').filter(function (item) {
        return item.get('quantityRemaining') > 0;
      });
    }),
    remainingSorted: computed.sort('remainingItems', 'remainingSort'),
    remainingSort: ['destination.stockable.sku', 'destination.stockable.inboundShipment.stagingLocation'],
    remainingPrep: computed('receivedItems.@each.prepperId', function () {
      return this.get('receivedItems').filter(function (item) {
        return !item.get('prepperId');
      });
    }),
    destinations: computed.mapBy('receivedItems', 'destination')
  });
});