define('core/components/pending-orders-overview', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var service = Ember.inject.service;
  var computed = Ember.computed;
  exports.default = Component.extend({
    cable: service(),
    store: service(),
    ajax: service(),
    currentUser: service(),
    sortOrder: ['placedAt:desc'],
    orders: computed.sort('model', 'sortOrder'),
    didInsertElement: function didInsertElement() {
      var _this = this;

      this.get('ajax').request('/api/pending_orders/overview?data=overview').then(function (data) {
        if (data.overview) {
          _this.set('overview', data.overview);
        }
      });

      setTimeout(function () {

        var consumer = _this.get('consumer') || _this.get('cable').createConsumer((window.location.protocol + '//' + window.location.hostname).replace('http', 'ws').replace('dv', 'core') + '/websocket?jwt=' + _this.get('currentUser.user.jwt'));

        consumer.subscriptions.create("PendingOrderChannel", {
          connected: function connected() {
            console.log('pending-order connected');
          },
          received: function received(data) {
            if (data.model && data.id && data.action == 'reload') {
              var record = _this.get('store').peekRecord(data.model, data.id);
              if (!record) {
                var _record = _this.get('store').findRecord(data.model, data.id);
                _this.get('model').addObject(_record);
              }
            } else if (data.format === 'json' && data.json) {
              var json = JSON.parse(data.json);
              var _record2 = _this.get('store').pushPayload(json);
              if (_record2) {
                _this.get('model').addObject(_record2);
              }
            } else if (data.overview) {
              _this.set('overview', data.overview);
            }
          },
          disconnected: function disconnected() {}
        });

        // Passing Parameters to Channel
        // const subscription = consumer.subscriptions.create({ channel: 'PendingOrderChannel'}, {
        //   received: (data) => {
        //     //athis.updateRecord(data);
        //   }
        // });

        // // Using mixin and inject your services
        // var receiver = function(data){
        //   if (data.model && data.id && data.action == 'reload') {
        //     _this.get('store').findRecord(data.model,data.id);
        //   }
        // };

        // consumer.subscriptions.create({ channel: 'PendingOrderChannel' }, receiver);

        // // Send actions to your Action Cable channel class
        // subscription.perform("your_channel_action", { hey: "hello" });
      }, 5000);

      // Save consumer to controller to link up computed props
      // this.set('consumer', consumer);
    }
  });
});