define('core/models/current', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  var camelize = Ember.String.camelize;
  exports.default = _emberData.default.Model.extend({
    toteType: computed('modelName', function () {
      return camelize(this.get('modelName'));
    }),
    modelName: computed(function () {
      console.log('model');
      return this.get('constructor.modelName');
    }),
    editComponent: computed('modelName', function () {
      return 'edit-' + this.get('modelName');
    })
  });
});