define('core/models/picking-tote', ['exports', 'ember-data', 'core/models/current', 'ember-api-actions'], function (exports, _emberData, _current, _emberApiActions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _Current$extend;

  function _defineProperty(obj, key, value) {
    if (key in obj) {
      Object.defineProperty(obj, key, {
        value: value,
        enumerable: true,
        configurable: true,
        writable: true
      });
    } else {
      obj[key] = value;
    }

    return obj;
  }

  var computed = Ember.computed;
  exports.default = _current.default.extend((_Current$extend = {
    sortingAt: _emberData.default.attr('date'),
    shippingAt: _emberData.default.attr('date'),
    target: _emberData.default.attr('number'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    projectRecords: _emberData.default.hasMany('project-record'),
    user: _emberData.default.belongsTo('user')
  }, _defineProperty(_Current$extend, 'target', _emberData.default.attr('string')), _defineProperty(_Current$extend, 'orderItems', _emberData.default.hasMany('order-item', { async: true })), _defineProperty(_Current$extend, 'pickingBin', _emberData.default.belongsTo('picking-bin')), _defineProperty(_Current$extend, 'tote', _emberData.default.belongsTo('tote', { inverse: 'pickingTote' })), _defineProperty(_Current$extend, 'toteId', _emberData.default.attr('number')), _defineProperty(_Current$extend, 'shipments', _emberData.default.hasMany('shipment')), _defineProperty(_Current$extend, 'printInvoices', (0, _emberApiActions.memberAction)({ path: 'print_invoice', type: 'put' })), _defineProperty(_Current$extend, 'shippedItems', computed.filterBy('orderItems', 'shipped', true)), _defineProperty(_Current$extend, 'unshippedItems', computed.filterBy('orderItems', 'shipped', false)), _defineProperty(_Current$extend, 'pickedItems', _emberData.default.hasMany('picked-item')), _defineProperty(_Current$extend, 'pickedOrderItems', computed.mapBy('pickedItems', 'orderItem')), _defineProperty(_Current$extend, 'purchaseOrderItems', computed.mapBy('pickedItems', 'purchaseOrderItem')), _defineProperty(_Current$extend, 'remainingItems', computed.filterBy('pickedItems', 'toted', false)), _defineProperty(_Current$extend, 'shippedOrderItems', computed.filterBy('pickedOrderItems', 'shipped', true)), _defineProperty(_Current$extend, 'unshippedOrderItems', computed.filterBy('pickedOrderItems', 'shipped', false)), _defineProperty(_Current$extend, 'scannableTote', computed('tote.id', function () {
    return 'TOTE' + this.get('tote.id');
  })), _defineProperty(_Current$extend, 'poiDestinations', computed.mapBy('purchaseOrderItems', 'destinations')), _defineProperty(_Current$extend, 'destinations', computed('poiDestinations', function () {

    var dests = [];
    if (this.get('poiDestinations.length') > 0) {
      this.get('poiDestinations').forEach(function (list) {
        list.forEach(function (dest) {
          if (dest.get('scannable')) {
            dests.push(dest);
          }
        });
      });
    }
    return dests;
  })), _Current$extend));
});