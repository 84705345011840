define('core/models/supplier', ['exports', 'ember-data', 'ember-api-actions'], function (exports, _emberData, _emberApiActions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    code: _emberData.default.attr('string'),
    vendorCode: _emberData.default.attr('string'),
    contact: _emberData.default.attr('string'),
    account: _emberData.default.attr('string'),
    address1: _emberData.default.attr('string'),
    address2: _emberData.default.attr('string'),
    city: _emberData.default.attr('string'),
    state: _emberData.default.attr('string'),
    zip: _emberData.default.attr('string'),
    country: _emberData.default.attr('string'),
    email: _emberData.default.attr('string'),
    phone: _emberData.default.attr('string'),
    fax: _emberData.default.attr('string'),
    website: _emberData.default.attr('string'),
    taxNumber: _emberData.default.attr('string'),
    terms: _emberData.default.attr('string'),
    poComments: _emberData.default.attr('string'),
    senderClass: _emberData.default.attr('string'),
    notes: _emberData.default.attr('string'),
    daysOut: _emberData.default.attr('number'),
    minimumOrder: _emberData.default.attr('number'),
    backorderPolicy: _emberData.default.attr('number'),
    carries: _emberData.default.attr('number'),
    useMpq: _emberData.default.attr('boolean'),
    purchaseOrders: _emberData.default.hasMany('purchase-order'),
    feeds: _emberData.default.hasMany('feed'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    marketplaceRestriction: _emberData.default.attr('string'),
    items: _emberData.default.hasMany('item'),
    poPreview: (0, _emberApiActions.memberAction)({ path: 'po_preview', type: 'get' }),
    reorderPo: (0, _emberApiActions.memberAction)({ path: 'reorder_po', type: 'put' }),
    csz: computed('city', 'state', 'zip', function () {
      return this.get('city') + ' ' + this.get('state') + ', ' + this.get('zip');
    }),
    addressBlock: computed('name', 'address1', 'address2', 'csz', function () {
      var address = [];
      address.push(this.get('name'));
      address.push(this.get('address1'));
      if (this.get('address2')) {
        address.push(this.get('address2'));
      }
      address.push(this.get('csz'));
      return address.join("\n");
    })

  });
});