define('core/components/amazon-feed', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    didInsertElement: function didInsertElement() {
      this.get('feed').reload();
    }
  });
});