define('core/router', ['exports', 'core/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var EmberRouter = Ember.Router;


  var Router = EmberRouter.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });

  Router.map(function () {
    this.route('login');
    this.route('items', function () {
      this.route('manage');
      this.route('new');
      this.route('edit', { path: '/:item_id' });
      this.route('query');
      this.route('kits');
      this.route('restock');
      this.route('amazon-restocks');
      this.route('relocate');
      this.route('inventory');
    });
    this.route('suppliers', function () {
      this.route('edit', { path: '/:supplier_id' });
    });
    this.route('customers', function () {
      this.route('new');
      this.route('edit', { path: '/:customer_id' });
    });
    this.route('orders', function () {
      this.route('new', { path: '/new' });
      this.route('edit', { path: '/:order_id' });
      this.route('overview');
    });
    this.route('order-items');
    this.route('shipments', function () {
      this.route('shipping');
    });
    this.route('amazon-listings', function () {
      this.route('edit', { path: '/:amazon_listing_id' });
      this.route('query');
      this.route('stock-report');
      this.route('loading');
    });
    this.route('inbound-shipments', function () {
      this.route('edit', { path: '/:inbound_shipment_id/edit' });
    });
    this.route('inbound-shipment-items');
    this.route('amazon-notifications', function () {
      this.route('edit', { path: '/:amazon_notification_id/edit' });
    });
    this.route('amazon-notification-offers');
    this.route('amazon-sellers');
    this.route('pending-orders');
    this.route('pending-order-items');
    this.route('amazon-requests');
    this.route('carton-dimensions');

    this.route('purchase-orders', function () {
      this.route('index');
      this.route('index', { path: '/manage' });
      this.route('query');
      this.route('edit', { path: '/:purchase_order_id/edit' });
      this.route('scan', { path: '/:purchase_order_id/scan' });
      this.route('edit', { path: '/:purchase_order_id/show' });
    });

    this.route('purchase-order-items', function () {
      this.route('query');
    });
    this.route('totes', function () {
      this.route('edit', { path: '/:tote_id' });
      this.route('work');
      this.route('clear');
    });
    this.route('picking-totes');
    this.route('receiving-totes', function () {
      this.route('edit', { path: '/:receiving_tote_id' });
    });
    this.route('users', function () {
      this.route('profile', { path: '/profile' });
      this.route('edit', { path: '/:user_id' });
      this.route('manage');
    });
    this.route('receiving-bins');
    this.route('projects', function () {
      this.route('work');
      this.route('edit', { path: '/:project_id' });
      this.route('manage');
    });
    this.route('project-batches', function () {
      this.route('edit', { path: '/:project_batch_id' });
      this.route('manage');
    });
    this.route('project-records');
    this.route('picked-items');
    this.route('pages', function () {
      this.route('edit', { path: '/:page_id' });
      this.route('manage');
    });
    this.route('collections', function () {
      this.route('manage');
      this.route('edit', { path: '/:collection_id' });
    });
    this.route('collection-items');
    this.route('destinations');
    this.route('page-blocks');
    this.route('received-items');
    this.route('bins', function () {
      this.route('edit', { path: '/:bin_id' });

      this.route('manage');
    });
    this.route('shopify-products');
    this.route('shopify-variants');
    this.route('project-users');
    this.route('amazon-listing-events');
    this.route('amazon-prices');
    this.route('amazon-products');
    this.route('amazon-inventories');
    this.route('ship-tos');
    this.route('utilities');
    this.route('imports');
    this.route('fulfillment-centers');
    this.route('box-contents');
    this.route('picking-bins');
    this.route('boxes');
    this.route('categories', function () {
      this.route('index');
      this.route('edit', { path: '/:category_id' });
    });
    this.route('route-error', { path: '/*route' });
    this.route('pricing-profiles', function () {
      this.route('index');
      this.route('edit', { path: '/:pricing_profile_id' });
    });
    this.route('price-rules');
    this.route('departments');
    this.route('permissions');
    this.route('roles', function () {
      this.route('edit', { path: '/:role_id' });
    });
    this.route('amazon');
    this.route('ebay');
    this.route('tickets', function () {
      this.route('edit', { path: '/:ticket_id' });
    });
    this.route('item-adjustments');
    this.route('addon', function () {
      this.route('edit', { path: '/:asin' });
    });
    this.route('product-types');
    this.route('picking-racks');
    this.route('reports');
    this.route('kits');
  });

  exports.default = Router;
});