define('core/models/project-user', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = _emberData.default.Model.extend({
    priority: _emberData.default.attr('number'),
    effectivePriority: computed('priority', 'project.priority', function () {
      return this.get('priority') || this.get('project.priority');
    }),
    batchSize: _emberData.default.attr('number'),
    effectivebBatchSize: computed('batchSize', 'project.batchSize', function () {
      return this.get('batchSize') || this.get('project.batchSize');
    }),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    project: _emberData.default.belongsTo('project'),
    user: _emberData.default.belongsTo('user')
  });
});