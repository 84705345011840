define('core/components/query-scopes', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var service = Ember.inject.service;
  exports.default = Component.extend({
    dataOptions: service(),
    actions: {
      addScope: function addScope() {
        this.set('canSaveQuery', false);

        console.log(this.get('scopes'));
        console.log(this);

        console.log(this.get('canSaveQuery'));

        if (!this.get('scopes')) {
          console.log('No Scopes');
          this.set('scopes', []);
          console.log('Scopes created');
          this.get('scopes').addObject({ value: null });
        } else {
          console.log('scopes');
          this.get('scopes').addObject({ value: null });
        }
      },
      removeScope: function removeScope(scope) {
        this.get('scopes').removeObject(scope);
        this.set('canSaveQuery', false);
      },
      pikaDate: function pikaDate(scope, date) {
        this.set('canSaveQuery', false);
        Ember.set(scope, 'value', date);
      }
    }
  });
});