define('core/components/table-pages', ['exports', 'core/mixins/table-common'], function (exports, _tableCommon) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  exports.default = Component.extend(_tableCommon.default, {
    columns: computed(function () {
      return [{
        label: 'Page',
        valuePath: 'title',
        cellComponent: 'lt-edit'
      }, {
        label: 'Handle',
        valuePath: 'handle'
      }];
    })
  });
});