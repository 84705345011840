define('core/components/project-link-items', ['exports', 'ember-concurrency'], function (exports, _emberConcurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  var service = Ember.inject.service;
  exports.default = Component.extend({
    speech: service(),
    store: service(),
    currentUser: service(),
    model: computed.alias('record.model'),
    item: computed.alias('model'),
    upcUrl: computed('item.name', 'record.searchTerm', function () {
      return 'https://www.amazon.com/s?k=' + this.get('item.upc') + '&id=' + this.get('item.id') + '&sku=' + this.get('item.sku') + '&t=' + encodeURI(this.get('item.name')) + '&ref=nb_sb_noss';
    }),
    url: computed('item.name', 'record.searchTerm', function () {
      return 'https://www.amazon.com/s?k=' + encodeURI((this.get('record.searchTerm') || this.get('item.name')).toLowerCase()) + '&id=' + this.get('item.id') + '&sku=' + this.get('item.sku') + '&t=' + encodeURI(this.get('item.name')) + '&ref=nb_sb_noss';
    }),
    actions: {
      nextRecord: function nextRecord() {
        this.set('item.listingsCheckedAt', new Date());
        this.set('item.listingsCheckedBy', this.get('currentUser.user'));
        this.get('item').then(function (item) {
          item.save();
        });
        this.set('record.completedAt', new Date());
        this.get('record').save();
      },
      openUrl: function openUrl() {
        this.set('record.nameSearched', true);
        window.open(this.get('url'), 'amazonSearch');
      },
      openUpcUrl: function openUpcUrl() {
        this.set('record.upcSearched', true);
        if (this.get('item.hasUpc')) {
          window.open(this.get('upcUrl'), 'amazonSearch');
        }
      }

    }
  });
});