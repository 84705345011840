define('core/models/tote', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = _emberData.default.Model.extend({
    code: _emberData.default.attr('string'),
    length: _emberData.default.attr('number'),
    width: _emberData.default.attr('number'),
    height: _emberData.default.attr('number'),
    maxWeight: _emberData.default.attr('number'),
    currentType: _emberData.default.attr('string', { readOnly: true }),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    binType: _emberData.default.attr('string', { readOnly: true }),
    receivingBinId: _emberData.default.attr('number', { readOnly: true }),
    //projectRecords: DS.hasMany('project-record'),
    current: _emberData.default.belongsTo('current', { polymorphic: true }),
    pickingTote: _emberData.default.belongsTo('pickingTote'),
    receivingTote: _emberData.default.belongsTo('receivingTote'),
    currentUse: computed('pickingTote.id', 'receivingTote.id', function () {
      return this.get('pickingTote') || this.get('receivingTote');
    })
  });
});