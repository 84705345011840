define('core/routes/application', ['exports', 'core/mixins/secure-default-route-factory', 'ember-simple-auth/mixins/application-route-mixin'], function (exports, _secureDefaultRouteFactory, _applicationRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  var service = Ember.inject.service;


  Route.reopenClass(_secureDefaultRouteFactory.default);

  exports.default = Route.extend(_applicationRouteMixin.default, {
    currentUser: service(),
    sessionAuthenticationSucceeded: function sessionAuthenticationSucceeded() {
      this.transitionTo(this.controllerFor('application').get('previousRouteName'));
    },
    beforeModel: function beforeModel() {
      return this._loadCurrentUser();
    },

    afterModel: function afterModel() {
      var session = JSON.parse(localStorage.getItem('ember_simple_auth-session'));
      session.authenticated.token = this.get('currentUser.user.jwt');
      if (session) {
        localStorage.setItem('ember_simple_auth-session', JSON.stringify(session));
      }
    },
    _loadCurrentUser: function _loadCurrentUser() {
      return this.get('currentUser').get('loadUser').perform();
    },

    actions: {
      toggleTimeClock: function toggleTimeClock() {
        this.controller.toggleProperty('timeClock');
      },
      getModalItem: function getModalItem(itemId) {
        var _this = this;
        this.get('store').findRecord('item', itemId).then(function (item) {
          setTimeout(function () {
            _this.controller.set('modalItem', item);
          }, 1);
        });
      },
      setModalItem: function setModalItem(item) {
        var _this = this;
        setTimeout(function () {
          _this.controller.set('modalItem', item);
        }, 1);
      },
      clearModalItem: function clearModalItem() {
        this.controller.set('modalItem', null);
      },
      getModalListing: function getModalListing(listingId) {
        var _this = this;

        this.get('store').findRecord('amazon-listing', listingId).then(function (listing) {

          if (listing.get('item.id') && !listing.get('item.sku')) {
            _this.get('store').findRecord('item', listing.get('item.id')).then(function (item) {
              console.log('item loaded');
              item.get('amazonListings').forEach(function (az) {
                console.log(az.get('id'));
                console.log(listing.get('id'));
                console.log(az.get('id') == listing.get('id'));
                _this.send('setModalListing', az);
                setTimeout(function () {
                  _this.controller.set('modalListing', az);
                }, 1);
              });
            });
          } else {
            _this.send('setModalListing', listing);

            setTimeout(function () {
              _this.controller.set('modalListing', listing);
            }, 1);
          }
        });
      },
      setModalListing: function setModalListing(content) {
        var _this = this;
        setTimeout(function () {
          _this.controller.set('modalListing', content.content || content);
        }, 1);
      },
      clearModalListing: function clearModalListing() {
        this.controller.set('modalListing', null);
      },
      setModalCustomer: function setModalCustomer(customer) {
        var _this = this;
        setTimeout(function () {
          _this.controller.set('modalCustomer', customer);
        }, 5);
      },
      clearModalCustomer: function clearModalCustomer() {
        this.controller.set('modalCustomer', null);
      },
      setModalShipment: function setModalShipment(shipment) {
        var _this = this;
        setTimeout(function () {
          _this.controller.set('modalShipment', shipment);
        }, 5);
      },
      clearModalShipment: function clearModalShipment() {
        this.controller.set('modalShipment', null);
      },
      setModalSupplier: function setModalSupplier(supplier) {
        var _this = this;
        setTimeout(function () {
          _this.controller.set('modalSupplier', supplier);
        }, 5);
      },
      clearModalSupplier: function clearModalSupplier() {
        this.controller.set('modalSupplier', null);
      },
      invalidateSession: function invalidateSession() {
        this.get('session').invalidate();
      },
      extendSession: function extendSession(jwt) {
        this.get('session').extend(jwt);
      }

    }
  });
});