define("core/components/full-screen-modal", ["exports", "ember-modal-dialog/components/modal-dialog"], function (exports, _modalDialog) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _modalDialog.default.extend({
    containerClassNames: "full-screen-modal",
    targetAttachment: "none"
  });
});