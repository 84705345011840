define('core/services/ajax', ['exports', 'ember-ajax/services/ajax'], function (exports, _ajax) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var computed = Ember.computed;
  exports.default = _ajax.default.extend({
    session: service(),
    headers: computed('session.session.content.authenticated.token', {
      get: function get() {

        var headers = {};

        headers['Authorization'] = 'Bearer: ' + this.get('session.session.content.authenticated.token');

        return headers;
      }
    })
  });
});