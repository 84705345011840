define('core/models/role', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    assignedPermissions: _emberData.default.hasMany('assigned-permission'),
    permissions: _emberData.default.hasMany('permission')
  });
});