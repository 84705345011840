define('core/models/amazon-notification-offer', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = _emberData.default.Model.extend({
    sellerId: _emberData.default.attr('string'),
    feedbackRating: _emberData.default.attr('number'),
    feedbackCount: _emberData.default.attr('number'),
    listingPrice: _emberData.default.attr('number'),
    shippingPrice: _emberData.default.attr('number'),
    shipState: _emberData.default.attr('string'),
    shipCountry: _emberData.default.attr('string'),
    fba: _emberData.default.attr('boolean'),
    expedited: _emberData.default.attr('boolean'),
    featuredMerchant: _emberData.default.attr('boolean'),
    domestic: _emberData.default.attr('boolean'),
    international: _emberData.default.attr('boolean'),
    buyBox: _emberData.default.attr('boolean'),
    availability: _emberData.default.attr('string'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    //projectRecords: DS.hasMany('project-record'),
    amazonNotification: _emberData.default.belongsTo('amazon-notification'),
    amazonSeller: _emberData.default.belongsTo('amazon-seller'),
    price: computed('listingPrice', 'shippingPrice', function () {
      return Math.round((this.get('listingPrice') + this.get('shippingPrice')) * 100) / 100;
    }),
    sellerType: computed('fba', 'sellerId', function () {
      if (this.get('sellerId') === 'ATVPDKIKX0DER') {
        return 'amazon';
      } else if (this.get('sellerId') === 'AA6MR5993HU8E') {
        return 'ypd';
      } else if (this.get('fba')) {
        return 'fba';
      } else {
        return 'merchant';
      }
    })
  });
});