define('core/components/pending-order-details', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var service = Ember.inject.service;
  exports.default = Component.extend({
    store: service(),
    didInsertElement: function didInsertElement() {
      this.get('store').peekAll('amazon-notification').forEach(function (price) {
        price.get('amazonListing');
      });
    }
  });
});