define('core/components/data-table-menu-selected', ['exports', 'ember-data-table/components/data-table-menu-selected'], function (exports, _dataTableMenuSelected) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _dataTableMenuSelected.default;
    }
  });
});