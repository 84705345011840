define('core/models/item-adjustment', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    quantityCounted: _emberData.default.attr('number'),
    previousQuantity: _emberData.default.attr('number'),
    newQuantity: _emberData.default.attr('number'),
    quantity: _emberData.default.attr('number'),
    adjustmentType: _emberData.default.attr('string'),
    scan: _emberData.default.attr('string'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    item: _emberData.default.belongsTo('item'),
    user: _emberData.default.belongsTo('user'),
    bin: _emberData.default.belongsTo('bin')
  });
});