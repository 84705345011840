define('core/mixins/secure-default-route-factory', ['exports', 'ember-simple-auth/mixins/application-route-mixin', 'ember-simple-auth/mixins/authenticated-route-mixin', 'ember-simple-auth/mixins/unauthenticated-route-mixin', 'core/mixins/open-route-mixin'], function (exports, _applicationRouteMixin, _authenticatedRouteMixin, _unauthenticatedRouteMixin, _openRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Mixin = Ember.Mixin;
  exports.default = Mixin.create({
    create: function create() {
      // Create the route using the normal technique:
      var route = this._super.apply(this, arguments);

      var authenticationRouteMixinApplied = _applicationRouteMixin.default.detect(route) || _authenticatedRouteMixin.default.detect(route) || _unauthenticatedRouteMixin.default.detect(route) || _openRouteMixin.default.detect(route);

      if (!authenticationRouteMixinApplied) {
        // The route was not created with any of the authentication-related route
        // mixins. Modify route so it requires authentication to be accessed:
        _authenticatedRouteMixin.default.apply(route);
      }

      return route;
    }
  });
});