define('core/models/amazon-listing-event', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = _emberData.default.Model.extend({
    eventType: _emberData.default.attr('string'),
    eventDescription: _emberData.default.attr('string'),
    eventDetails: _emberData.default.attr('string'),
    price: _emberData.default.attr('number'),
    quantity: _emberData.default.attr('number'),
    inboundQuantity: _emberData.default.attr('number'),
    restockLevel: _emberData.default.attr('number'),
    reorderPoint: _emberData.default.attr('number'),
    units30Days: _emberData.default.attr('number'),
    units7Days: _emberData.default.attr('number'),
    profit30Days: _emberData.default.attr('number'),
    profit7Days: _emberData.default.attr('number'),
    itemRestockLevel: _emberData.default.attr('number'),
    itemQuantity: _emberData.default.attr('number'),
    itemReorderPoint: _emberData.default.attr('number'),
    itemQuantityOnOrder: _emberData.default.attr('number'),
    startedAt: _emberData.default.attr('date'),
    endedAt: _emberData.default.attr('date'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    amazonListing: _emberData.default.belongsTo('amazon-listing'),
    duration: computed('startedAt', 'endedAt', function () {
      return ((this.get('endedAt') || new Date()) - this.get('startedAt')) / 1000;
    })
  });
});