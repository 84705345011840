define('core/components/item-amazon-tooltip', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  exports.default = Component.extend({
    listings: computed('item.amazonListings', 'type', function () {
      if (this.get('type')) {
        return this.get('item.amazonListings').filterBy('type', this.get('type'));
      }
      return this.get('item.amazonListings');
    })
  });
});