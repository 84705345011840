define('core/models/pricing-profile', ['exports', 'ember-data', 'ember-api-actions'], function (exports, _emberData, _emberApiActions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    defaultRuleSign: _emberData.default.attr('string'),
    defaultRuleBy: _emberData.default.attr('string'),
    defaultRuleAmount: _emberData.default.attr('number'),
    minimumType: _emberData.default.attr('string'),
    minimumAmount: _emberData.default.attr('number'),
    maximumType: _emberData.default.attr('string'),
    maximumAmount: _emberData.default.attr('number'),
    priceRules: _emberData.default.hasMany('price-rule'),
    sortOrder: ['position'],
    sortedRules: computed.sort('priceRules', 'sortOrder'),
    amazonListings: _emberData.default.hasMany('amazon-listing'),
    reprice: (0, _emberApiActions.memberAction)({ path: 'reprice', type: 'get' })

  });
});