define('core/components/braintree-connect-button', ['exports', 'ember-braintree-components/components/braintree-connect-button'], function (exports, _braintreeConnectButton) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _braintreeConnectButton.default;
    }
  });
});