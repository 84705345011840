define('core/routes/purchase-orders/scan', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  exports.default = Route.extend({
    // model: function(params) {
    //   return this.get('store').queryRecord('purchase-order',{lookup: 'scan', id: params.purchase_order_id})
    // }
  });
});