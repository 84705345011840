define('core/components/order-charts', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var service = Ember.inject.service;
  exports.default = Component.extend({
    cable: service(),
    store: service(),
    sortOrder: ['placedAt:desc'],
    ajax: service(),
    didInsertElement: function didInsertElement() {
      var _this = this;
      this.get('ajax').request('/api/orders/overview?data=charts').then(function (data) {
        if (data.charts) {
          data.charts.forEach(function (chart) {
            _this.set(chart.chart, chart.data);
          });
        }
      });
    }
  });
});