define('core/models/picked-item', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = _emberData.default.Model.extend({
    scanIdentifier: _emberData.default.attr('string'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    orderItem: _emberData.default.belongsTo('order-item'),
    projectRecords: _emberData.default.hasMany('project-record'),
    user: _emberData.default.belongsTo('user'),
    pickingTote: _emberData.default.belongsTo('picking-tote'),
    item: _emberData.default.belongsTo('item'),
    receivedItemId: _emberData.default.attr('number'),
    quantity: _emberData.default.attr('number'),
    purchaseOrderItem: _emberData.default.belongsTo('purchase-order-item'),
    contentType: _emberData.default.attr('string'),
    toted: computed('orderItem.placedAt', function () {
      return !!this.get('orderItem.placedAt');
    })
  });
});