define('core/instance-initializers/mixin-storefront', ['exports', 'ember-data-storefront/instance-initializers/mixin-storefront'], function (exports, _mixinStorefront) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _mixinStorefront.default;
    }
  });
  Object.defineProperty(exports, 'initialize', {
    enumerable: true,
    get: function () {
      return _mixinStorefront.initialize;
    }
  });
});