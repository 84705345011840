define('core/models/pending-order-item', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = _emberData.default.Model.extend({
    quantity: _emberData.default.attr('number'),
    price: _emberData.default.attr('number'),
    listingId: _emberData.default.attr('number'),
    marketId: _emberData.default.attr('string'),
    name: _emberData.default.attr('string'),
    profit: _emberData.default.attr('number'),
    cogs: _emberData.default.attr('number'),
    settlementId: _emberData.default.attr('number'),
    estPrice: _emberData.default.attr('number'),
    estProfit: _emberData.default.attr('number'),
    quantityAfter: _emberData.default.attr('number'),
    fbaOffers: _emberData.default.attr('number'),
    merchantOffers: _emberData.default.attr('number'),
    compFbaPrice: _emberData.default.attr('number'),
    compMerchantPrice: _emberData.default.attr('number'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    projectRecords: _emberData.default.hasMany('project-record'),
    pendingOrder: _emberData.default.belongsTo('pending-order'),
    item: _emberData.default.belongsTo('item'),
    amazonListing: _emberData.default.belongsTo('amazon-listing'),
    bestPrice: computed('price', 'estPrice', function () {
      return this.get('price') || this.get('estPrice');
    }),
    bestProfit: computed('profit', 'estProfit', function () {
      return this.get('profit') || this.get('estProfit');
    }),
    extPrice: computed('price', 'estPrice', 'quantity', function () {
      return this.get('bestPrice') * this.get('quantity');
    }),
    commission: computed('price', 'estPrice', 'quantity', function () {
      return Math.round(this.get('bestPrice') * 15, 100) / 100;
    }),
    revenue: computed('price', 'estPrice', 'quantity', function () {
      return this.get('bestPrice') * this.get('quantity');
    }),
    unitFee: computed('quantity', 'amazonListing.fbaFulfillmentCost', function () {
      return this.get('quantity') * this.get('amazonListing.fbaFulfillmentCost');
    })
  });
});