define('core/models/inbound-shipment-item', ['exports', 'ember-data', 'ember-api-actions'], function (exports, _emberData, _emberApiActions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = _emberData.default.Model.extend({
    projectRecordId: _emberData.default.attr('number'),
    afnSku: _emberData.default.attr('string'),
    quantity: _emberData.default.attr('number'),
    quantityShort: _emberData.default.attr('number'),
    boxId: _emberData.default.attr('number'),
    pickerId: _emberData.default.attr('number'),
    prepperId: _emberData.default.attr('number'),
    packerId: _emberData.default.attr('number'),
    pickedAt: _emberData.default.attr('date'),
    quantityReceived: _emberData.default.attr('number'),
    quantityCommitted: _emberData.default.attr('number'),
    quantityCanceled: _emberData.default.attr('number'),
    quantityPicked: _emberData.default.attr('number'),
    receivedAt: _emberData.default.attr('date'),
    checkedInAt: _emberData.default.attr('date'),
    inStockAt: _emberData.default.attr('date'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    projectRecords: _emberData.default.hasMany('project-record'),
    inboundShipment: _emberData.default.belongsTo('inbound-shipment'),
    purchaseOrderItem: _emberData.default.belongsTo('purchase-order-item'),
    amazonListing: _emberData.default.belongsTo('amazon-listing'),
    labelRequired: _emberData.default.attr('boolean', { readOnly: true }),
    labelRequiredAtReceiving: _emberData.default.attr('boolean', { readOnly: true }),
    planLabelRequired: _emberData.default.attr('boolean', { readOnly: true }),
    boxContents: _emberData.default.hasMany('box-content'),
    discrepency: computed('quantity', 'quantityReceived', function () {
      if (this.get('quantityReceived')) {
        return this.get('quantity') - this.get('quantityReceived');
      }
    }),
    keypadValue: computed('quantity', 'quantityPicked', function () {
      return this.get('quantity') - this.get('quantityPicked') * 1;
    }),
    quantityRemaining: computed('quantity', 'quantityPicked', function () {
      return this.get('quantity') - this.get('quantityPicked') * 1;
    }),
    sku: computed.alias('amazonListing.sku'),
    item: computed.alias('amazonListing.item'),
    label: (0, _emberApiActions.memberAction)({ path: 'label', type: 'put' }),
    shrinkwrap: computed('inboundShipment.receivingBin.id', 'amazonListing.prepInfo', function () {
      return ['SHRINKWRAP', 'SHRINKWRAP_SOLD_AS_SET', 'SHRINKWRAP_UPC_REQUIRED', 'PENNANT'].indexOf(this.get('amazonListing.prepInfo')) > -1;
    }),
    prep: computed('inboundShipment.receivingBin.id', 'amazonListing.prepInfo', function () {
      return ['OTHER', 'BUBBLE_WRAP', 'FLAT_FOIL'].indexOf(this.get('amazonListing.prepInfo')) > -1;
    }),
    preppedReceivingBin: computed('purchaseOrderItem.purchaseOrder.stationId', 'inboundShipment.receivingBin', 'shrinkwrap', 'prep', 'inboundShipment.boxDirectly', 'inboundShipment.oversize', function () {
      var _this = this;
      var bins = this.get('store').peekAll('receiving-bin');

      var group = 'standard';
      var station = this.get('inboundShipment.stationId') || this.get('purchaseOrderItem.purchaseOrder.stationId');

      console.log('is oversize');
      if (this.get('inboundShipment.boxDirectly')) {
        group = 'box_directly';
        bins = bins.filter(function (bin) {
          return bin.get('id') === _this.get('inboundShipment.receivingBin.id');
        });
      } else if (this.get('inboundShipment.oversize')) {
        group = 'oversize';
        console.log('Oversize Station');
        console.log(station);
      }

      bins = bins.filter(function (bin) {
        if (group == 'oversize') {
          if (bin.get('id') === 273) {
            console.log(station);
            console.log(bin.get('binType'));
          }
        }
        return bin.get('stationId') === station && bin.get('binType') === group;
      });
      console.log('prepped bins');
      return bins.get('firstObject');
    }),
    receivingBin: computed('inboundShipment.shipmentId', 'inboundShipment.receivingBin.id', 'shrinkwrap', 'prep', 'inboundShipment.boxDirectly', 'inboundShipment.oversize', function () {
      var _this = this;
      var bins = this.get('store').peekAll('receiving-bin');

      var group = 'standard';
      var station = this.get('inboundShipment.stationId') || this.get('purchaseOrderItem.purchaseOrder.stationId');

      if (this.get('shrinkwrap')) {
        group = 'shrinkwrap';
      } else if (this.get('prep')) {
        group = 'prep';
      } else if (this.get('inboundShipment.boxDirectly')) {
        group = 'box_directly';
        bins = bins.filter(function (bin) {
          return bin.get('id') === _this.get('inboundShipment.receivingBin.id');
        });
      } else if (this.get('inboundShipment.oversize')) {
        group = 'oversize';
      }

      console.log(group);
      console.log(station);
      console.log(this.get('inboundShipment.stationId'));
      console.log(this.get('inboundShipment.id'));
      console.log(this.get('id'));
      console.log(this.get('purchaseOrderItem.id'));
      console.log(this.get('purchaseOrderItem.purchaseOrder.stationId'));
      console.log(this.get('purchaseOrderItem.purchaseOrder.id'));

      bins = bins.filter(function (bin) {
        return bin.get('stationId') === station && bin.get('binType') === group;
      });
      return bins.get('firstObject');
    }),
    preppedReceivingTote: computed('preppedReceivingBin.currentTote.id', 'inboundShipment.receivingBin', 'shrinkwrap', 'prep', 'inboundShipment.boxDirectly', 'inboundShipment.oversize', function () {
      console.log('prep tote calculated');
      return this.get('preppedReceivingBin.currentTote');
    }),
    receivingTote: computed('receivingBin.currentTote.id', 'inboundShipment.receivingBin', 'shrinkwrap', 'prep', 'inboundShipment.boxDirectly', 'inboundShipment.oversize', function () {
      return this.get('receivingBin.currentTote');
    }),
    preppedScannableTote: computed('preppedReceivingBin.currentTote.id', 'receivingBin.currentTote.id', 'inboundShipment.receivingBin', 'shrinkwrap', 'prep', 'inboundShipment.boxDirectly', 'inboundShipment.oversize', function () {
      console.log('prep scan calculated');
      return this.get('preppedReceivingTote.code');
    }),
    scannableTote: computed('receivingBin.currentTote.code', 'receivingBin.currentTote.id', 'receivingBin.currentTote.filledAt', 'inboundShipment.receivingBin', 'shrinkwrap', 'prep', 'inboundShipment.boxDirectly', 'inboundShipment.oversize', function () {
      if (this.get('shrinkwrap') || this.get('prep') || !this.get('inboundShipment.boxDirectly')) {
        console.log(this.get('receivingBin'));
        if (!this.get('receivingBin.currentTote.code')) {
          console.log('Current Tote:');
          console.log(this.get('receivingBin.currentTote'));
        }
        if (this.get('receivingBin.currentTote.inboundShipment.id') || this.get('receivingBin.currentTote.code') && this.get('receivingBin.currentTote.code').indexOf('FBA') > -1 || this.get('receivingBin.currentTote.filledAt')) {
          return 'Scan New Tote';
        }
      }
      return this.get('receivingBin.currentTote.code');

      // else if (this.get('inboundShipment.receivingBin.receivingTote.code') && this.get('inboundShipment.receivingBin.receivingTote.code').slice(0,-3) == this.get('inboundShipment.shipmentId')) {
      //   return (this.get('inboundShipment.receivingBin.receivingTote.code'));
      // } else {
      //   return 'Scan Tote';
      // }
    })

  });
});