define('core/controllers/projects/work', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  var service = Ember.inject.service;
  exports.default = Controller.extend({
    router: service(),
    actions: {
      continue: function _continue() {
        this.get('router').transitionTo('index');
      }
    }
  });
});