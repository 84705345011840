define('core/models/bin', ['exports', 'ember-data', 'ember-api-actions'], function (exports, _emberData, _emberApiActions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    location: _emberData.default.attr('string'),
    aisle: _emberData.default.attr('string'),
    side: _emberData.default.attr('number'),
    section: _emberData.default.attr('number'),
    sectionCode: _emberData.default.attr('string'),
    shelf: _emberData.default.attr('number'),
    position: _emberData.default.attr('number'),
    volume: _emberData.default.attr('number'),
    length: _emberData.default.attr('number'),
    width: _emberData.default.attr('number'),
    height: _emberData.default.attr('number'),
    maxDim: _emberData.default.attr('number'),
    midDim: _emberData.default.attr('number'),
    minDim: _emberData.default.attr('number'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    maxDimExceeds: _emberData.default.attr('number'),
    deleteWhenEmpty: _emberData.default.attr('boolean'),
    removeItem: _emberData.default.attr('boolean'),
    disabled: _emberData.default.attr('boolean'),
    minDimUnder: _emberData.default.attr('number'),
    unscannable: _emberData.default.attr('boolean'),
    entryMax: _emberData.default.attr('number'),
    entryMin: _emberData.default.attr('number'),
    minDimExceeds: _emberData.default.attr('number'),
    item: _emberData.default.belongsTo('item'),
    lastItem: _emberData.default.belongsTo('item'),
    binTypeId: _emberData.default.attr('number'),
    receivingBin: _emberData.default.belongsTo('receiving-bin'),
    receivingGroupId: _emberData.default.attr('number'),
    lockItem: _emberData.default.attr('boolean'),
    printLabel: (0, _emberApiActions.memberAction)({ path: 'print', type: 'put' })
  });
});