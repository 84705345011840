define('core/models/user', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = _emberData.default.Model.extend({
    number: _emberData.default.attr('string'),
    name: _emberData.default.attr('string'),
    email: _emberData.default.attr('string'),
    phone: _emberData.default.attr('string'),
    jwt: _emberData.default.attr('string', { readOnly: true }),
    rate: _emberData.default.attr('number'),
    currentSignInIp: _emberData.default.attr('string'),
    lastSignInIp: _emberData.default.attr('string'),
    notificationKey: _emberData.default.attr('string'),
    image: _emberData.default.attr('string'),
    lastLocalActivityAt: _emberData.default.attr('date'),
    hiredOn: _emberData.default.attr('date'),
    terminatedOn: _emberData.default.attr('date'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    permitted: _emberData.default.attr({ readOnly: true }),
    tickets: _emberData.default.hasMany('ticket'),
    ticketHistories: _emberData.default.hasMany('ticket-history'),
    pin: _emberData.default.attr('string'),
    pinConfirmation: _emberData.default.attr('string'),
    password: _emberData.default.attr('string'),
    passwordConfirmation: _emberData.default.attr('string'),
    //projectRecords: DS.hasMany('project-record'),
    shippingStationId: _emberData.default.attr('number'),
    extraHelp: _emberData.default.attr('boolean'),
    projectUsers: _emberData.default.hasMany('project-user'),
    projects: _emberData.default.hasMany('project', { inverse: 'user' }),
    roles: _emberData.default.hasMany('role'),
    permissions: _emberData.default.hasMany('permission'),
    timeclock: _emberData.default.belongsTo('timeclock'),
    recentTimeclocks: _emberData.default.attr({ readOnly: true }),
    shortName: computed('name', function () {
      if (this.get('name')) {
        var names = this.get('name').split(' ');
        return names[0] + ' ' + names[1].slice(0, 1) + '.';
      }
    }),
    firstName: computed('name', function () {
      if (this.get('name')) {
        var names = this.get('name').split(' ');
        return names[0];
      }
    })

  });
});