define('core/models/payment-token', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    token: _emberData.default.attr('string'),
    billingAddress: _emberData.default.attr('string'),
    bin: _emberData.default.attr('string'),
    cardType: _emberData.default.attr('string'),
    nonce: _emberData.default.attr('string'),
    cardholderName: _emberData.default.attr('string'),
    expirationMonth: _emberData.default.attr('string'),
    expirationYear: _emberData.default.attr('string'),
    lastFour: _emberData.default.attr('string'),
    issuingBank: _emberData.default.attr('string'),
    imageUrl: _emberData.default.attr('string'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    customer: _emberData.default.belongsTo('customer'),
    order: _emberData.default.belongsTo('order')
  });
});