define('core/models/detail-page', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = _emberData.default.Model.extend({
    asin: _emberData.default.attr('string'),
    title: _emberData.default.attr('string'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    item: _emberData.default.belongsTo('item'),
    amazonListings: _emberData.default.hasMany('amazon-listing'),
    fbaListings: _emberData.default.hasMany('fba-listing'),
    fslListings: _emberData.default.hasMany('fsl-listing'),
    fbmListings: _emberData.default.hasMany('fbm-listing'),
    listings: computed('fba-listings', 'fsl-listings', 'fbm-listings', function () {
      var listings = [];
      this.get('fbaListings').each(function (listing) {
        listings.pushObject(listing);
      });
      return listings;
    })
  });
});