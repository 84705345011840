define('core/components/punch-timeclock', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var service = Ember.inject.service;
  var computed = Ember.computed;
  exports.default = Component.extend({
    currentUser: service(),
    store: service(),
    ajax: service(),
    didInsertElement: function didInsertElement() {
      this.$('.user-id').focus();
      var _this = this;
      if (this.get('user')) {
        this.get('ajax').request('/api/users/recent_timeclocks').then(function (clock) {
          if (clock.length > 0) {
            _this.set('user.recentTimeClocks', clock);
          }
        });
      }
    },
    user: computed.alias('currentUser.user'),
    clockedIn: computed('user.timeclock.inAt', 'user.timeclock.outAt', function () {
      return this.get('user.timeclock.id') && !this.get('user.timeclock.outAt');
    }),
    actions: {
      clearModal: function clearModal() {
        this.sendAction('clearModal');
      },
      punch: function punch() {
        var _this = this;
        if (this.get('requirePin') && (!this.get('pin') || this.get('pin.length') !== 4)) {
          console.log('focusPin');
          $('input.user-pin').focus();

          return;
        }
        if (this.get('id') && this.get('pin') && this.get('pin.length') === 4 && this.get('id.length') > 0) {
          this.get('ajax').request('api/users/authenticate', { method: 'GET', data: { authenticate: { id: this.get('id'), pin: this.get('pin') } } }).then(function (userData) {
            _this.get('store').pushPayload(userData);
            var user = _this.get('store').peekRecord('user', _this.get('id'));
            if (user) {
              _this.set('user', user);
              _this.set('requirePin', false);
            }
          });
        }
      },
      punchIn: function punchIn() {
        var _this = this;
        this.get('store').createRecord('timeclock', {
          user: this.get('user')
        }).save().then(function (tc) {
          _this.set('user.timeclock', tc);
          _this.send('clearModal');
        });
      },
      punchOut: function punchOut() {
        var _this = this;
        this.set('user.timeclock.clockOut', true);
        this.get('user.timeclock').then(function (tc) {
          tc.save();
          _this.send('clearModal');
        });
      }
    }
  });
});