define('core/controllers/application', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  var service = Ember.inject.service;
  exports.default = Controller.extend({
    session: service(),
    currentUser: service(),
    actions: {
      setModalItem: function setModalItem(item) {
        this.set('modalItem', item);
      },
      clearModalItem: function clearModalItem() {
        this.set('modalItem', null);
      },
      setModalListing: function setModalListing(listing) {
        this.set('modalListing', listing);
      },
      clearModalListing: function clearModalListing() {
        this.set('modalListing', null);
      },
      setModalCustomer: function setModalCustomer(customer) {
        this.set('modalCustomer', customer);
      },
      clearModalCustomer: function clearModalCustomer() {
        this.set('modalCustomer', null);
      },
      setModalShipment: function setModalShipment(shipment) {
        this.set('modalShipment', shipment);
      },
      clearModalShipment: function clearModalShipment() {
        this.set('modalShipment', null);
      },
      setModalSupplier: function setModalSupplier(supplier) {
        this.set('modalSupplier', supplier);
      },
      clearModalSupplier: function clearModalSupplier() {
        this.set('modalSupplier', null);
      },
      closeTimeClock: function closeTimeClock() {
        this.set('timeClock', null);
      }
    }
  });
});