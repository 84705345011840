define('core/models/amazon-seller', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    sellerId: _emberData.default.attr('string'),
    location: _emberData.default.attr('string'),
    listingCount: _emberData.default.attr('number'),
    feedbackCount: _emberData.default.attr('number'),
    rating: _emberData.default.attr('number'),
    lastCheckedAt: _emberData.default.attr('date'),
    willPriceUp: _emberData.default.attr('boolean'),
    willPriceDown: _emberData.default.attr('boolean'),
    usesYoyo: _emberData.default.attr('boolean'),
    reactionRate: _emberData.default.attr('boolean'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    offers: _emberData.default.hasMany('amazon-notification-offer'),
    hasName: computed('name', 'hasDirtyAttributes', function () {
      return this.get('name') && !this.get('hasDirtyAttributes');
    })
    //projectRecords: DS.hasMany('project-record')
  });
});