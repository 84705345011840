define('core/models/recordable', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = _emberData.default.Model.extend({
    name: 'Test',
    model: computed('recordable', function () {
      this.set('projectRecordId', this.get('id'));
      return this;
    }),
    projectRecordId: _emberData.default.attr('number'),
    modelName: computed(function () {
      return this.get('constructor.modelName');
    }),
    route: computed('modelName', function () {
      return this.get('modelName') + 's.process';
    })

  });
});