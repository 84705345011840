define('core/models/amazon-notification', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = _emberData.default.Model.extend({
    uniqueId: _emberData.default.attr('string'),
    asin: _emberData.default.attr('string'),
    merchantOffers: _emberData.default.attr('number'),
    fbaOffers: _emberData.default.attr('number'),
    listPrice: _emberData.default.attr('number'),
    category: _emberData.default.attr('string'),
    rank: _emberData.default.attr('number'),
    processed: _emberData.default.attr('boolean'),
    buyBoxPrice: _emberData.default.attr('number'),
    processedAt: _emberData.default.attr('date'),
    myBox: _emberData.default.attr('boolean'),
    changedAt: _emberData.default.attr('date'),
    job: _emberData.default.attr('string'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    projectRecords: _emberData.default.hasMany('project-record'),
    item: _emberData.default.belongsTo('item'),
    offers: _emberData.default.hasMany('amazon-notification-offer'),
    amazonListing: _emberData.default.belongsTo('amazon-listing'),
    //amazonListings: DS.hasMany('amazon-listing', {inverse: 'lastNotification'}),
    fba: computed('offers.@each.fba', function () {
      return this.get('offers').filterBy('fba', true).sortBy('price');
    }),
    merchant: computed('offers.@each.fba', function () {
      return this.get('offers').filterBy('fba', false).sortBy('price');
    }),
    amazonSellers: computed('offers.@each.amazonSeller', function () {
      return this.get('offers').reduce(function (acc, value) {
        acc.push(value.get('amazonSeller'));
        return acc;
      }, []);
    }),
    pricedOffers: computed('offers', function () {
      return this.get('offers').sortBy('price');
    })

  });
});