define('core/components/simple-table', ['exports', 'core/mixins/table-common'], function (exports, _tableCommon) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  exports.default = Component.extend(_tableCommon.default, {
    columns: computed(function () {
      return [{
        label: 'Created',
        valuePath: 'createdAt',
        cellComponent: 'lt-date',
        cellClassNames: 'date'
      }, {
        label: 'Updated',
        valuePath: 'updatedAt',
        cellComponent: 'lt-date',
        cellClassNames: 'date'
      }, {
        label: 'Last Name',
        valuePath: 'lastName',
        width: '150px'
      }, {
        label: 'Address',
        valuePath: 'address'
      }, {
        label: 'State',
        valuePath: 'state'
      }, {
        label: 'Country',
        valuePath: 'country'
      }];
    })
  });
});