define('core/components/email-form', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  exports.default = Component.extend({
    defaultName: computed(function () {
      return this.get('emailData.name');
    }),
    defaultEmail: computed(function () {
      return this.get('emailData.email');
    }),
    defaultSubject: computed(function () {
      return this.get('emailData.subject');
    }),
    defaultBody: computed(function () {
      return this.get('emailData.body');
    }),
    options: computed(function () {
      return this.get('contactOptions');
    }),
    disableEmail: computed('email', 'defaultEmail', function () {
      return !/(.+)@(.+){2,}\.(.+){2,}/.test(this.get('email') || this.get('defaultEmail'));
    }),
    actions: {
      sendEmail: function sendEmail() {
        var data = { name: this.get('name') || this.get('defaultName'),
          email: this.get('email') || this.get('defaultEmail'),
          subject: this.get('subject') || this.get('defaultSubject'),
          body: this.get('body') || this.get('defaultBody'),
          bcc: this.get('bcc')
        };
        this.get('sendAction')(data);
      },
      open: function open() {
        this.get('openAction')();
      },
      print: function print() {
        this.get('printAction')();
      },
      setContact: function setContact(contact) {
        this.set('selectedContact', contact);
        this.set('name', contact.get('name'));
        this.set('email', contact.get('email'));
      }
    }

  });
});