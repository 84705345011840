define('core/services/mqtt-client', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = Ember.Service;
  var computed = Ember.computed;
  exports.default = Service.extend({
    data: 'mqtt',
    lastMessageId: 1,
    lastSubId: 1,
    subscriptions: [],
    messages: [],
    connected: false,
    host: 'mqtt.usnovelty.com',
    port: 8884,
    clientId: computed(function () {
      return Math.random().toString(36).slice(2, 36);
    }),
    username: 'printing',
    password: 'Printer1@$',
    lastUpdate: null,
    connect: function connect() {
      var username = 'printing';
      var password = 'Printer1@$';
      var keepAlive = 60;
      var cleanSession = true;
      var lwTopic = '';
      var lwQos = 0;
      var lwRetain = false;
      var lwMessage = '';
      var ssl = true;

      var client = new Paho.MQTT.Client(this.get('host'), this.get('port'), this.get('clientId'));
      client.onConnectionLost = this.onConnectionLost;
      client.onMessageArrived = this.onMessageArrived;
      client.service = this;
      this.set('client', client);

      var options = {
        timeout: 3,
        keepAliveInterval: keepAlive,
        cleanSession: cleanSession,
        useSSL: ssl,
        onSuccess: this.onConnect,
        onFailure: this.onFail,
        invocationContext: this
      };

      if (username.length > 0) {
        options.userName = username;
      }
      if (password.length > 0) {
        options.password = password;
      }
      if (lwTopic.length > 0) {
        var willmsg = new Messaging.Message(lwMessage);
        willmsg.qos = lwQos;
        willmsg.destinationName = lwTopic;
        willmsg.retained = lwRetain;
        options.willMessage = willmsg;
      }

      client.connect(options);
    },
    onConnect: function onConnect() {

      this.connected = true;
      this.invocationContext.set('connected', true);
    },
    onFail: function onFail(message) {
      this.set('connected', false);
      console.log('onFail');
      console.log(this);
    },
    onConnectionLost: function onConnectionLost(responseObject) {
      this.connected = false;
      if (responseObject.errorCode !== 0) {}

      //Cleanup messages
      // websocketclient.messages = [];

      //Cleanup subscriptions
      // websocketclient.subscriptions = [];
    },
    onMessageArrived: function onMessageArrived(message) {

      //var subscription = websocketclient.getSubscriptionForTopic(message.destinationName);

      var messageObj = {
        'topic': message.destinationName,
        'retained': message.retained,
        'qos': message.qos,
        'payload': message.payloadString,
        'timestamp': moment()
      };

      console.log(new Date() + ' - ' + message.payloadString);

      this.connectOptions.invocationContext.set(message.destinationName.replace(/\//g, '_'), message.payloadString);
      this.connectOptions.invocationContext.set('lastUpdate', new Date());
      //this.set('lastMessage',messageObj.payload);
      //websocketclient.messages.push(messageObj);
    },
    disconnect: function disconnect() {
      this.client.disconnect();
    },
    publish: function publish(topic, payload, qos, retain) {
      if (!websocketclient.connected) {
        websocketclient.render.showError("Not connected");
        return false;
      }
      var message = new Messaging.Message(payload);
      message.destinationName = topic;
      message.qos = qos;
      message.retained = retain;
      this.client.send(message);
    },
    subscribe: function subscribe(topic, qosNr) {
      if (!this.get('client').connected) {
        //    return false;
      }

      if (topic.length < 1) {
        return false;
      }

      if (this.get('subscriptions').indexOf(topic) > -1) {
        return false;
      }

      this.client.subscribe(topic, { qos: qosNr });

      this.get('subscriptions').pushObject(topic);
      return true;
    },
    unsubscribe: function unsubscribe(id) {
      var subs = _.find(websocketclient.subscriptions, { 'id': id });
      this.client.unsubscribe(subs.topic);
      websocketclient.subscriptions = _.filter(websocketclient.subscriptions, function (item) {
        return item.id != id;
      });
    },
    deleteSubscription: function deleteSubscription(id) {
      if (confirm('Unsubscribe?')) {
        elem.remove();
        this.unsubscribe(id);
      }
    }

  });
});