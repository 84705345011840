define('core/authenticators/jwt', ['exports', 'ember-simple-auth/authenticators/base', 'core/config/environment'], function (exports, _base, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Promise = Ember.RSVP.Promise,
      ajax = Ember.$.ajax,
      run = Ember.run;
  exports.default = _base.default.extend({
    test: 'true',
    tokenEndpoint: _environment.default.host + '/user_token',
    restore: function restore(data) {
      return new Promise(function (resolve, reject) {
        if (!Ember.isEmpty(data.token)) {
          resolve(data);
        } else {
          reject();
        }
      });
    },
    authenticate: function authenticate(creds) {
      var identification = creds.identification,
          password = creds.password;

      var data = JSON.stringify({
        auth: {
          email: identification,
          password: password
        }
      });
      var requestOptions = {
        url: this.tokenEndpoint,
        type: 'POST',
        data: data,
        contentType: 'application/json',
        dataType: 'json'
      };
      return new Promise(function (resolve, reject) {
        ajax(requestOptions).then(function (response) {
          var jwt = response.jwt;
          // Wrapping aync operation in Ember.run

          run(function () {
            resolve({
              token: jwt
            });
          });
        }, function (error) {
          // Wrapping aync operation in Ember.run
          run(function () {
            reject(error);
          });
        });
      });
    },
    invalidate: function invalidate(data) {
      return Promise.resolve(data);
    }
  });
});