define('core/models/picking-rack', ['exports', 'ember-data', 'ember-api-actions'], function (exports, _emberData, _emberApiActions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = _emberData.default.Model.extend({
    location: _emberData.default.attr('string'),
    status: _emberData.default.attr('string'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    user: _emberData.default.belongsTo('user'),
    hasPicked: _emberData.default.attr('boolean'),
    pickedCount: _emberData.default.attr('number'),
    pickingBins: _emberData.default.hasMany('picking-bin'),
    shipments: _emberData.default.hasMany('shipment'),
    clear: (0, _emberApiActions.memberAction)({ path: 'clear', type: 'put' }),
    sortedBins: computed.sort('pickingBins', function (a, b) {
      if (a.id * 1 > b.id * 1) {
        return 1;
      } else if (a.id * 1 < b.id * 1) {
        return -1;
      } else {
        return 0;
      }
    })
  });
});