define('core/routes/items/new', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  exports.default = Route.extend({
    model: function model() {
      return this.get('store').createRecord('item');
    },
    afterModel: function afterModel(model, transition) {
      transition.send('setModalItem', model);
    }
  });
});