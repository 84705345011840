define('core/models/page-block', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    blockType: _emberData.default.attr('string'),
    position: _emberData.default.attr('number'),
    imageUrl: _emberData.default.attr('string'),
    anchor: _emberData.default.attr('string'),
    accentColor: _emberData.default.attr('string'),
    path: _emberData.default.attr('string'),
    title: _emberData.default.attr('string'),
    justify: _emberData.default.attr('string'),
    html: _emberData.default.attr('string'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    options: _emberData.default.attr(),
    primaryColor: _emberData.default.attr('string'),
    page: _emberData.default.belongsTo('page'),
    pageBlocks: _emberData.default.hasMany('page-block'),
    image: computed('imageUrl', function () {
      return '//d2mk6k62owtk8p.cloudfront.net/shopify/' + this.get('imageUrl');
    }),
    wideLeft: computed('options', function () {
      console.log(this.get('options'));
      return this.get('options.wide-image-left');
    }),
    wideRight: computed('options', function () {
      console.log(this.get('options'));
      return this.get('options.wide-image-right');
    }),
    tallLeft: computed('options', function () {
      console.log(this.get('options'));
      return this.get('options.tall-image-left');
    }),
    tallRight: computed('options', function () {
      console.log(this.get('options'));
      return this.get('options.tall-image-right');
    })

  });
});