define('core/serializers/application', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.JSONAPISerializer.extend({
    serializeAttribute: function serializeAttribute(snapshot, json, key, attribute) {
      // do not serialize the attribute!
      if (attribute.options && attribute.options.readOnly) {
        return;
      }
      if (attribute.name == 'createdAt' || attribute.name == 'updatedAt') {
        return;
      }
      this._super.apply(this, arguments);
    },
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      if (payload.included) {
        payload.included.forEach(function (inc) {

          if (inc.id === '85769') {}
          if (inc.relationships) {
            Object.keys(inc.relationships).forEach(function (rel) {
              if (Array.isArray(inc.relationships[rel].data) && inc.relationships[rel].data.length == 0) {
                delete inc.relationships[rel];
              }
            });
          }
        });
      }

      if (payload.data && payload.data.relationships) {
        Object.keys(payload.data.relationships).forEach(function (rel) {
          if (Array.isArray(payload.data.relationships[rel].data) && payload.data.relationships[rel].data.length == 0) {
            delete payload.data.relationships[rel];
          }
        });
      } else if (Array.isArray(payload.data)) {
        payload.data.forEach(function (i, ix) {
          if (i.relationships) {
            Object.keys(i.relationships).forEach(function (rel) {
              if (Array.isArray(i.relationships[rel].data) && i.relationships[rel].data.length == 0) {
                delete i.relationships[rel];
              }
            });
          }
        });
      }

      return this._super.apply(this, arguments);
    }
  });
});