define('core/models/purchase-order-item', ['exports', 'ember-data', 'ember-api-actions'], function (exports, _emberData, _emberApiActions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = _emberData.default.Model.extend({
    projectRecordId: _emberData.default.attr('number'),
    name: _emberData.default.attr('string'),
    reorderNumber: _emberData.default.attr('string'),
    sku: computed.alias('item.sku'),
    quantityOrdered: _emberData.default.attr('number'),
    quantityReceived: _emberData.default.attr('number'),
    quantityReceivedToDate: _emberData.default.attr('number'),
    received: computed('quantityReceived', 'quantityReceivedToDate', function () {
      return this.get('quantityReceived') > 0 ? this.get('quantityReceived') : this.get('quantityReceivedToDate');
    }),
    quantityShipped: computed('quantityReceived', 'quantityReceivedToDate', function () {
      return this.get('quantityReceived') + this.get('quantityReceivedToDate');
    }),
    price: _emberData.default.attr('number'),
    extPrice: computed('quantityOrdered', 'price', function () {
      return this.get('quantityOrdered') * this.get('price');
    }),
    backordered: _emberData.default.attr('boolean'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    fulfillmentQuantity: _emberData.default.attr('number'),
    fulfillmentQuantityReserved: _emberData.default.attr('number'),
    orderInMpq: _emberData.default.attr('boolean'),
    purchaseOrder: _emberData.default.belongsTo('purchase-order'),
    item: _emberData.default.belongsTo('item'),
    pickedItems: _emberData.default.hasMany('picked-item'),
    destinations: _emberData.default.hasMany('destination'),
    getDestinationInfo: (0, _emberApiActions.memberAction)({ path: 'destination_info', type: 'get' }),
    scannableDestinations: computed.filterBy('destinations', 'scannable', true),
    scannableQuantities: computed.mapBy('scannableDestinations', 'quantityAssigned'),
    scannableAssigned: computed.sum('scannableQuantities'),
    picksRemaining: computed('quantityOrdered', 'quantityReceived', 'quantityReceivedToDate', 'pickedItems.@each.quantity', function () {
      if (this.get('pickedItems.length') > 0) {
        var picked = this.get('pickedItems').reduce(function (acc, val) {
          acc += val.get('quantity');
          return acc;
        }, 0);
        return this.get('quantityOrdered') - picked;
      } else {
        return this.get('quantityOrdered');
      }
    }),
    keypadValue: computed('picksRemaining', function () {
      return this.get('picksRemaining');
    })
    //store: DS.belongsTo('store')
  });
});