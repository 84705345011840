define('core/models/received-item', ['exports', 'ember-data', 'ember-api-actions'], function (exports, _emberData, _emberApiActions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = _emberData.default.Model.extend({
    receiver: _emberData.default.belongsTo('user'),
    stocker: _emberData.default.belongsTo('user'),
    stockedAt: _emberData.default.attr('date'),
    quantity: _emberData.default.attr('number'),
    quantityStocked: _emberData.default.attr('number'),
    destination: _emberData.default.belongsTo('destination'),
    receivingScore: _emberData.default.attr('number'),
    stockingScore: _emberData.default.attr('number'),
    scanIdentifier: _emberData.default.attr('string'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    preppedAt: _emberData.default.attr('date'),
    prepperId: _emberData.default.attr('number', { readOnly: true }),
    receivingTote: _emberData.default.belongsTo('receiving-tote'),
    receivingToteId: _emberData.default.attr('number'),
    bin: _emberData.default.belongsTo('bin'),
    orderItem: _emberData.default.belongsTo('order-item'),
    item: _emberData.default.belongsTo('item'),
    issue: _emberData.default.attr('number'),
    currentQuantity: _emberData.default.attr('number'),
    inboundShipmentItem: _emberData.default.belongsTo('inbound-shipment-item'),
    quantityRemaining: computed('quantity', 'quantityStocked', function () {
      return this.get('quantity') - (this.get('quantityStocked') || 0);
    }),
    quantityToting: computed('quantity', 'quantityStocked', function () {
      return this.get('quantity') - (this.get('quantityStocked') || 0);
    }),
    printLabels: (0, _emberApiActions.memberAction)({ path: 'print_labels', type: 'put' })

  });
});