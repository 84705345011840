define('core/components/edit-shipment', ['exports', 'ember-keyboard-shortcuts/mixins/component', 'ember-concurrency'], function (exports, _component, _emberConcurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var service = Ember.inject.service;
  var computed = Ember.computed;
  exports.default = Component.extend(_component.default, {
    store: service(),
    actions: {

      clearModal: function clearModal() {
        this.sendAction('clearModalShipment');
      },
      save: function save() {
        this.get('shipment').save();
      }

    }
  });
});