define('core/models/pending-order', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = _emberData.default.Model.extend({
    number: _emberData.default.attr('string'),
    salesChannel: _emberData.default.attr('string'),
    billingCountry: _emberData.default.attr('string'),
    serviceLevel: _emberData.default.attr('string'),
    status: _emberData.default.attr('number'),
    latestShipAt: _emberData.default.attr('date'),
    placedAt: _emberData.default.attr('date'),
    total: _emberData.default.attr('number'),
    shipping: _emberData.default.attr('number'),
    commission: _emberData.default.attr('number'),
    inventory: _emberData.default.attr('number'),
    smallUnits: _emberData.default.attr('number'),
    largeUnits: _emberData.default.attr('number'),
    oversizeUnits: _emberData.default.attr('number'),
    standardWeight: _emberData.default.attr('number'),
    oversizeWeight: _emberData.default.attr('number'),
    pickFee: _emberData.default.attr('number'),
    orderFee: _emberData.default.attr('number'),
    weightFee: _emberData.default.attr('number'),
    profit: _emberData.default.attr('number'),
    estTotal: _emberData.default.attr('number'),
    estProfit: _emberData.default.attr('number'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    pendingOrderItems: _emberData.default.hasMany('pending-order-item'),
    projectRecords: _emberData.default.hasMany('project-record'),
    marginOnRevenue: computed('total', 'estTotal', 'profit', 'estProfit', function () {
      var total = this.get('total') || this.get('estTotal');
      var profit = this.get('profit') || this.get('estProfit');
      return Math.round(profit * 100 / total * 100) / 100;
    }),
    marginOnCost: computed('profit', 'estProfit', 'inventory', function () {
      var cost = this.get('inventory');
      var profit = this.get('profit') || this.get('estProfit');
      return Math.round(profit * 100 / cost * 100) / 100;
    }),
    bestTotal: computed('total', 'estTotal', function () {
      return this.get('total') || this.get('estTotal');
    }),
    bestProfit: computed('profit', 'estProfit', function () {
      return this.get('profit') || this.get('estProfit');
    })
  });
});