define('core/models/station', ['exports', 'ember-data', 'ember-api-actions'], function (exports, _emberData, _emberApiActions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    number: _emberData.default.attr('number'),
    type: _emberData.default.attr('string'),
    info: _emberData.default.attr('string'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    hardwares: _emberData.default.hasMany('hardware'),
    printers: _emberData.default.hasMany('printer'),
    scales: _emberData.default.hasMany('scale'),
    swapPrinters: (0, _emberApiActions.memberAction)({ path: 'swap_printers', type: 'put' })
  });
});