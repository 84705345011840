define('core/models/fulfillment-center', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    code: _emberData.default.attr('string'),
    fulfillmentType: _emberData.default.attr('string'),
    boxDirectly: _emberData.default.attr('boolean'),
    maxWeight: _emberData.default.attr('number'),
    maxAge: _emberData.default.attr('number'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    inboundShipments: _emberData.default.hasMany('inbound-shipment')
  });
});