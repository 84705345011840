define('core/models/order', ['exports', 'ember-data', 'ember-api-actions'], function (exports, _emberData, _emberApiActions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = _emberData.default.Model.extend({
    placedAt: _emberData.default.attr('date'),
    total: _emberData.default.attr('number'),
    salesTax: _emberData.default.attr('number'),
    number: _emberData.default.attr('string'),
    poNumber: _emberData.default.attr('string'),
    comments: _emberData.default.attr('string'),
    status: _emberData.default.attr('string', { readOnly: true }),
    inventory: _emberData.default.attr('number'),
    shipping: _emberData.default.attr('number'),
    commission: _emberData.default.attr('number'),
    fulfillment: _emberData.default.attr('number'),
    profit: _emberData.default.attr('number'),
    signature: _emberData.default.attr('string'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    storeId: _emberData.default.attr('number'),
    department: _emberData.default.belongsTo('department'),
    projectRecords: _emberData.default.hasMany('project-record'),
    orderItems: _emberData.default.hasMany('order-item'),
    orderEvents: _emberData.default.hasMany('order-event'),
    orderNotes: _emberData.default.hasMany('order-note'),
    sortedItems: computed.sort('orderItems', 'sortOrder'),
    reverseSortedItems: computed.sort('orderItems', 'reverseSortOrder'),
    sortOrder: ['position'],
    reverseSortOrder: ['position:desc'],
    customer: _emberData.default.belongsTo('customer'),
    shipTo: _emberData.default.belongsTo('ship-to'),
    user: _emberData.default.belongsTo('user'),
    rbTotal: _emberData.default.attr('number', { readOnly: true }),
    paymentAmount: _emberData.default.attr('number'),
    orderPayments: _emberData.default.hasMany('order-payment'),
    //posBatch: DS.belongsTo('pos-batch'),
    //channel: DS.belongsTo('channel'),
    shipments: _emberData.default.hasMany('shipment'),
    marketId: _emberData.default.attr('string'),
    open: computed('status', function () {
      return !this.get('status') || this.get('status') === 'open' || this.get('status') === 'processing';
    }),
    closed: computed('open', 'status', function () {
      return !this.get('open');
    }),
    print: (0, _emberApiActions.memberAction)({ path: 'print', type: 'put' }),
    cancel: (0, _emberApiActions.memberAction)({ path: 'cancel', type: 'put' }),
    paymentToken: _emberData.default.belongsTo('payment-token'),
    ecSubTotals: computed.mapBy('orderItems', 'ecExtPrice'),
    ecSubTotal: computed.sum('ecSubTotals'),
    ecRefundSubTotals: computed.mapBy('orderItems', 'ecExtRefundPrice'),
    ecRefundSubTotal: computed.sum('ecRefundSubTotals'),
    ecCosts: computed.mapBy('orderItems', 'ecExtCost'),
    ecCost: computed.sum('ecCosts'),
    ecProfit: computed('ecSubTotal', 'ecCost', function () {
      console.log(this.get('ecSubtotal'));
      console.log(this.get('ecCost'));
      return this.get('ecSubTotal') - this.get('ecCost');
    }),
    bestDate: computed('placedAt', 'createdAt', function () {
      return this.get('placedAt') || this.get('createdAt');
    }),
    locked: computed('placedAt', function () {
      return !!this.get('placedAt');
    }),
    unlocked: computed('locked', function () {
      return !this.get('placedAt');
    }),
    quantityShort: computed('orderItems.@each.quantityShort', function () {
      return this.get('orderItems').reduce(function (acc, val) {
        acc += val.get('quantityShort');
        return acc;
      }, 0);
    }),
    taxExempt: computed('customer.taxExempt', function () {
      return this.get('customer.taxExempt');
    }),
    taxRate: computed('taxExempt', function () {
      console.log('tax');
      console.log(this.get('customer.taxExempt'));
      if (this.get('taxExempt')) {
        return 0;
      }
      return 0.0745;
    }),
    ecSalesTax: computed('placedAt', 'ecSubTotal', 'ecTotal', 'taxRate', function () {
      console.log('ecSalesTax');
      console.log(this.get('salesTax'));
      if (this.get('placedAt')) {
        return this.get('salesTax');
      }

      var taxable = this.get('orderItems').reduce(function (acc, val) {
        if (val.get('item.taxable')) {
          acc += val.get('ecExtPrice') * 1;
        }
        return acc;
      }, 0);

      console.log(Math.round(100 * taxable * this.get('taxRate')) / 100);
      return Math.round(100 * taxable * this.get('taxRate')) / 100;
    }),
    ecRefundSalesTax: computed('ecRefundSubTotal', 'ecRefundTotal', 'taxRate', function () {
      console.log(this.get('ecRefundSubTotal'));
      return Math.round(100 * this.get('ecRefundSubTotal') * this.get('taxRate')) / 100;
    }),
    ecTotal: computed('ecSubTotal', 'ecSalesTax', 'ecShipping', function () {
      console.log(this.get('ecSubTotal'));
      console.log(this.get('ecSalesTax'));
      console.log(this.get('ecShipping'));
      return Math.round((this.get('ecSubTotal') + this.get('ecSalesTax') + this.get('ecShipping')) * 100) / 100;
    }),
    ecRefundTotal: computed('ecRefundSubTotal', 'ecRefundSalesTax', function () {
      console.log(this.get('ecRefundSubTotal'));
      console.log(this.get('ecRefundSalesTax'));
      return Math.round((this.get('ecRefundSubTotal') + this.get('ecRefundSalesTax')) * 100) / 100;
    }),
    ecShipmentCharges: computed.mapBy('shipments', 'charge'),
    ecShipping: computed('ecShipmentCharges', function () {
      return this.get('ecShipmentCharges').reduce(function (acc, val) {
        acc += val * 1;
        return acc;
      }, 0);
    }),
    emailInvoice: (0, _emberApiActions.memberAction)({ path: 'email_invoice', type: 'put' }),
    pdfInvoice: (0, _emberApiActions.memberAction)({ path: 'invoice', type: 'get' }),
    emailPaperwork: (0, _emberApiActions.memberAction)({ path: 'email_paperwork', type: 'put' }),
    pdfPaperwork: (0, _emberApiActions.memberAction)({ path: 'paperwork', type: 'get' }),
    chargeCard: (0, _emberApiActions.memberAction)({ path: 'charge_card', type: 'put' }),
    canCharge: computed('paymentsTotal', 'ecTotal', 'rbTotal', function () {
      return this.get('ecTotal') === this.get('rbTotal') && (this.get('ecTotal') > 0 && this.get('paymentsTotal') < this.get('ecTotal') || this.get('ecTotal') < 0 && this.get('paymentsTotal') > this.get('ecTotal'));
    }),
    paymentsTotal: computed('orderPayments.@each.amount', 'payments.@each.status', function () {
      return this.get('orderPayments').reduce(function (acc, value) {
        // if (value.get('status') === 'pending' || value.get('status') === 'success') {
        acc += value.get('amount');
        // }
        return acc;
      }, 0);
    }),
    emailInvoiceData: computed('customer.name', 'customer.email', 'id', function () {
      return { name: this.get('customer.name'),
        email: this.get('customer.email'),
        subject: 'Invoice #' + this.get('id'),
        body: 'A copy of your invoice is attached.' };
    })

  });
});