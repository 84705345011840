define('core/routes/projects/work', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  exports.default = Route.extend({
    titleToken: 'Project',
    model: function model() {
      return this.store.queryRecord('project-batch', { work: true });
    }
  });
});