define('core/models/amazon-listing', ['exports', 'ember-data', 'ember-api-actions'], function (exports, _emberData, _emberApiActions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = _emberData.default.Model.extend({
    projectRecordId: _emberData.default.attr('number'),
    sku: _emberData.default.attr('string'),
    asin: _emberData.default.attr('string'),
    quantity: _emberData.default.attr('number'),
    price: _emberData.default.attr('number'),
    minPrice: _emberData.default.attr('number'),
    maxPrice: _emberData.default.attr('number'),
    mapPrice: _emberData.default.attr('number'),
    multiple: _emberData.default.attr('number'),
    region: _emberData.default.attr('string'),
    active: _emberData.default.attr('boolean'),
    title: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    pullListing: _emberData.default.attr('boolean'),
    listingId: _emberData.default.attr('string'),
    deleteListing: _emberData.default.attr('boolean'),
    priceSyncedAt: _emberData.default.attr('date'),
    productSyncedAt: _emberData.default.attr('date'),
    inventorySyncedAt: _emberData.default.attr('date'),
    lastNotificationAt: _emberData.default.attr('date'),
    lastRepricedAt: _emberData.default.attr('date'),
    repriceAt: _emberData.default.attr('date'),
    calculateStockAt: _emberData.default.attr('date'),
    lastCrawledAt: _emberData.default.attr('date'),
    crawlAt: _emberData.default.attr('date'),
    lastSoldAt: _emberData.default.attr('date'),
    lastReceivedAt: _emberData.default.attr('date'),
    prepInfo: _emberData.default.attr('string'),
    length: _emberData.default.attr('number'),
    width: _emberData.default.attr('number'),
    height: _emberData.default.attr('number'),
    weight: _emberData.default.attr('number'),
    inboundCost: _emberData.default.attr('number'),
    bubbleWrap: _emberData.default.attr('boolean'),
    soldAsSet: _emberData.default.attr('boolean'),
    bullet1: _emberData.default.attr('string'),
    bullet2: _emberData.default.attr('string'),
    bullet3: _emberData.default.attr('string'),
    bullet4: _emberData.default.attr('string'),
    bullet5: _emberData.default.attr('string'),
    search1: _emberData.default.attr('string'),
    search2: _emberData.default.attr('string'),
    search3: _emberData.default.attr('string'),
    search4: _emberData.default.attr('string'),
    search5: _emberData.default.attr('string'),
    sizeName: _emberData.default.attr('string'),
    colorName: _emberData.default.attr('string'),
    verifiedAt: _emberData.default.attr('date'),
    restockLevel: _emberData.default.attr('number'),
    reorderPoint: _emberData.default.attr('number'),
    supplierRestockLevel: _emberData.default.attr('number'),
    supplierReorderPoint: _emberData.default.attr('number'),
    lastPrice: _emberData.default.attr('number'),
    lastQuantity: _emberData.default.attr('number'),
    winning: _emberData.default.attr('boolean'),
    fba: _emberData.default.attr('boolean'),
    barcode: _emberData.default.attr('string'),
    fulfillmentQuantity: _emberData.default.attr('number'),
    unfulfillableReason: _emberData.default.attr('string'),
    rank: _emberData.default.attr('number'),
    rankCategory: _emberData.default.attr('string'),
    unauthorized: _emberData.default.attr('boolean'),
    restricted: _emberData.default.attr('boolean'),
    category: _emberData.default.attr('string'),
    hazmat: _emberData.default.attr('boolean'),
    sellerFulfilled: _emberData.default.attr('boolean'),
    advertisingKeywords: _emberData.default.attr('string'),
    validName: _emberData.default.attr('boolean'),
    validBullets: _emberData.default.attr('boolean'),
    validDescription: _emberData.default.attr('boolean'),
    validImage: _emberData.default.attr('boolean'),
    lastSellerPrice: _emberData.default.attr('number'),
    dataChangedAt: _emberData.default.attr('date'),
    doNotReprice: _emberData.default.attr('boolean'),
    sellerPrice: _emberData.default.attr('number'),
    unfulfillableQuantity: _emberData.default.attr('number'),
    priceReason: _emberData.default.attr('string'),
    // detailPage: DS.belongsTo('detail-page'),
    offer: _emberData.default.belongsTo('amazon-notification-offer'),
    priceRule: _emberData.default.belongsTo('price-rule'),
    priceReasonData: _emberData.default.attr('string'),
    fnSku: _emberData.default.attr('string'),
    prepInstruction: _emberData.default.attr('string'),
    expiresCode: _emberData.default.attr('number'),
    targetBuyBox: _emberData.default.attr('boolean'),
    maximumPriceLower: _emberData.default.attr('number'),
    minimumPriceLower: _emberData.default.attr('number'),
    minimumPrice: _emberData.default.attr('number', { readOnly: true }),
    maximumPrice: _emberData.default.attr('number', { readOnly: true }),
    age: _emberData.default.attr('number', { readOnly: true }),
    salesHistory: _emberData.default.attr({ readOnly: true }),
    inboundError: _emberData.default.attr('string', { readOnly: true }),
    planQuantity: _emberData.default.attr('number', { readOnly: true }),
    daysSoldOut: _emberData.default.attr('number', { readOnly: true }),
    totalQuantity: _emberData.default.attr('number'),
    inStockQuantity: _emberData.default.attr('number'),
    merchantOfferCount: _emberData.default.attr('number'),
    fbaOfferCount: _emberData.default.attr('number'),
    priceToLow: _emberData.default.attr('number'),
    priceToMerchant: _emberData.default.attr('number'),
    priceToFba: _emberData.default.attr('number'),
    amazonCompetes: _emberData.default.attr('boolean'),
    haveBuyboxDiff: _emberData.default.attr('number'),
    lostBuyboxDiff: _emberData.default.attr('number'),
    pullSellerListing: _emberData.default.attr('boolean'),
    listingReplaced: _emberData.default.attr('boolean'),
    amazonCompetedAt: _emberData.default.attr('date'),
    minimumRestockLevel: _emberData.default.attr('number'),
    maximumRestockLevel: _emberData.default.attr('number'),
    yoyoStartsAt: _emberData.default.attr('date'),
    yoyoEndsAt: _emberData.default.attr('date'),
    priceCategory: _emberData.default.attr('number'),
    priceCategoryChangedAt: _emberData.default.attr('date'),
    holdPriceCategoryFor: _emberData.default.attr('number'),
    pullSellerReason: _emberData.default.attr('string'),
    pullSellerAt: _emberData.default.attr('date'),
    pullFbaReason: _emberData.default.attr('string'),
    pullFbaAt: _emberData.default.attr('date'),
    closeoutReason: _emberData.default.attr('string'),
    stockLevel: _emberData.default.attr('number'),
    sfp: _emberData.default.attr('boolean'),
    sellerQuantity: _emberData.default.attr('number'),
    sellerQuantitySyncedAt: _emberData.default.attr('date'),
    inventoryChangedAt: _emberData.default.attr('date'),
    standardRate: _emberData.default.attr('number'),
    priorityRate: _emberData.default.attr('number'),
    baseRate: _emberData.default.attr('number'),
    lastShippingTemplate: _emberData.default.attr('string'),
    status: _emberData.default.attr('string'),
    amazonStatus: _emberData.default.attr('string'),
    listingStatus: computed('pullListing', 'deleteListing', 'amazonStatus', function () {
      if (this.get('deleteListing')) {
        return 'Delete';
      } else if (this.get('pullListing')) {
        return 'Pulled';
      } else if (this.get('amazonStatus')) {
        return this.get('amazonStatus');
      } else {
        return 'Unknown';
      }
    }),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    //projectRecords: DS.hasMany('project-record'),
    amazonPrices: _emberData.default.hasMany('amazon-price'),
    amazonProducts: _emberData.default.hasMany('amazon-product'),
    amazonInventories: _emberData.default.hasMany('amazon-inventories'),
    item: _emberData.default.belongsTo('item'),
    type: _emberData.default.attr('string'),
    fslError: _emberData.default.attr('string'),
    pricingProfile: _emberData.default.belongsTo('pricing-profile'),
    carton: _emberData.default.belongsTo('carton'),
    // verifier: DS.belongsTo('verifier'),
    // user: DS.belongsTo('user'),
    pricing: _emberData.default.belongsTo('amazon-notification'),
    // lastStockOut: DS.belongsTo('last-stock-out'),
    // lastBuyboxNotification: DS.belongsTo('last-buybox-notification'),
    // lastLosingNotification: DS.belongsTo('last-losing-notification'),
    // yoyoOffer: DS.belongsTo('yoyo-offer'),
    // buyboxOffer: DS.belongsTo('buybox-offer'),
    // pullSellerUser: DS.belongsTo('pull-seller-user'),
    // pullFbaUser: DS.belongsTo('pull-fba-user')
    inboundShipmentItems: _emberData.default.hasMany('inbound-shipment-item'),
    pendingOrderItems: _emberData.default.hasMany('pending-order-item'),
    inboundQuantity: _emberData.default.attr('number', { readOnly: true }),
    monthlyUnits: _emberData.default.attr('number', { readOnly: true }),
    monthlySales: _emberData.default.attr('number', { readOnly: true }),
    nextAction: _emberData.default.attr('string'),
    nextActionAt: _emberData.default.attr('date'),
    getInboundShipments: (0, _emberApiActions.memberAction)({ path: 'inbound_shipments', type: 'get' }),
    getSalesChart: (0, _emberApiActions.memberAction)({ path: 'sales_chart', type: 'get' }),
    getEvents: (0, _emberApiActions.memberAction)({ path: 'events', type: 'get' }),
    getFbaOrders: (0, _emberApiActions.memberAction)({ path: 'fba_orders', type: 'get' }),
    getPrices: (0, _emberApiActions.memberAction)({ path: 'prices', type: 'get' }),
    getInventories: (0, _emberApiActions.memberAction)({ path: 'inventories', type: 'get' }),
    getProducts: (0, _emberApiActions.memberAction)({ path: 'products', type: 'get' }),
    print: (0, _emberApiActions.memberAction)({ path: 'print', type: 'put' }),
    events: _emberData.default.hasMany('amazon-listing-event'),
    eventSort: ['startedAt:desc'],
    sortedEvents: computed.sort('events', 'eventSort'),
    holdStockLevel: computed('calculateStockAt', function () {
      return !!this.get('calculateStockAt');
    }),
    cost: computed('item.cost', 'multiple', function () {
      return this.get('item.cost') * this.get('multiple');
    }),
    dims: computed('length', 'width', 'height', 'weight', function () {
      return this.get('length') + ' x ' + this.get('width') + ' x ' + this.get('height') + ' x ' + this.get('weight') + ' oz';
    }),
    bestLength: computed('length', 'item.sMaxDim', function () {
      return this.get('length') || this.get('item.sMaxDim');
    }),
    bestWidth: computed('width', 'item.sMidDim', function () {
      return this.get('width') || this.get('item.sMidDim');
    }),
    bestHeight: computed('height', 'item.sMinDim', function () {
      return this.get('height') || this.get('item.sMinDim');
    }),
    bestWeight: computed('weight', 'item.weight', function () {
      return this.get('weight') || this.get('item.sMinDim');
    }),
    dimensionalWeight: computed('bestLength', 'bestWidth', 'bestHeight', function () {
      return Math.round(this.get('bestLength') * this.get('bestWidth') * this.get('bestHeight') / 1.66) / 100;
    }),
    fulfillmentCost: computed('expectedFulfillmentFee', 'carton.cost', 'type', function () {
      console.log(this.get('type'));
      if (this.get('type') === 'FbmListing') {
        return this.get('fbmFulfillmentCost');
      } else {
        console.log(this.get('expectedFulfillmentFee'));
        return this.get('expectedFulfillmentFee');
      }
    }),
    expectedFulfillmentFee: _emberData.default.attr('number'),
    fbaRate: computed.alias('fbaFulfillmentCost'),
    fbmFulfillmentCost: computed('carton.id', 'carton.cost', 'bestLength', 'bestWidth', 'bestHeight', 'bestWeight', function () {
      var cc = Math.round(this.get('carton.cost') * 100) / 100;
      var ix = Math.ceil(this.get('bestWeight') || 16);
      console.log('carton cost');
      console.log(cc);

      if (this.get('carton.id') * 1 === 1 && this.get('bestWeight') < 3.4) {
        console.log([0.5, 0.5, 0.65, 0.8, 0.95][ix]);
        console.log([0.5, 0.5, 0.65, 0.8, 0.95][ix] + cc);
        return [0.5, 0.5, 0.65, 0.8, 0.95][ix] + cc;
      } else if (this.get('carton.id') * 1 === 1651 || this.get('carton.id') * 1 === 1664 && this.get('bestWeight') < 16) {
        console.log('best Weight');
        console.log(this.get('bestWeight'));

        return 1 + 0.2 * Math.floor(this.get('bestWeight')) + cc;
      } else if (this.get('bestWeight') < 16) {
        return [3.0, 3.0, 3.0, 3.0, 3.0, 3.55, 3.55, 3.55, 3.55, 4.26, 4.26, 4.26, 4.26, 5.46, 5.46, 5.46, 5.46][ix] + cc;
      } else {
        return this.get('fbaFulfillmentCost') + cc;
      }
    }),
    fbaFulfillmentCost: computed('expectedFulfillmentFee', 'bestLength', 'bestWidth', 'bestHeight', 'bestWeight', function () {
      if (this.get('expectedFulfillmentFee')) {
        return this.get('expectedFulfillmentFee');
      }
      var cost = 0;
      if (this.get('bestLength') <= 15 && this.get('bestWidth') <= 12 && this.get('bestHeight') <= 0.75 && this.get('bestWeight') <= 6) {
        cost = 2.50;
      } else if (this.get('bestLength') <= 15 && this.get('bestWidth') <= 12 && this.get('bestHeight') <= 0.75 && this.get('bestWeight') <= 12) {
        cost = 2.63;
      } else if (this.get('bestLength') <= 18 && this.get('bestWidth') <= 14 && this.get('bestHeight') <= 8 && this.get('bestWeight') <= 320) {
        if (this.get('bestWeight') < 6) {
          cost = 3.31;
        } else if (this.get('bestWeight') < 12) {
          cost = 3.48;
        } else if (this.get('bestWeight') < 28) {
          cost = 4.90;
        } else if (this.get('bestWeight') < 44) {
          cost = 5.42;
        } else {
          var itemWeight = Math.max(this.get('dimensionalWeight'), this.get('bestWeight') / 16);
          cost = 5.42 + Math.ceil(Math.max(itemWeight + 0.25 - 3, 0)) * 0.38;
        }
      } else if (this.get('bestLength') <= 60 && this.get('bestWidth') <= 30 && this.get('bestWeight') <= 1120) {
        cost = 8.26;
        cost += Math.max(this.get('dimensionalWeight') - 2, Math.ceil((this.get('bestWeight') - 16) / 16), 0) * 0.38;
      } else if (this.get('weight') < 2400) {
        cost = 11.37;
        cost += Math.max(this.get('dimensionalWeight') - 2, Math.ceil((this.get('bestWeight') - 16) / 16), 0) * 0.79;
      } else {
        cost = 137.32;
        cost += Math.ceil((this.get('weight') - 1424) / 16) * 0.91;
      }

      if (this.get('category') === 'Apparel') {
        cost += 0.11;
        if (this.get('bestWeight') > 6) {
          cost += 0.19;
        }
      }
      return Math.round(cost * 100) / 100;
    }),
    maxDim: computed.alias('item.maxDim'),
    midDim: computed.alias('item.midDim'),
    minDim: computed.alias('item.minDim'),
    standardWeight: computed('carton.weight', 'item.weight', 'maxDim', 'midDim', 'minDim', function () {
      var weight = this.get('item.weight') + this.get('carton.weight') * 1;
      if (this.get('maxDim') + this.get('midDim') * 2 + this.get('minDim') * 2 < 108) {
        return Math.round(weight * 100) / 100;
      } else if (this.get('carton.name') === 'PACKAGE') {
        return Math.round(Math.max(weight, this.get('item.volume') / 194 * 18) * 100) / 100; //add ~10% for item without carton
      } else {
        return Math.round(Math.max(weight, this.get('item.volume') / 194 * 16) * 100) / 100;
      }
    }),
    priorityWeight: computed('carton.weight', 'item.weight', 'maxDim', 'midDim', 'minDim', function () {
      var weight = this.get('item.weight') + this.get('carton.weight') * 1;
      if (this.get('carton.name') === 'PACKAGE') {
        return Math.round(Math.max(weight, this.get('item.volume') / 194 * 18) * 100) / 100; //add ~10% for item without carton
      } else {
        return Math.round(Math.max(weight, this.get('item.volume') / 194 * 16) * 100) / 100;
      }
    }),
    priorityRates: [7.04, 7.04, 9.97, 14.45, 17.08, 19.80, 22.67, 25.46, 28.59, 31.79, 34.57, 37.45, 40.16, 41.58, 43.65, 44.81, 47.27, 49.76, 52.26, 54.75, 57.28, 58.01, 58.68, 59.03, 60.47, 61.52],
    standardRates: [2.61, 2.61, 2.61, 2.61, 2.61, 2.77, 2.77, 2.77, 2.77, 3.32, 3.46, 3.60, 3.74, 3.88, 4.02, 4.16, 4.30],
    ecStandardRate: computed('standardWeight', 'sfp', 'maxDim', 'midDim', 'minDim', 'item.mustShipGround', function () {
      if (this.get('item.weight') * 1 == 0) {
        return 15;
      }
      var rate = 0;
      if (this.get('sfp') && this.get('standardWeight') >= 16) {
        if (this.get('maxDim') < 11 && this.get('midDim') < 9 && this.get('minDim') < 1) {
          rate = 5.75;
        } else {
          rate = this.get('priorityRates')[Math.ceil(this.get('standardWeight') / 16)] || 75;
        }
      } else if (this.get('standardWeight') < 16) {
        rate = this.get('standardRates')[Math.ceil(this.get('standardWeight'))];
        if (this.get('item.mustShipGround')) {
          rate += 12;
        }
      } else if (this.get('standardWeight') >= 16) {
        return this.get('ecPriorityRate');
      }
      rate = Math.round(rate * 100) / 100;
      return rate;
    }),
    ecPriorityRate: computed('priorityWeight', 'sfp', 'maxDim', 'midDim', 'minDim', 'item.mustShipGround', function () {
      if (this.get('item.weight') * 1 == 0) {
        return 15;
      }
      var rate = 0;
      if (this.get('sfp') || this.get('priorityWeight') >= 16) {
        if (this.get('maxDim') < 11 && this.get('midDim') < 9 && this.get('minDim') < 1) {
          rate = 5.75;
        } else {
          rate = this.get('priorityRates')[Math.ceil(this.get('priorityWeight') / 16)];
        }
      } else {
        rate = this.get('standardRates')[Math.ceil(this.get('standardWeight') / 16)];
        if (this.get('item.mustShipGround')) {
          rate += 12;
        }
      }
      return rate;
    }),
    standardFulfillment: computed('ecStandardRate', 'carton.cost', function () {
      return Math.round((this.get('ecStandardRate') + this.get('carton.cost')) * 100) / 100;
    }),
    priorityFulfillment: computed('ecPriorityRate', 'carton.cost', function () {
      return Math.round((this.get('ecPriorityRate') + this.get('carton.cost')) * 100) / 100;
    }),
    fbaCommission: computed('price', function () {
      return Math.round(Math.max(this.get('price') * 0.15, 0.30) * 100) / 100;
    }),
    sellerCommission: computed('sellerPrice', function () {
      return Math.round(Math.max(this.get('sellerPrice') * 0.15, 0.30) * 100) / 100;
    }),
    profit: computed('carton.cost', 'type', 'price', 'carton.id', function () {
      console.log('Profit:');
      console.log(this.get('type'));
      console.log(this.get('price'));
      console.log(this.get('fbaCommission'));
      console.log(this.get('cost'));
      console.log(this.get('fbmFulfillmentCost'));
      if (this.get('type') === 'FbmListing') {
        return Math.round((this.get('price') - this.get('fbaCommission') - this.get('cost') - this.get('fbmFulfillmentCost')) * 100) / 100;
      } else {
        return Math.round((this.get('price') - this.get('fbaCommission') - this.get('cost') - this.get('fbaRate') - this.get('inboundCost')) * 100) / 100;
      }
    }),
    fbaProfit: computed('price', 'fbaCommission', 'cost', 'fbaRate', 'inboundCost', function () {
      return Math.round((this.get('price') - this.get('fbaCommission') - this.get('cost') - this.get('fbaRate') - this.get('inboundCost')) * 100) / 100;
    }),
    standardProfit: computed('sellerPrice', 'sellerCommission', 'cost', 'ecStandardFulfillment', 'carton.cost', function () {
      return Math.round((this.get('sellerPrice') - this.get('sellerCommission') - this.get('cost') - this.get('standardFulfillment')) * 100) / 100;
    }),
    priorityProfit: computed('sellerPrice', 'sellerCommission', 'cost', 'ecPriorityRate', 'carton.cost', function () {
      return Math.round((this.get('sellerPrice') - this.get('sellerCommission') - this.get('cost') - this.get('priorityFulfillment')) * 100) / 100;
    }),
    oversize: computed('bestLength', 'bestWidth', 'bestHeight', function () {
      if (this.get('bestLength') > 18 || this.get('bestWidth') > 14 || this.get('bestHeight') > 8) {
        return true;
      }
      return false;
    }),
    commissionWith: function commissionWith(amount) {
      if (amount >= 5.67) {
        return Math.round(amount / 0.0085) / 100;
      } else {
        return Math.round(amount * 100 + 100) / 100;
      }
    },
    minimumStandardPrice: computed('item.age', 'item.quantity', 'ecStandardRate', function () {
      if (this.get('item.quantity') <= 0) {
        return this.commissionWith(this.get('cost') * 1.15 + this.get('standardFulfillment'));
      } else if (this.get('item.age') * 1 < 365) {
        return this.commissionWith(this.get('cost') * 1.15 + this.get('standardFulfillment'));
      } else if (this.get('item.age') * 1 < 730) {
        return this.commissionWith(this.get('cost') * 1 + this.get('standardFulfillment'));
      } else if (this.get('item.age') * 1 < 1095) {
        return this.commissionWith(this.get('cost') * 0.8 + this.get('standardFulfillment'));
      } else {
        return this.commissionWith(this.get('cost') * 0.5 + this.get('standardFulfillment'));
      }
    }),
    minimumPriorityPrice: computed('item.age', 'item.quantity', 'ecPriorityRate', function () {
      if (this.get('item.quantity') <= 0) {
        return this.commissionWith(this.get('cost') * 1.15 + this.get('priorityFulfillment'));
      } else if (this.get('item.age') * 1 < 365) {
        return this.commissionWith(this.get('cost') * 1.15 + this.get('priorityFulfillment'));
      } else if (this.get('item.age') * 1 < 730) {
        return this.commissionWith(this.get('cost') * 1 + this.get('priorityFulfillment'));
      } else if (this.get('item.age') * 1 < 1095) {
        return this.commissionWith(this.get('cost') * 0.8 + this.get('priorityFulfillment'));
      } else {
        return this.commissionWith(this.get('cost') * 0.5 + this.get('priorityFulfillment'));
      }
    })

  });
});