define('core/models/shipping-method', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    internalName: _emberData.default.attr('string'),
    apiName: _emberData.default.attr('string'),
    apiCode: _emberData.default.attr('string'),
    tracked: _emberData.default.attr('boolean'),
    expedited: _emberData.default.attr('boolean'),
    guaranteed: _emberData.default.attr('boolean'),
    transitDaysMin: _emberData.default.attr('number'),
    transitDaysMax: _emberData.default.attr('number'),
    flatRate: _emberData.default.attr('number'),
    weightInPounds: _emberData.default.attr('boolean'),
    trackingUrl: _emberData.default.attr('string'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    shippingCarrierId: _emberData.default.attr('number'),
    shippingCarrier: _emberData.default.attr(),
    carrier: computed('shippingCarrierId', function () {
      if (this.get('shippingCarrierId') === 1) {
        return 'USN';
      } else if (this.get('shippingCarrierId') === 2) {
        return 'USPS';
      } else if (this.get('shippingCarrierId') === 3) {
        return 'UPS';
      }
    })
  });
});