define('core/components/edit-price-rule', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var service = Ember.inject.service;
  exports.default = Component.extend({
    store: service(),
    signOptions: [{ id: 'price_above', name: 'Above' }, { id: 'price_below', name: 'Below' }],
    typeOptions: [{ id: 'price_by_amount', name: 'Amount' }, { id: 'price_by_percent', name: 'Percent' }],
    againstOptions: [{ id: 'against_competition', name: 'Competition' }, { id: 'against_current_price', name: 'Current Price' }],
    sellerFbaOptions: [{ id: "seller_is_fba", name: "We are FBA" }, { id: "seller_small_and_light", name: "We are FBA Small & Light" }, { id: "seller_not_fba", name: "We are not FBA" }, { id: "seller_fba_any", name: "Doesn't Matter" }],
    compFbaOptions: [{ id: "comp_is_fba", name: "Competition is FBA" }, { id: "comp_not_fba", name: "Competition is not FBA" }, { id: "comp_fba_any", name: "Doesn't Matter" }],
    compStockOptions: [{ id: "comp_in_stock", name: "In Stock" }, { id: "comp_out_of_stock", name: "Out Of Stock" }, { id: "comp_any_stock", name: "Doesn't Matter" }],
    compLocationOptions: [{ id: "comp_domestic", name: "Domestic" }, { id: "comp_foreign", name: "Foreign" }, { id: "comp_global", name: "Doesn't Matter" }],
    sellerFreeShippingOptions: [{ id: "seller_free_shipping", name: "We have Free Shipping" }, { id: "seller_paid_shipping", name: "We do not have Free Shipping" }, { id: "seller_any_shipping", name: "Doesn't Matter" }],
    compFreeShippingOptions: [{ id: "comp_free_shipping", name: "Competition is Free Shipping" }, { id: "comp_paid_shipping", name: "Competition is not Free Shipping" }, { id: "comp_any_shipping", name: "Doesn't Matter" }],
    compMatchOptions: [{ id: "include_all", name: "Include All" }, { id: "include_sellers", name: "Include Sellers Listed" }, { id: "exclude_sellers", name: "Exclude Sellers Listed" }],
    lastSaleOptions: [{ id: "last_sold_before", name: "Before" }, { id: "last_sold_after", name: "After" }, { id: "last_sale_unknown", name: "Unknown" }],
    lastSalePeriodOptions: [{ id: "hours", name: "Hours" }, { id: "days", name: "Days" }, { id: "weeks", name: "Weeks" }, { id: "months", name: "Months" }],
    sellerBuyBoxOptions: [{ id: "seller_winning_box", name: "We are Winning Buy Box" }, { id: "seller_losing_box", name: "We are not winning Buy Box" }, { id: "seller_any_box", name: "Doesn't Matter" }],
    compBuyBoxOptions: [{ id: "comp_winning_box", name: "Competition Winning Buy Box" }, { id: "comp_losing_box", name: "Competition not winning Buy Box" }, { id: "comp_any_box", name: "Doesn't Matter" }],
    marginMinOptions: [{ id: "no_min_margin", name: "No Minimum Margin" }, { id: "margin_on_cost_above", name: "% Margin on Cost Above" }, { id: "margin_on_revenue_above", name: "% Margin on Revenue Above" }, { id: "margin_above", name: "Margin Amount Above" }],
    marginMaxOptions: [{ id: "no_max_margin", name: "No Maximum Margin" }, { id: "margin_on_cost_below", name: "% Margin on Cost Below" }, { id: "margin_on_revenue_below", name: "% Margin on Revenue Below" }, { id: "margin_below", name: "Margin Amount Below" }],
    comparisonOptions: [{ id: "competition_changed", name: "Competition Changed" }, { id: "competition_unchanged", name: "Competition Unchanged" }, { id: "any_competition_changes", name: "Doesn't Matter" }],
    actions: {
      setPriceSign: function setPriceSign(selected) {
        this.set('model.priceSign', selected.id);
      },
      setPriceAgainst: function setPriceAgainst(selected) {
        this.set('model.priceAgainst', selected.id);
      },
      setPriceBy: function setPriceBy(selected) {
        this.set('model.priceBy', selected.id);
      },
      setPriceAmount: function setPriceAmount(selected) {
        this.set('model.priceAmount', selected.id);
      },
      setSellerFba: function setSellerFba(selected) {
        this.set('model.sellerFba', selected.id);
      },
      setCompFba: function setCompFba(selected) {
        this.set('model.compFba', selected.id);
      },
      setCompStock: function setCompStock(selected) {
        this.set('model.compStock', selected.id);
      },
      setCompLocation: function setCompLocation(selected) {
        this.set('model.compLocation', selected.id);
      },
      setSellerFreeShipping: function setSellerFreeShipping(selected) {
        this.set('model.sellerFreeShipping', selected.id);
      },
      setCompFreeShipping: function setCompFreeShipping(selected) {
        this.set('model.compFreeShipping', selected.id);
      },
      setCompMatch: function setCompMatch(selected) {
        this.set('model.compMatch', selected.id);
      },
      setLastSaleSign: function setLastSaleSign(selected) {
        this.set('model.lastSaleSign', selected.id);
      },
      setLastSalePeriod: function setLastSalePeriod(selected) {
        this.set('model.lastSalePeriod', selected.id);
      },
      setSellerBuyBox: function setSellerBuyBox(selected) {
        this.set('model.sellerBuyBox', selected.id);
      },
      setCompBuyBox: function setCompBuyBox(selected) {
        this.set('model.compBuyBox', selected.id);
      },
      setMarginTypeMin: function setMarginTypeMin(selected) {
        this.set('model.marginTypeMin', selected.id);
      },
      setMarginTypeMax: function setMarginTypeMax(selected) {
        this.set('model.marginTypeMax', selected.id);
      },
      setComparison: function setComparison(selected) {
        this.set('model.winningToLosingComparison', selected.id);
      },
      save: function save() {
        this.get('model').save();
      },
      clearModal: function clearModal() {
        this.set('model', null);
      }

    }
  });
});