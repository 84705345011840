define('core/models/report', ['exports', 'ember-data', 'ember-api-actions'], function (exports, _emberData, _emberApiActions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    code: _emberData.default.attr('string'),
    details: _emberData.default.attr('string'),
    department: _emberData.default.attr('string'),
    group: _emberData.default.attr('string'),
    value: _emberData.default.attr('number'),
    nextUpdateAt: _emberData.default.attr('date'),
    mean: _emberData.default.attr('number'),
    info: _emberData.default.attr('string'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    reportHistories: _emberData.default.hasMany('report-history'),
    refresh: (0, _emberApiActions.memberAction)({ path: 'refresh', type: 'put' })
  });
});