define('core/services/speech', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = Ember.Service;
  var service = Ember.inject.service;
  exports.default = Service.extend({
    speechReader: service(),
    speak: function speak(phrase) {
      var speechReader = this.get('speechReader');
      var newReader = speechReader.getNewReader(phrase);
      newReader.play();
    }
  });
});