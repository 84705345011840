define('core/routes/items/edit', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  exports.default = Route.extend({
    afterModel: function afterModel(model, transition) {
      transition.send('setModalItem', model);
    }
  });
});