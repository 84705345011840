define('core/components/query-purchase-orders', ['exports', 'core/mixins/query-common'], function (exports, _queryCommon) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var service = Ember.inject.service;
  var computed = Ember.computed;
  exports.default = Component.extend(_queryCommon.default, {
    dataOptions: service(),
    ajax: service(),
    store: service(),
    listings: computed('item', function () {
      return { listingType: { name: 'All Listings', type: null }, items: this.get('query') };
    }),
    query: computed(function () {
      return {};
    }),
    actions: {
      fetchItems: function fetchItems() {
        var query = { query: { items: this.get('items') } };
        var _this = this;
        this.get('ajax').put('api/purchase_orders', { data: query }).then(function (results) {
          //_this.get('store').pushPayload(results);
          _this.set('results', results);
        });
      }
    }
  });
});