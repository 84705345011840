define('core/components/lt-date', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    format: 'MM/DD/YYYY hh:mm:ss A'
  });
});