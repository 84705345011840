define('core/components/data-table-menu-general', ['exports', 'ember-data-table/components/data-table-menu-general'], function (exports, _dataTableMenuGeneral) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _dataTableMenuGeneral.default;
    }
  });
});