define('core/uploaders/s3', ['exports', 'ember-uploader/uploaders/s3'], function (exports, _s) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _s.default;
    }
  });
});