define('core/models/order-item', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = _emberData.default.Model.extend({
    quantity: _emberData.default.attr('number'),
    name: _emberData.default.attr('string'),
    price: _emberData.default.attr('number'),
    cost: _emberData.default.attr('number'),
    salesTax: _emberData.default.attr('number'),
    extPrice: _emberData.default.attr('number'),
    extCost: _emberData.default.attr('number'),
    extSalesTax: _emberData.default.attr('number'),
    fullPrice: _emberData.default.attr('number'),
    priceSource: _emberData.default.attr('number'),
    taxable: _emberData.default.attr('boolean'),
    backorderedQuantity: _emberData.default.attr('number'),
    backorderedReason: _emberData.default.attr('number'),
    backorderedAt: _emberData.default.attr('date'),
    quantityShort: _emberData.default.attr('number'),
    shortedAt: _emberData.default.attr('date'),
    quantityBackordered: _emberData.default.attr('number'),
    pickedAt: _emberData.default.attr('date'),
    correction: _emberData.default.attr('boolean'),
    commission: _emberData.default.attr('number'),
    shippingCharge: _emberData.default.attr('number'),
    shippingCost: _emberData.default.attr('number'),
    profit: _emberData.default.attr('number'),
    unitProfit: _emberData.default.attr('number'),
    placedAt: _emberData.default.attr('date'),
    time: computed('createdAt', 'order.placedAt', 'order.createdAt', function () {
      return this.get('order.placedAt') || this.get('createdAt') || this.get('order.createdAt');
    }),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    projectRecords: _emberData.default.hasMany('project-record'),
    pickedItems: _emberData.default.hasMany('picked-item'),
    order: _emberData.default.belongsTo('order'),
    shipment: _emberData.default.belongsTo('shipment'),
    item: _emberData.default.belongsTo('item'),
    amazonListing: _emberData.default.belongsTo('amazon-listing'),
    marketId: _emberData.default.attr('string'),
    projectRecordId: _emberData.default.attr('number'),
    //salesRep: DS.belongsTo('sales-rep'),
    //channel: DS.belongsTo('channel'),
    picker: _emberData.default.belongsTo('user'),
    kitComponent: _emberData.default.attr('boolean', { readOnly: true }),
    shipmentStatus: _emberData.default.attr('string', { readOnly: true }),
    pickingTote: _emberData.default.belongsTo('picking-tote'),
    unscannable: _emberData.default.attr('boolean'),
    sku: computed.alias('item.sku'),
    location: computed.alias('item.location'),
    shipped: computed('shipment.shippedAt', function () {
      console.log(this.get('item.sku'));
      console.log(this.get('shipment.shippedAt'));
      return !!this.get('shipment.shippedAt');
    }),
    disableEdits: computed('kitComponent', 'order.closed', function () {
      return this.get('kitComponent') || this.get('order.closed');
    }),
    quantityShipped: computed('quantity', 'quantityBackordered', function () {
      return this.get('quantity') - (this.get('quantityBackordered') || 0);
    }),
    ecExtPrice: computed('quantity', 'price', 'quantityBackordered', function () {
      return Math.round(this.get('price') * 100 * this.get('quantityShipped')) / 100;
    }),
    ecExtCost: computed('quantity', 'cost', 'quantityBackordered', function () {
      return Math.round(this.get('cost') * 100 * this.get('quantityShipped')) / 100;
    }),
    ecExtRefundPrice: computed('refundQuantity', 'refundPrice', function () {
      return Math.round(this.get('refundPrice') * 100 * (this.get('refundQuantity') || 0)) / 100;
    }),
    picked: computed.mapBy('pickedItems', 'quantity'),
    pickingIcon: computed('shipment.pickingTote.id', 'pickedItems.@each.pickingTote', function () {
      var _this = this;
      var icon = 'info-circle';
      if (this.get('quantityPicked') !== this.get('quantityShipped')) {
        return 'exclamation-triangle';
      }
      if (this.get('shipment.pickingTote.id')) {
        this.get('pickedItems').forEach(function (pi) {
          if (pi.get('pickingTote.id') !== _this.get('shipment.pickingTote.id')) {
            icon = 'exclamation-triangle';
          }
        });
      }
      return icon;
    }),
    quantityPicked: computed.sum('picked'),
    quantityRemaining: computed('quantity', 'quantityPicked', 'quantityBackordered', function () {
      return this.get('quantity') - (this.get('quantityPicked') || 0) - (this.get('quantityBackordered') || 0);
    }),
    keypadValue: computed('quantityRemaining', function () {
      return this.get('quantityRemaining');
    })

  });
});