define('core/models/carton-dimension', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    length: _emberData.default.attr('number'),
    width: _emberData.default.attr('number'),
    height: _emberData.default.attr('number'),
    weight: _emberData.default.attr('number'),
    maxWeight: _emberData.default.attr('number'),
    cost: _emberData.default.attr('number'),
    speed: _emberData.default.attr('number'),
    protection: _emberData.default.attr('number'),
    maxDim: _emberData.default.attr('number'),
    midDim: _emberData.default.attr('number'),
    minDim: _emberData.default.attr('number'),
    volume: _emberData.default.attr('number'),
    process: _emberData.default.attr('number'),
    maxValue: _emberData.default.attr('number'),
    maxUnits: _emberData.default.attr('number'),
    reserved: _emberData.default.attr('boolean'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    projectRecords: _emberData.default.hasMany('project-record'),
    carton: _emberData.default.belongsTo('carton')
  });
});