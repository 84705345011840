define('core/models/amazon-request', ['exports', 'ember-data', 'ember-api-actions'], function (exports, _emberData, _emberApiActions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = _emberData.default.Model.extend({
    requestId: _emberData.default.attr('string'),
    reportId: _emberData.default.attr('string'),
    requestType: _emberData.default.attr('string'),
    script: _emberData.default.attr('string'),
    messages: _emberData.default.attr('number'),
    cachesData: _emberData.default.attr('string'),
    notes: _emberData.default.attr('string'),
    cachedData: _emberData.default.attr('string', { readOnly: true }),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    title: _emberData.default.attr('string', { readOnly: true }),
    projectRecords: _emberData.default.hasMany('project-record'),
    amazonAcknowledgements: _emberData.default.hasMany('amazon-acknowledgement'),
    amazonFulfillments: _emberData.default.hasMany('amazon-fulfillment'),
    amazonImages: _emberData.default.hasMany('amazon-image'),
    amazonInventories: _emberData.default.hasMany('amazon-inventory'),
    amazonAdjustments: _emberData.default.hasMany('amazon-adjustment'),
    amazonPrices: _emberData.default.hasMany('amazon-price'),
    amazonProducts: _emberData.default.hasMany('amazon-product'),
    amazonRelationships: _emberData.default.hasMany('amazon-relationship'),
    amazonOpenListings: _emberData.default.hasMany('amazon-open-listing'),
    amazonCartonContents: _emberData.default.hasMany('amazon-carton-content'),
    amazonListingReports: _emberData.default.hasMany('amazon-listing-report'),
    records: computed('requestType', function () {
      if (this.get('requestType') === 'Product') {
        return this.get('amazonProducts');
      } else if (this.get('requestType') === 'Inventory') {
        return this.get('amazonInventories');
      } else if (this.get('requestType') === 'Price') {
        return this.get('amazonPrices');
      } else if (this.get('requestType') === 'Image') {
        return this.get('amazonImages');
      }
    }),
    download: (0, _emberApiActions.memberAction)({ path: 'download', type: 'get' })
  });
});