define('core/components/edit-receiving-tote', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    actions: {
      setCurrent: function setCurrent(field) {
        this.set('tote.' + field, new Date());
      },
      save: function save() {
        this.get('tote').save();
      }
    }
  });
});