define('core/components/project-amazon-details', ['exports', 'ember-concurrency'], function (exports, _emberConcurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  var service = Ember.inject.service;
  exports.default = Component.extend({
    speech: service(),
    store: service(),
    model: computed.alias('record.model'),
    item: computed.alias('model.item'),
    actions: {
      nextRecord: function nextRecord() {
        this.set('record.completedAt', new Date());
        this.get('record.model').then(function (model) {
          model.save();
        });
      },
      openUrl: function openUrl() {
        this.set('record.nameSearched', true);
        window.open(this.get('url'), 'amazonSearch');
      },
      openUpcUrl: function openUpcUrl() {
        this.set('record.upcSearched', true);
        if (this.get('item.hasUpc')) {
          window.open(this.get('upcUrl'), 'amazonSearch');
        }
      }

    }
  });
});