define('core/routes/amazon-listings/stock-report', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  var service = Ember.inject.service;
  exports.default = Route.extend({
    ajax: service(),
    model: function model(params) {
      return this.get('ajax').request('/api/amazon_listings?report=stockReport');
    }
  });
});