define('core/models/pos-batch', ['exports', 'ember-data', 'ember-api-actions'], function (exports, _emberData, _emberApiActions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = _emberData.default.Model.extend({
    registerId: _emberData.default.attr('number'),
    openedAt: _emberData.default.attr('date'),
    closedAt: _emberData.default.attr('date'),
    openingAmount: _emberData.default.attr('number'),
    closingAmount: _emberData.default.attr('number'),
    sales: _emberData.default.attr('number'),
    returns: _emberData.default.attr('number'),
    tax: _emberData.default.attr('number'),
    salesPlusTax: _emberData.default.attr('number'),
    commission: _emberData.default.attr('number'),
    paidOut: _emberData.default.attr('number'),
    dropped: _emberData.default.attr('number'),
    paidOnAccount: _emberData.default.attr('number'),
    tenderedAmount: _emberData.default.attr('number'),
    changeAmount: _emberData.default.attr('number'),
    discounts: _emberData.default.attr('number'),
    costOfGoods: _emberData.default.attr('number'),
    shipping: _emberData.default.attr('number'),
    transactionCount: _emberData.default.attr('number'),
    popDrawerCount: _emberData.default.attr('number'),
    abortedTransactionCount: _emberData.default.attr('number'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    storeId: _emberData.default.attr('number'),
    balanced: _emberData.default.attr('boolean', { readOnly: true }),
    posBatchTenderTotals: _emberData.default.hasMany('pos-batch-tender-total'),
    tenderSort: ['tender.id'],
    sortedTenders: computed.sort('posBatchTenderTotals', 'tenderSort'),
    orders: _emberData.default.hasMany('order'),
    orderPayments: _emberData.default.hasMany('order-payment'),
    print: (0, _emberApiActions.memberAction)({ path: 'print', type: 'put' })
  });
});