define('core/models/property', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    display: _emberData.default.attr('string'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    position: _emberData.default.attr('number'),
    propertyValues: _emberData.default.hasMany('property-value'),
    queryName: computed('name', function () {
      return 'query' + this.get('name');
    })
  });
});