define('core/components/data-table-content-header', ['exports', 'ember-data-table/components/data-table-content-header'], function (exports, _dataTableContentHeader) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _dataTableContentHeader.default;
    }
  });
});