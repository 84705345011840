define('core/models/customer', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = _emberData.default.Model.extend({
    number: _emberData.default.attr('string'),
    accountTypeId: _emberData.default.attr('number'),
    company: _emberData.default.attr('string'),
    title: _emberData.default.attr('string'),
    firstName: _emberData.default.attr('string'),
    lastName: _emberData.default.attr('string'),
    name: computed('firstName', 'lastName', function () {
      return this.get('firstName') + ' ' + this.get('lastName');
    }),
    address1: _emberData.default.attr('string'),
    address2: _emberData.default.attr('string'),
    city: _emberData.default.attr('string'),
    state: _emberData.default.attr('string'),
    zip: _emberData.default.attr('string'),
    country: _emberData.default.attr('string'),
    email: _emberData.default.attr('string'),
    phone: _emberData.default.attr('string'),
    fax: _emberData.default.attr('string'),
    priceLevel: _emberData.default.attr('number'),
    enforcePurchaseLimits: _emberData.default.attr('boolean'),
    accountBalance: _emberData.default.attr('number'),
    creditLimit: _emberData.default.attr('number'),
    totalSales: _emberData.default.attr('number'),
    totalSavings: _emberData.default.attr('number'),
    taxNumber: _emberData.default.attr('string'),
    taxExempt: _emberData.default.attr('boolean'),
    currentDiscount: _emberData.default.attr('number'),
    paymentToken: _emberData.default.attr('string'),
    paymentTokens: _emberData.default.hasMany('payment-token'),
    invoiceContact: _emberData.default.attr('string'),
    invoiceEmail: _emberData.default.attr('string'),
    nonce: _emberData.default.attr('string'),
    lastVisitAt: _emberData.default.attr('date'),
    notes: _emberData.default.attr('string'),
    orders: _emberData.default.hasMany('order'),
    shipTos: _emberData.default.hasMany('ship-to', { inverse: 'customer' }),
    shipToSort: ['name'],
    sortedShipTos: computed.sort('shipTos', 'shipToSort'),
    createdAt: _emberData.default.attr('date', { readOnly: true }),
    updatedAt: _emberData.default.attr('date', { readOnly: true }),
    defaultShipTo: _emberData.default.belongsTo('ship-to'),
    storeId: _emberData.default.attr('number')
    //employee: DS.belongsTo('employee'),
    //user: DS.belongsTo('user'),
  });
});