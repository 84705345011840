define('core/models/receiving-bin', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = _emberData.default.Model.extend({
    location: _emberData.default.attr('string'),
    length: _emberData.default.attr('number'),
    width: _emberData.default.attr('number'),
    height: _emberData.default.attr('number'),
    maxWeight: _emberData.default.attr('number'),
    position: _emberData.default.attr('number'),
    projectRecords: _emberData.default.hasMany('project-record'),
    currentTote: _emberData.default.belongsTo('receiving-tote', { inverse: 'receivingBin' }),
    receivingTotes: _emberData.default.hasMany('receiving-tote'),
    stationId: _emberData.default.attr('number'),
    receivingGroupId: _emberData.default.attr('number'),
    sharedBinId: _emberData.default.attr('number'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    assignmentPosition: _emberData.default.attr('number'),
    binType: _emberData.default.attr('string'),
    binCode: computed('stationId', 'location', function () {
      return this.get('stationId') + '.' + this.get('location');
    }),
    station: computed('stationId', function () {
      return this.get('stationId') || 'Unassigned';
    }),
    locInt: computed('location', function () {
      return this.get('location') * 1;
    })
  });
});